/*--
/*  3.3 - Mobile Menu CSS
/*----------------------------------------*/

// Mobile Menu 
.mobile-menu{
    position: fixed;
    top: 0;
    width: 300px;
    height: 100%;
    left: -305px;
    background-color: $white;
    z-index: 999;
    padding-top: 70px;
    transition: $transition-base;
    overflow: auto;

    &.open{
        left: 0;
    }

    & .menu-close{
        position: absolute;
        top: 20px;
        right: 25px;
        font-size: 30px;
        color: $dark;
    }
}

// Mobile Sign In & Up
.mobile-top{
    padding-top: 30px;

    & p{
        font-size: 15px;
        color: $dark;
        font-weight: 400;
        margin-bottom: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        line-height: 1.4;

        @media #{$desktop-device}{
            font-size: 14px;
        }

        & + p{
            margin-top: 5px;
        }

        & a{
            color: $dark;

            &:hover{
                color: $primary;
            }
        }
        & i{
            color: $primary;
            font-size: 20px;
            padding-right: 15px;

            @media #{$desktop-device}{
                font-size: 18px;
                padding-right: 10px;
            }
        }
    }
}


// Mobile Sign In & Up
.mobile-sign-in-up{
    padding-top: 30px;

    & ul{
        display: flex;
        align-items: center;
        justify-content: center;
        
        & li{
            margin-right: 20px;

            &:last-child{
                margin-right: 0;
            }

            & a{
                font-size: 16px;
                font-weight: 500;
                text-transform: capitalize;
                color: $dark;
                transition: $transition-base;
                display: block;
                height: 45px;
                line-height: 41px;
                padding: 0 30px;
                border: 1px solid $primary;
                border-radius: 5px;
                background-color: $white;

                @media #{$desktop-device}{
                    font-size: 16px;
                }

                &:hover{
                    background-color: $primary;
                    color: $white;
                }

                &.sign-in{}
                &.sign-up{}
            }
        }
    }
}


// Mobile Menu
.mobile-menu-items{
    padding: 30px 0;

    & ul{
        border-top: 1px solid #eeecec;

        & li{
            position: relative;

            & a{
                display: block;
                width: 100%;
                background: none;
                text-align: left;
                padding: 10px 20px;
                border: 0;
                border-bottom: 1px solid #eeecec;
                font-size: 15px;
                font-weight: 500;
                color: $dark;
            }

            & .mobile-menu-expand{
                position: absolute;
                right: 20px;
                top: 12px;
                width: 16px;
                height: 16px;
                cursor: pointer;
                
                &::before{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 1px;
                    background-color: $dark;
                    top: 50%;
                    transform: translateY(-50%);
                }
                
                &::after {
                    position: absolute;
                    content: '';
                    width: 1px;
                    height: 100%;
                    background-color: $dark;
                    left: 50%;
                    bottom: 0;
                    @include transition(0.3s);
                    transform: translateX(-50%);
                }
            }

            &.active-expand{
                & > .mobile-menu-expand{
                    &::after {
                        height: 0;
                    }
                }
            }

            ul{
                border-top: 0;

                & li{
                    & a{
                        padding-left: 12%;
                    }

                    ul{
                        & li{
                            & a{
                                padding-left: 24%;
                            }
                        }
                    }
                }
            }
        }        
    }
}

// Mobile Social
.mobile-social{
    padding-bottom: 30px;

    & .social{
        display: flex;
        justify-content: center;

        & li{
            margin-right: 20px;

            &:last-child{
                margin-right: 0;
            }

            & a{
                font-size: 20px;
                color: $dark;

                &:hover{
                    color: $primary;
                }
            }
        }
    }
}




