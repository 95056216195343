/*--
/*  4.11 - About CSS
/*----------------------------------------*/


// About Images
.about-images{
    position: relative;
    overflow: hidden;
    margin-top: 50px;

    & .images{
        & img{
            border-radius: 10px;
            width: 100%;
        }
    }

    & .about-years{
        width: 125px;
        background-color: $primary;
        padding: 10px 20px;
        text-align: center;
        border-radius: 10px;
        position: absolute;
        top: 50px;
        right: 30px;

        & .years-icon{
            background-color: $white;
            text-align: center;
            width: 85px;
            height: 85px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -30px;
            position: relative;
        }
        & p{
            color: $white;
            font-size: 14px;
            font-weight: 400;
            margin-top: 6px;

            & strong{
                font-size: 18px;
                font-weight: 700;
                line-height: 1;
            }
        }
    }
}


// About Content
.about-content{
    margin-top: 45px;
    max-width: 530px;
    margin-left: auto;

    @media #{$tablet-device, $large-mobile}{
        margin-left: 0;
    }

    & .sub-title{
        font-size: 20px;
        font-weight: 500;
        color: $primary;
        margin-bottom: 20px;

        @media #{$small-mobile}{
            font-size: 16px;
        }
    }
    & .main-title{
        font-size: 40px;
        font-weight: 500;
        margin-bottom: 0;
        line-height: 1.4;
        padding-bottom: 24px;

        @media #{$desktop-device}{
            font-size: 35px;
        }
        @media #{$large-mobile}{
            font-size: 40px;
        }
        @media #{$small-mobile}{
            font-size: 24px;
            padding-bottom: 0;
        }

        & span{
            color: $primary;
            position: relative;

            &::before{
                position: absolute;
                content: '';
                background-image: url(../images/shape/shape-4.png);
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 210px;
                height: 21px;
                left: 50%;
                bottom: -24px;
                transform: translateX(-50%);

                @media #{$tablet-device}{
                    width: 140px;
                    height: 15px;
                }
                @media #{$small-mobile}{
                    display: none;
                }
            }
        }
    }

    & p{
        margin-top: 25px;
        margin-bottom: 0;

        @media #{$desktop-device}{
            margin-top: 15px;
        }
    }

    & .btn{
        margin-top: 40px;

        @media #{$desktop-device}{
            margin-top: 25px;
        }
    }
}


// About Items Wrapper
.about-items-wrapper{}

.about-item{
    border: 1px solid rgba($primary, 0.2);
    border-radius: 10px;
    padding: 30px;
    margin-top: 30px;
    transition: $transition-base;
    
    @media #{$desktop-device}{
        padding: 20px;
    }
    
    & .item-icon-title{
        display: flex;
        align-items: center;
        
        & .item-icon{
            & i{
                width: 80px;
                height: 80px;
                line-height: 86px;
                text-align: center;
                border-radius: 50%;
                background-color: $secondary;
                color: $primary;
                font-size: 36px;
                display: inline-block;
                transition: $transition-base;
                
                @media #{$desktop-device, $small-mobile}{
                    width: 60px;
                    height: 60px;
                    line-height: 66px;
                    font-size: 28px;
                }
            }
        }
        & .item-title{
            flex: 1;
            padding-left: 20px;

            & .title{
                font-size: 22px;
                font-weight: 500;

                @media #{$desktop-device, $small-mobile}{
                    font-size: 18px;
                }
            }
        }
    }
    & p{
        margin-top: 25px;
        margin-bottom: 0;
    }

    &:hover{
        border-color: $primary;

        & .item-icon-title{    
            & .item-icon{
                & i{                   
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }
}








