/*--
/*  3.4 - Login Header CSS
/*----------------------------------------*/

// Login Header
.login-header{
    background-color: $dark;
}

// Login Header Wrapper
.login-header-wrapper{
    &.navbar{
        padding: 0 0;
    }
}

// Login Header Logo
.login-header-logo{
    padding: 25px 25px;
    background-color: #28313d;    

    & a {
        & + a{
            margin-left: 15px;

            @media #{$small-mobile}{
                display: none;
            }
        }
    }

    & img{}

    &.logo-2{
        margin-right: 50px;
        padding: 35px 50px;
        background-color: #28313d;

        @media #{$tablet-device}{
            padding: 30px 20px;
            margin-right: 20px;
        }
        @media #{$large-mobile}{
            padding: 20px 20px;
            margin-right: 30px;
        }
    }
}

// Login Header Search
.login-header-search{
    position: relative;
    width: 500px;
    margin-left: auto;
    margin-right: 30px;

    @media #{$desktop-device}{
        width: 400px;
    }
    @media #{$tablet-device}{
        width: 350px;
    }
    @media #{$large-mobile}{
        position: static;
        width: auto;
        margin-right: 0;
    }

    & .search-input{
        position: relative;
        padding: 0;
        background: none;
        display: block;
        z-index: 11;
        border-radius: 0;
        border: 0;

        @media #{$large-mobile}{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #28313d;
            align-items: center;
            z-index: 113;
            display: none;
        }

        & input{
            width: 100%;
            height: 60px;
            border: 0;
            border-radius: 50px;
            padding: 0 30px;
            padding-right: 53px;
            outline: none;
            transition: $transition-base;
            background-color: #28313d;
            color: $white;
            font-size: 16px; 
            font-weight: 400;
        }

        &.show{
            display: flex;
            animation: sticky 0.3s;
        }
    }
    & button{
        position: absolute;
        width: 50px;
        height: 50px;
        line-height: 53px;
        text-align: center;
        border-radius: 10px;
        background-color: transparent;
        border: 0;
        top: 5px;
        right: 5px;
        font-size: 16px;
        color: $primary;
        z-index: 112;

        @media #{$large-mobile}{
            position: relative;
            top: 0;
            right: 0;
            font-size: 24px;
        }
        @media #{$small-mobile}{
            position: relative;
            top: 0;
            right: 0;
            font-size: 20px;
        }
    }

    &.search-2{
        margin-right: 0;
        margin-left: 0;

        @media #{$large-mobile}{
            margin-left: auto;
        }
    }
}

@-webkit-keyframes search {
    0%{
        transform: translateY(-100%)
    }
    100%{
        transform: translateY(0%)
    }
}

@keyframes search {
    0%{
        transform: translateY(-100%)
    }
    100%{
        transform: translateY(0%)
    }
}




// Login Header Action
.login-header-action{
    display: flex;
    align-items: center;
    padding-right: 25px;

    @media #{$large-mobile}{
        padding-right: 5px;
    }
    & > *{
        margin: 0px 15px;

        @media #{$tablet-device, $small-mobile}{
            margin: 0px 7px;
        }
    }
    & .action{}    
    & .notification{
        font-size: 36px;
        color: $primary;
        position: relative;
        padding-top: 3px;
        border: 0;
        background: none;

        @media #{$tablet-device}{
            font-size: 28px;
        }
        @media #{$large-mobile}{
            font-size: 24px;
        }
        @media #{$small-mobile}{
            font-size: 22px;
        }

        & .active{
            width: 12px;
            height: 12px;
            background-color: #ff6e30;
            border: 2px solid $dark;
            position: absolute;
            top: 12px;
            right: 9px;
            border-radius: 5px;

            @media #{$small-mobile}{
                right: -2px;
            }
        }
    }
    & .author{
        & img{
            width: 60px;
            border-radius: 50%;

            @media #{$tablet-device}{
                width: 50px;
            }
            @media #{$large-mobile}{
                width: 45px;
            }
        }
    }
    & .more{
        width: 36px;
        height: 36px;
        border: 0;
        background-color: $white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media #{$tablet-device}{
            width: 30px;
            height: 30px;
        }

        @media #{$small-mobile}{
            width: 28px;
            height: 28px;
        }
        
        & span{
            width: 4px;
            height: 4px;
            background-color: $primary;
            border-radius: 50%;
            display: inline-block;
            margin: 0 1.5px;

            @media #{$small-mobile}{
                width: 3px;
                height: 3px;
                margin: 0 1px;
            }
        }
    }

    & .dropdown{
        & .dropdown-menu {
            width: 160px;
            padding: 10px;
            border-radius: 10px;
            box-shadow: 0 10px 30px 0 rgba($dark, 0.1);
            background-color: $white;
            left: auto;
            right: 0;

            &.dropdown-notification{
                width: 360px;
                padding: 0;
                right: -150px;

                @media #{$tablet-device}{
                    right: -110px;
                }

                @media #{$large-mobile}{
                    right: -140px;
                }

                @media #{$small-mobile}{
                    width: 270px;
                    right: -90px;
                }
            }

            & li{
                border-bottom: 1px solid #ebedf2;

                &:last-child{
                    border-bottom: 0;
                }
                
                & > a{
                    display: block;
                    color: $dark;
                    font-size: 13px;
                    font-weight: 600;
                    padding: 9px 14px;
                    transition: $transition-base;

                    &:hover{
                        color: $primary;
                    }
                }
            }

            & .notification-items-list{
                & .notification-item{
                    display: flex;
                    border-bottom: 1px solid #e0dddb;
                    padding: 20px 25px;

                    @media #{$small-mobile}{
                        padding: 10px 15px;
                    }

                    & .notify-icon{
                        width: 35px;
                        height: 35px;
                        line-height: 35px;
                        text-align: center;
                        border-radius: 50%;
                        display: inline-block;
                        margin-right: 10px;
                        padding: 0 9px;

                        @media #{$small-mobile}{
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            padding: 0 8px;
                            font-size: 13px;
                        }
                    }
                    & .dropdown-body{
                        flex: 1;

                        & p {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            max-width: 200px;
                            margin-bottom: 0;
                            margin-top: 5px;
                            font-weight: 400;
                            font-size: 14px;

                            @media #{$small-mobile}{
                                width: 150px;
                            }
                        }
                    }
                    & .notify-time{
                        width: 100%;
                        white-space: nowrap;
                        color: #828690;
                        height: 35px;
                        line-height: 35px;
                        text-align: center;
                        border-radius: 50%;
                        display: inline-block;
                        font-size: 13px;
                        padding-left: 9px;

                        @media #{$small-mobile}{
                            font-size: 12px;
                        }
                    }
                }
            }
            & .all-notification{
                display: block;
                padding: 15px 30px 15px;
                text-align: center;
                font-size: 14px;
                color: $dark;
                font-weight: 400;
            }
        }
    }

    &.action-2{
        margin-left: auto;

        @media #{$large-mobile}{
            margin-left: 0;
        }
    }
}


















