/*--
/*  4.15 - Registration & Login Form CSS
/*----------------------------------------*/


// Register & Login Wrapper
.register-login-wrapper{
    padding: 70px;
    padding-top: 20px;
    border: 1px solid rgba($primary, 0.2);
    border-radius: 10px;
    
    @media #{$small-mobile}{
        padding: 30px;
    }
}

// Register & Login Images 
.register-login-images{
    background-color: $secondary;
    padding: 45px 40px 0;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin-top: 50px;

    @media #{$small-mobile}{
        margin-top: 30px;
        padding: 25px 20px 0;
    }

    & .shape-1{
        width: 270px;
        height: 270px;
        border-radius: 50%;
        background-color: $primary;
        top: 50px;
        left: 20%;
        position: absolute;
        z-index: -1;

        @media #{$small-mobile}{
            width: 120px;
            height: 120px;
        }

        & img{
            position: absolute;
            top: 45%;
            transform: translateY(-50%);
            left: 20%;

            @media #{$small-mobile}{
                width: 30px;
            }
        }
    }

    & .images{
        width: 100%;
    }
}


// Register & Login Form
.register-login-form{
    max-width: 400px;
    margin-left: auto;
    margin-top: 45px;

    @media #{$tablet-device, $large-mobile}{
        max-width: 100%;
    }
    @media #{$small-mobile}{
        margin-top: 30px;
    }

    & .title{
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 0;
        line-height: 1.4;
        padding-bottom: 10px;

        @media #{$large-mobile}{
            font-size: 24px;
        }
        @media #{$small-mobile}{
            font-size: 20px;
        }

        & span{
            color: $primary;
            position: relative;

            &::before{
                position: absolute;
                content: '';
                background-image: url(../images/shape/shape-4.png);
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 121px;
                height: 13px;
                left: 50%;
                bottom: -10px;
                transform: translateX(-50%);
                
                @media #{$small-mobile}{
                    display: none;
                }
            }
        }
    }

    & .form-wrapper{
        padding-top: 30px;

        @media #{$small-mobile}{
            padding-top: 10px;
        }
    }

    & .single-form{
        & .btn{
            margin-top: 20px;
            transition: $transition-base;

            &::before{
                width: 600px;
                height: 600px;
            }

            &.btn-outline{
                border: 1px solid rgba($primary, 0.2);
                line-height: 58px;
                
                &:hover{
                    background-color: $primary;
                    border-color: $primary;
                    color: $white;
                }
            }
        }
    }
}





