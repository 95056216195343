/*--
/*  4.9 - Page Banner CSS
/*----------------------------------------*/

// Page Banner
.page-banner{
    height: 550px;
    background-color: $secondary;
    display: flex;
    align-items: center;
    padding-top: 189px;
    position: relative;
    overflow: hidden;

    @media #{$tablet-device, $large-mobile}{
        height: 400px;
        padding-top: 103px;
    }    

    & .shape-1{
        position: absolute;
        top: 32%;
        left: 100px;

        @media #{$large-mobile}{
            width: 60px;
            left: 30px;
        }
    }
    & .shape-2{
        position: absolute;
        bottom: 0;
        left: 0;

        @media #{$desktop-device, $tablet-device}{
            width: 220px;
        }
        @media #{$large-mobile}{
            width: 180px;
        }
    }
    & .shape-3{
        position: absolute;
        bottom: 0;
        right: 0;

        @media #{$desktop-device}{
            width: 305px;
        }
        @media #{$tablet-device}{
            width: 225px;
        }
        @media #{$large-mobile}{
            width: 195px;
        }
    }

    & .shape-author{
        position: absolute;
        top: 45%;
        right: 9.5%;
        border-radius: 50%;

        @media #{$desktop-device}{
            width: 150px;
            top: 63%;
        }
        @media #{$tablet-device}{
            width: 150px;
            top: 52%;
        }
        @media #{$large-mobile}{
            width: 100px;
            top: auto;
            bottom: 11%;
            right: 11%;
        }

        @media #{$small-mobile}{
            bottom: 6%;
            width: 80px;
        }
    }
}

// Page Banner Content 
.page-banner-content{
    max-width: 570px;

    @media #{$desktop-device}{
        max-width: 500px;
    }
    @media #{$tablet-device, $large-mobile}{  
        max-width: 360px;
    }
    
    & .breadcrumb{
        margin-bottom: 0;

        & li{
            font-size: 17px;
            font-weight: 500;
            color: $dark;
            position: relative;

            @media #{$large-mobile}{
                font-size: 15px;
            }

            & + li{
                &::before{
                    content: '//';
                    font-size: 17px;
                    font-weight: 500;
                    color: $dark;
                    margin: 0 8px;

                    @media #{$large-mobile}{
                        font-size: 15px;
                    }
                }
            }

            &.active{
                color: $primary;
            }

            & a{}
        }
    }
    & .title{
        font-size: 40px;
        font-weight: 500;
        margin-bottom: 0;
        margin-top: 15px;
        padding-bottom: 15px;
        line-height: 1.6;

        @media #{$tablet-device, $large-mobile}{  
            font-size: 30px;
        }
        @media #{$small-mobile}{
            font-size: 24px;
        }

        & span{
            position: relative;
            color: $primary;

            &::before{
                position: absolute;
                content: '';
                background-image: url(../images/shape/shape-4.png);
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 120px;
                height: 13px;
                left: 50%;
                bottom: -10px;
                transform: translateX(-50%);

                @media #{$tablet-device, $large-mobile}{                    
                    display: none;
                }
            }
        }
    }
}

// Shape Icon Box 
.shape-icon-box{
    position: absolute;
    top: 47%;
    left: 60%;

    @media #{$desktop-device}{
        left: 55%;
    }

    @media #{$tablet-device}{
        top: 35%;
        left: 50%;
    }
    @media #{$large-mobile}{
        top: 30%;
        left: 57%;
    }
    @media #{$small-mobile}{
        left: 62%;
    }

    & .icon-shape-1{
        position: absolute;
        top: -15px;
        right: -130px;

        @media #{$large-mobile}{
            right: -100px;
        }
        @media #{$small-mobile}{
            display: none;
        }
    }
    & .icon-shape-2{
        @media #{$large-mobile}{
            width: 100px;
        }
        @media #{$small-mobile}{
            width: 100px;
        }
    }

    & .box-content{
        width: 140px;
        height: 140px;
        text-align: center;
        border: 1px solid rgba($primary, 0.2);
        border-radius: 50%;
        margin: 0 auto;
        padding: 10px;

        @media #{$large-mobile}{
            width: 100px;
            height: 100px;
        }
        @media #{$small-mobile}{
            width: 80px;
            height: 80px;
        }

        & .box-wrapper{
            background-color: $primary;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            width: 100%;
            height: 100%;

            & i{
                font-size: 70px;
                color: $white;
                display: block;
                line-height: 1;
    
                @media #{$large-mobile}{
                    font-size: 40px;
                }
            }
        }
    }
}



