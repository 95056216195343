/*----------------------------------------*/
/*  05. Widget CSS
/*----------------------------------------*/

@import "widget/footer";
@import "widget/sidebar";





