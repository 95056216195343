/*----------------------------------------*/
/*  04. Page CSS
/*----------------------------------------*/

@import "page/slider";
@import "page/courses";
@import "page/call-to-action";
@import "page/how-it-work";
@import "page/download-app";
@import "page/testimonial";
@import "page/brand-logo";
@import "page/blog";
@import "page/page-banner";
@import "page/about";
@import "page/team";
@import "page/courses-details";
@import "page/blog-details";
@import "page/register-login";
@import "page/faq";
@import "page/404-error";
@import "page/contact";
@import "page/courses-enroll";
@import "page/courses-admin";







