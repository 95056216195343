/*--
/*  4.19 - Courses Enroll CSS
/*----------------------------------------*/

// ourses Enroll Wrapper
.courses-enroll-wrapper{
    display: flex;
    flex-wrap: wrap;
}

// Courses Video Player
.courses-video-player{
    width: 71.8%;
    padding-bottom: 80px;

    @media #{$tablet-device, $large-mobile}{
        width: 100%;
    }

    @media #{$large-mobile}{
        padding-bottom: 60px;
    }    
    
    & .vidcontainer{
        background: #010101;
        position: relative;
        height: 690px;
        position: relative;

        @media #{$desktop-device}{
            height: 490px;
        }
        @media #{$tablet-device}{
            height: 500px;
        }
        @media #{$large-mobile}{
            height: 420px;
        }
        @media #{$small-mobile}{
            height: 340px;
        }
    }

    video {
        width: 100%;
        height: 690px;
        text-align: center;
        display: block;

        @media #{$desktop-device}{
            height: 490px;
        }
        @media #{$tablet-device}{
            height: 500px;
        }
        @media #{$large-mobile}{
            height: 420px;
        }
        @media #{$small-mobile}{
            height: 340px;
        }
    }

    & .video-play-bar{
        background-color: rgba($dark, 0.8);
        position: absolute;
        bottom: 20px;
        left: 30px;
        right: 30px;
        padding: 20px 40px;
        z-index: 13;
        opacity: 0;
        visibility: hidden;
        transition: $transition-base;
        transition-delay: 1.5s;

        @media #{$small-mobile}{
            left: 20px;
            right: 20px;
            padding: 15px 15px;
        }
    }

    & .topControl {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        z-index: 1;

        & .progress {
            width: calc(100% - 110px);
            height: 3px;
            position: relative;
            cursor: pointer;
            background-color: #7a8088;

            @media #{$small-mobile}{
                width: calc(100% - 100px);
            }

            & span {
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: block;
            }

            & .timeBar{
                z-index:10;
                width:0;
                background-color: #ff0000;
            }

            & .bufferBar{
                z-index:5;
                width:0;
                background-color: rgba($white, 0.6);
            }
        }

        & .time{
            white-space: nowrap;
            text-align: right;
            font-size: 12px;
            font-family: $headings-font-family;
            font-weight: 400;
            color: $white;
        }
    }
    
    & .controllers {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        margin-left: -5px;
        margin-right: -5px;

        & .controllers-left{}
        & .controllers-right{
            white-space: nowrap;
            text-align: right;
        }

        & button {
            border: 0;
            background: none;
            color: $white;
            margin: 0 10px;
            outline: none;
            font-size: 15px;
            display: inline-block;
            padding: 0;

            @media #{$small-mobile}{
                font-size: 14px;
            }
        }

        & .btnPlay:after {
            content: "\f04b";
            font-family: 'FontAwesome';
        }
        & .paused:after {
            content: "\f04c";
        }


        & .sound:after {
            content: "\f027";
            font-family: 'FontAwesome';
        }
        & .sound2:after {
            content: "\f028";
        }
        & .muted:after {
            content: "\f026";
        }

        & .volume {
            position: relative;
            cursor: pointer;
            width: 70px;
            height: 5px;
            background-color: #7a8088;
            display: inline-block;
            margin-bottom: 2px;

            & .volumeBar{
                display: block;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #ff0000;
                z-index: 10;
            }
        }

        & .speedcnt {
            display: none;
            position: absolute;
            right: 60px;
            bottom: 46px;
            background-color: $white;
            border-radius: 5px;
            transition: $transition-base;

            & li{
                text-align: center;
                font-family: $headings-font-family;
                font-weight: 400;
                font-size: 11px;
                padding: 5px 20px;
                cursor: pointer;
                display: block;
                border-bottom: 1px solid rgba($primary, 0.2);
                transition: $transition-base;
                color: $body-color;

                &:last-child{
                    border-bottom: 0;
                }

                &.selected{
                    font-weight: 500;
                    background-color: $primary;
                    color: $white;
                }
            }
        }

        & .btnFS:after {
            content: "\f065";
           font-family: 'FontAwesome';
        }
        

        & .disabled {
            pointer-events: none;
            cursor: not-allowed;
            color: rgba($white, 0.5);
        }
    }

    & .bigplay {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 11;
        color: $white;
        font-size: 90px;
        line-height: 90px;
        text-align: center;
        cursor: pointer;
        top: 0;
        left: 0;

        @media #{$small-mobile}{
            font-size: 45px;
        }

        & i{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    & .loading {
        width: 100%;
        height: 100%;
        background-color: $black;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;

        & .spinner{
            border-color: $white;
            border-right-color: transparent;
            width: 70px;
            height: 70px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -35px;
            margin-left: -35px;
        }
    }

    &:hover{
        & .video-play-bar{
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
        }
    }
}

// Courses Enroll Content
.courses-enroll-content{
    padding: 0 70px;

    @media #{$desktop-device, $tablet-device, $large-mobile}{
        padding: 0 40px;
    }
    @media #{$small-mobile}{
        padding: 0 20px;
    }

    & .courses-enroll-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;

        @media #{$tablet-device, $large-mobile}{
            display: block;
        }

        & .title{
            max-width: 760px;
            font-size: 25px;
            font-weight: 500;
            line-height: 1.5;
            color: $dark;
            margin-bottom: 0;
            margin-top: 10px;

            @media #{$desktop-device}{
                max-width: 380px;
                font-size: 22px;
            }
            @media #{$small-mobile}{
                font-size: 18px;
            }
        }
        & p{
            font-size: 15px;
            font-weight: 400;
            margin-top: 10px;

            & i{
                color: #ff4200;
                margin-right: 3px;
            }
            & span{
                color: #ff4200;                
            }
        }
    }

    & .courses-enroll-tab{
        background-color: $secondary;
        padding: 5px 15px 15px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        @media #{$large-mobile}{
            display: block;
        }
        

        & .enroll-tab-menu{
            & .nav{
                margin-left: -7px;
                margin-right: -7px;

                & li{
                    padding: 10px 7px 0;

                    & button{
                        border: 1px solid rgba($primary, 0.2);
                        background-color: $white;
                        border-radius: 10px;
                        padding: 0 15px;
                        height: 45px;
                        line-height: 41px;
                        text-align: center;
                        display: block;
                        font-weight: 500;
                        font-size: 15px;
                        color: $dark;
                        transition: $transition-base;
                        white-space: nowrap;

                        @media #{$desktop-device}{
                            font-size: 14px;
                            padding: 0 13px;
                        }
                        @media #{$tablet-device, $large-mobile}{
                            font-size: 14px;
                            padding: 0 13px;
                        }
                        
                        &.active,
                        &:hover{
                            background-color: $primary;
                            border-color: $primary;
                            color: $white;
                        }
                    }
                }
            }
        }
        & .enroll-share{
            padding-top: 10px;
            
            & a{
                border: 1px solid rgba($primary, 0.2);
                background-color: $white;
                border-radius: 10px;
                padding: 0 25px;
                height: 45px;
                line-height: 43px;
                text-align: center;
                display: inline-block;
                font-weight: 500;
                font-size: 15px;
                color: $dark;
                transition: $transition-base;
                white-space: nowrap;

                @media #{$desktop-device}{
                    font-size: 14px;
                    padding: 0 20px;
                }
                
                & i{
                    margin-right: 5px;
                    font-size: 20px;
                    color: $primary;
                    transition: $transition-base;
                }

                &:hover{
                    background-color: $primary;
                    border-color: $primary;
                    color: $white;

                    & i{
                        color: $white;
                    }
                }
            }
        }
    }

    & .courses-enroll-tab-content{
        border: 1px solid rgba($primary, 0.2);
        padding: 10px 50px 35px;
        border-radius: 10px;
        margin-top: 30px;       
        
        @media #{$desktop-device}{
            padding: 1px 40px 25px;
        }
        @media #{$small-mobile}{
            padding: 1px 20px 15px;            
        }

        & .enroll-tab-title{
            padding-top: 20px;

            & .title{
                font-size: 25px;
                font-weight: 500;
                line-height: 1.5;
                color: $dark;
                margin-bottom: 0;

                @media #{$desktop-device}{
                    font-size: 20px;
                }
            }
        }
        & .enroll-tab-content{
            padding-top: 25px;

            & p{
                font-size: 15px;
                font-weight: 400;
            }
            & .table{
                border-top: 1px solid rgba($primary, 0.2);
                margin-bottom: 25px;
                margin-top: 25px;

                & tbody{
                    & tr{
                        & th{
                            width: 170px;
                            border-color: rgba($primary, 0.2);
                            padding: 12px 30px;
                            padding-right: 0;
                            font-size: 15px;
                            font-weight: 500;
                            color: $dark;
                            white-space: nowrap;

                            @media #{$small-mobile}{
                                font-size: 14px;
                                padding: 8px 15px;
                                padding-right: 0;
                                min-width: 90px;
                            }

                            & span{
                                display: block;
                                float: right;
                            }
                        }
                        & td{
                            border-color: rgba($primary, 0.2);
                            padding: 12px 30px;
                            font-size: 15px;
                            font-weight: 400;
                            color: $body-color;

                            @media #{$small-mobile}{
                                font-size: 14px;
                                padding: 8px 15px;
                            }                       
                        }
                    }
                }
            }

            & .text{
                font-weight: 500;
                color: $dark;
            }

            & .single-instructor{
                border-bottom: 1px solid rgba($primary, 0.2);
                position: relative;
                overflow: hidden;
                padding: 25px 0 20px;

                &:first-child{
                    padding-top: 0;
                }
                &:last-child{
                    border-bottom: 0;
                    padding-bottom: 0;
                }

                & .review-author{
                    display: flex;
                    align-items: center;

                    @media #{$small-mobile}{
                        display: block;
                    }

                    & .author-thumb{
                        position: relative;
                        display: inline-block;

                        & img{
                            display: block;
                            width: 90px;
                            border: 1px solid rgba($primary, 0.2);
                            border-radius: 50%;
                            padding: 8px;
                        }
                    }
                    & .author-content{
                        flex: 1;
                        padding-left: 30px;

                        @media #{$small-mobile}{
                            padding-left: 0;
                            padding-top: 20px;
                        }

                        & .name{
                            font-size: 22px;
                            font-weight: 500;
                            margin-bottom: 0;
                        }
                        & .designation{
                            font-size: 14px;
                            font-weight: 400;
                            color: $primary;
                            margin-top: 5px;
                            display: block;
                        }

                        & .rating-star{
                            position: relative;
                            margin-top: 5px;
                            display: inline-block;
        
                            &::before{
                                content: '\f000 \f000 \f000 \f000 \f000';
                                font-family: IcoFont;
                                color: #d0d0d0;
                                font-size: 14px;
                                letter-spacing: 2px;
            
                                @media #{$desktop-device}{
                                    font-size: 13px;
                                    letter-spacing: 1px;
                                }
                            }
            
                            & .rating-bar{
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                                overflow: hidden;
                                display: inline-block;
            
                                &::before{
                                    content: '\f000 \f000 \f000 \f000 \f000';
                                    font-family: IcoFont;
                                    color: #ffba00;
                                    font-size: 14px;
                                    letter-spacing: 2px;
            
                                    @media #{$desktop-device}{
                                        font-size: 13px;
                                        letter-spacing: 1px;
                                    }
                                }
                            }
                        }
                    }
                }

                & p{
                    margin-top: 15px;
                    margin-bottom: 0;
                    font-size: 15px;
                    font-weight: 400;
                    color: $dark;
                }
            }
        }
    }
}


// Courses Enroll Playlist
.courses-video-playlist{
    width: 28.2%;
    background-color: #eefbf3;

    @media #{$tablet-device, $large-mobile}{
        width: 100%;
    }

    & .playlist-title{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        padding: 15px 30px 25px;
        background-color: #dff0e6;

        @media #{$tablet-device, $large-mobile}{
            padding: 15px 40px 25px;
        }
        @media #{$small-mobile}{
            padding: 5px 20px 15px;
        }

        & .title{
            font-size: 22px;
            font-weight: 500;
            color: $dark;
            margin-bottom: 0;
            margin-top: 10px;

            @media #{$small-mobile}{
                font-size: 18px;
            }
        }
        & span{
            font-size: 18px;
            font-weight: 500;
            color: $primary;
            display: inline-block;
            margin-top: 10px;

            @media #{$small-mobile}{
                font-size: 16px;
            }
        }
    }

    & .video-playlist{
        & .accordion-item{
            text-align: left;
            border: 0;
            margin-bottom: 0;
            background: none;
            
            & button{
                border: 0;
                background: none;
                text-align: left;
                display: block;
                width: 100%;
                border-bottom: 1px solid #cde9d7;
                padding: 8px 30px;
                padding-right: 50px;
                background-color: #e0f1e7;
                position: relative;
                transition: $transition-base;

                @media #{$tablet-device, $large-mobile}{
                    padding: 8px 40px;
                }
                @media #{$small-mobile}{
                    padding: 8px 20px;
                    padding-right: 40px;
                }

                & p{
                    margin-bottom: 0;
                    font-size: 15px;
                    font-weight: 500;
                    color: $primary;
                    transition: $transition-base;
                    max-width: 420px;

                    @media #{$desktop-device, $large-mobile}{
                        font-size: 14px;
                    }
                }

                &::before{
                    content: '\eaa1';
                    font-family: IcoFont;
                    font-size: 20px;
                    color: $primary;
                    position: absolute;
                    top: 8px;
                    right: 30px;
                    transition: $transition-base;

                    @media #{$small-mobile}{
                        right: 20px;
                    }
                }

                &.collapsed{
                    background-color: #eefbf3;

                    & p{
                        color: $dark;
                    }

                    &::before{
                        content: '\eab2';
                        color: $dark;
                    }
                }
                
                
            }
        }
        & .total-duration{
            font-size: 13px;
            font-weight: 400;
            color: #848886;
            margin-top: 6px;
            display: block;
        }        
        & .vids{
            & a{
                display: block;
                padding: 8px 80px;
                padding-right: 30px;
                border-bottom: 1px solid #cde9d7;
                position: relative;

                &::before{
                    position: absolute;
                    content: '';
                    width: 13px;
                    height: 13px;
                    border: 3px solid $primary;
                    border-radius: 50%;
                    top: 13px;
                    left: 48px;
                }

                & p{
                    margin-bottom: 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: $dark;
                    transition: $transition-base;

                    @media #{$desktop-device, $large-mobile}{
                        font-size: 13px;
                    }
                }

                &.active{
                    &::before{
                        background-color: $primary;
                        border-color: #acd6bc;
                    }
                    & p{
                        color: $primary;
                    }
                }
            }
        }
    }
}



