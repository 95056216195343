/*-----------------------------------------------------------------------------------

    Template Name: Edule - eLearning Website Template
    Author: codecarnival
    Author URL: https://themeforest.net/user/codecarnival
    Support: https://hasthemes.com/contact-us/
    Description: Creative  HTML5 template.
    Version: 1.0
    
-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Template default CSS
    02. Component CSS
        2.1 - Button CSS
        2.2 - Blockquote CSS
        2.3 - Form CSS
        2.4 - Modal CSS
    03. Header CSS
        3.1 - Header Top CSS
        3.2 - Header Main CSS
        3.3 - Mobile Menu CSS
        3.4 - Login Header CSS
    04. Page CSS
        4.1 - Slider CSS
        4.2 - Courses CSS
        4.3 - Call to Action CSS
        4.4 - How It Work CSS
        4.5 - Download App CSS
        4.6 - Testimonial CSS
        4.7 - Brand Logo CSS
        4.8 - Blog CSS
        4.9 - Page Banner CSS
        4.11 - About CSS
        4.12 - Team CSS
        4.13 - Courses Details CSS
        4.14 - Blog Details CSS
        4.15 - Registration & Login Form CSS
        4.16 - FAQ CSS
        4.17 - 404 Error CSS
        4.18 - Contact CSS
        4.19 - Courses Enroll CSS
        4.20 - Courses Admin CSS
    05. Widget CSS
        5.1 - Footer Widget CSS
        5.2 - Sidebar Widget CSS
    06. Footer CSS

-----------------------------------------------------------------------------------*/
/*!
 * Bootstrap v5.0.0-beta3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-dark-blue: #004a80;
  --bs-lower-blue: #8194d9;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #309255;
  --bs-secondary: #e7f8ee;
  --bs-success: #198754;
  --bs-info: #363b42;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212832;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Gordita";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #52565b;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Gordita";
  font-weight: 500;
  line-height: 1.2;
  color: #1d2733;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #309255;
  text-decoration: underline;
}

a:hover {
  color: #267544;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1200px;
  }
}

@media (min-width: 1600px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1200px;
  }
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.3125rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.3125rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.625rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.625rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.9375rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.9375rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.25rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.25rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.625rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.625rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.875rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.875rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 2.1875rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 2.1875rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 2.8125rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 2.8125rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 3.125rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 3.125rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 1600px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 3.125rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #52565b;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #52565b;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #52565b;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #52565b;
  vertical-align: top;
  border-color: #dee2e6;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d6e9dd;
  --bs-table-striped-bg: #cbddd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1d2c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6d8cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c1d2c7;
}

.table-secondary {
  --bs-table-bg: #fafefc;
  --bs-table-striped-bg: #eef1ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1e5e3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7ebe9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e1e5e3;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #d7d8d9;
  --bs-table-striped-bg: #cccdce;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2c2c3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c7c8c9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c2c2c3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212832;
  --bs-table-striped-bg: #2c333c;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373e47;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323841;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373e47;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0 2.188rem;
  font-family: "Gordita";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #52565b;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #52565b;
  background-color: #fff;
  border-color: #98c9aa;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(48, 146, 85, 0);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0 2.188rem;
  margin: 0 -2.188rem;
  margin-inline-end: 2.188rem;
  color: #52565b;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  padding: 0 2.188rem;
  margin: 0 -2.188rem;
  margin-inline-end: 2.188rem;
  color: #52565b;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 0;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #52565b;
  background-color: transparent;
  border: solid transparent;
  border-width: 0 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: 1.5em;
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0 6.564rem 0 2.188rem;
  font-family: "Gordita";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #52565b;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 2.188rem center;
  background-size: 16px 12px;
  border: 0 solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.form-select:focus {
  border-color: #98c9aa;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(48, 146, 85, 0);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 2.188rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #52565b;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #98c9aa;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(48, 146, 85, 0);
}

.form-check-input:checked {
  background-color: #309255;
  border-color: #309255;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #309255;
  border-color: #309255;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2398c9aa'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(48, 146, 85, 0);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(48, 146, 85, 0);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #309255;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #c1decc;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #309255;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #c1decc;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: 3.5rem;
  padding: 1rem 2.188rem;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 2.188rem;
  pointer-events: none;
  border: 0 solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0 2.188rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #52565b;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 0 solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 8.752rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: 1.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.375em center;
  background-size: 0.75em 0.75em;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0 rgba(25, 135, 84, 0);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 1.5em;
  background-position: top 0.375em right 0.375em;
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
  padding-right: 12.034rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 2.188rem center, center right 6.564rem;
  background-size: 16px 12px, 0.75em 0.75em;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0 rgba(25, 135, 84, 0);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0 rgba(25, 135, 84, 0);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: 1.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.375em center;
  background-size: 0.75em 0.75em;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 1.5em;
  background-position: top 0.375em right 0.375em;
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
  padding-right: 12.034rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 2.188rem center, center right 6.564rem;
  background-size: 16px 12px, 0.75em 0.75em;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-family: "Gordita";
  font-weight: 500;
  line-height: 3.75rem;
  color: #52565b;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 0 solid transparent;
  padding: 0 2.188rem;
  font-size: 1.125rem;
  border-radius: 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #52565b;
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0;
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #000;
  background-color: #309255;
  border-color: #309255;
}

.btn-primary:hover {
  color: #000;
  background-color: #4fa26f;
  border-color: #459d66;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #000;
  background-color: #4fa26f;
  border-color: #459d66;
  box-shadow: 0 0 0 0 rgba(41, 124, 72, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #000;
  background-color: #59a877;
  border-color: #459d66;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(41, 124, 72, 0.5);
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #000;
  background-color: #309255;
  border-color: #309255;
}

.btn-secondary {
  color: #000;
  background-color: #e7f8ee;
  border-color: #e7f8ee;
}

.btn-secondary:hover {
  color: #000;
  background-color: #ebf9f1;
  border-color: #e9f9f0;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #000;
  background-color: #ebf9f1;
  border-color: #e9f9f0;
  box-shadow: 0 0 0 0 rgba(196, 211, 202, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #ecf9f1;
  border-color: #e9f9f0;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(196, 211, 202, 0.5);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #000;
  background-color: #e7f8ee;
  border-color: #e7f8ee;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}

.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0 rgba(60, 153, 110, 0.5);
}

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(60, 153, 110, 0.5);
}

.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #fff;
  background-color: #363b42;
  border-color: #363b42;
}

.btn-info:hover {
  color: #fff;
  background-color: #2e3238;
  border-color: #2b2f35;
}

.btn-check:focus + .btn-info, .btn-info:focus {
  color: #fff;
  background-color: #2e3238;
  border-color: #2b2f35;
  box-shadow: 0 0 0 0 rgba(84, 88, 94, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #2b2f35;
  border-color: #292c32;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(84, 88, 94, 0.5);
}

.btn-info:disabled, .btn-info.disabled {
  color: #fff;
  background-color: #363b42;
  border-color: #363b42;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0 rgba(217, 164, 6, 0.5);
}

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(217, 164, 6, 0.5);
}

.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
}

.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0 rgba(211, 212, 213, 0.5);
}

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(211, 212, 213, 0.5);
}

.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #212832;
  border-color: #212832;
}

.btn-dark:hover {
  color: #fff;
  background-color: #1c222b;
  border-color: #1a2028;
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1c222b;
  border-color: #1a2028;
  box-shadow: 0 0 0 0 rgba(66, 72, 81, 0.5);
}

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a2028;
  border-color: #191e26;
}

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(66, 72, 81, 0.5);
}

.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #212832;
  border-color: #212832;
}

.btn-outline-primary {
  color: #309255;
  border-color: #309255;
}

.btn-outline-primary:hover {
  color: #000;
  background-color: #309255;
  border-color: #309255;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0 rgba(48, 146, 85, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #000;
  background-color: #309255;
  border-color: #309255;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(48, 146, 85, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #309255;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #e7f8ee;
  border-color: #e7f8ee;
}

.btn-outline-secondary:hover {
  color: #000;
  background-color: #e7f8ee;
  border-color: #e7f8ee;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0 rgba(231, 248, 238, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #000;
  background-color: #e7f8ee;
  border-color: #e7f8ee;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(231, 248, 238, 0.5);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #e7f8ee;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0 rgba(25, 135, 84, 0.5);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(25, 135, 84, 0.5);
}

.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #363b42;
  border-color: #363b42;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #363b42;
  border-color: #363b42;
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0 rgba(54, 59, 66, 0.5);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #363b42;
  border-color: #363b42;
}

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(54, 59, 66, 0.5);
}

.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #363b42;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0 rgba(248, 249, 250, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(248, 249, 250, 0.5);
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212832;
  border-color: #212832;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #212832;
  border-color: #212832;
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0 rgba(33, 40, 50, 0.5);
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212832;
  border-color: #212832;
}

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(33, 40, 50, 0.5);
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #212832;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #309255;
  text-decoration: underline;
}

.btn-link:hover {
  color: #267544;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #52565b;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu[data-bs-popper] {
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto /* rtl:ignore */;
  left: 0 /* rtl:ignore */;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0 /* rtl:ignore */;
  left: auto /* rtl:ignore */;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1600px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #309255;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #309255;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.641rem;
  padding-left: 1.641rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #309255;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #52565b;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #2b834d;
  background-color: #eaf4ee;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232b834d'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2352565b'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #98c9aa;
  outline: 0;
  box-shadow: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item:last-of-type {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #309255;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #267544;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #267544;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(48, 146, 85, 0);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #309255;
  border-color: #309255;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #1d5833;
  background-color: #d6e9dd;
  border-color: #c1decc;
}

.alert-primary .alert-link {
  color: #174629;
}

.alert-secondary {
  color: #5c635f;
  background-color: #fafefc;
  border-color: #f8fdfa;
}

.alert-secondary .alert-link {
  color: #4a4f4c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #202328;
  background-color: #d7d8d9;
  border-color: #c3c4c6;
}

.alert-info .alert-link {
  color: #1a1c20;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #14181e;
  background-color: #d3d4d6;
  border-color: #bcbfc2;
}

.alert-dark .alert-link {
  color: #101318;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #309255;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #52565b;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #309255;
  border-color: #309255;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1600px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #1d5833;
  background-color: #d6e9dd;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #1d5833;
  background-color: #c1d2c7;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #1d5833;
  border-color: #1d5833;
}

.list-group-item-secondary {
  color: #5c635f;
  background-color: #fafefc;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #5c635f;
  background-color: #e1e5e3;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #5c635f;
  border-color: #5c635f;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #202328;
  background-color: #d7d8d9;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #202328;
  background-color: #c2c2c3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #202328;
  border-color: #202328;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #14181e;
  background-color: #d3d4d6;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #14181e;
  background-color: #bebfc1;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #14181e;
  border-color: #14181e;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(48, 146, 85, 0);
  opacity: 1;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 15px;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1599.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Gordita";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Gordita";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #1d2733;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #52565b;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1040;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.offcanvas-backdrop::before {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1039;
  width: 100vw;
  height: 100vh;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #309255;
}

.link-primary:hover, .link-primary:focus {
  color: #59a877;
}

.link-secondary {
  color: #e7f8ee;
}

.link-secondary:hover, .link-secondary:focus {
  color: #ecf9f1;
}

.link-success {
  color: #198754;
}

.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #363b42;
}

.link-info:hover, .link-info:focus {
  color: #2b2f35;
}

.link-warning {
  color: #ffc107;
}

.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}

.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}

.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #212832;
}

.link-dark:hover, .link-dark:focus {
  color: #1a2028;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1600px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #309255 !important;
}

.border-secondary {
  border-color: #e7f8ee !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #363b42 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212832 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.3125rem !important;
}

.gap-2 {
  gap: 0.625rem !important;
}

.gap-3 {
  gap: 0.9375rem !important;
}

.gap-4 {
  gap: 1.25rem !important;
}

.gap-5 {
  gap: 1.625rem !important;
}

.gap-6 {
  gap: 1.875rem !important;
}

.gap-7 {
  gap: 2.1875rem !important;
}

.gap-8 {
  gap: 2.5rem !important;
}

.gap-9 {
  gap: 2.8125rem !important;
}

.gap-10 {
  gap: 3.125rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.3125rem !important;
}

.m-2 {
  margin: 0.625rem !important;
}

.m-3 {
  margin: 0.9375rem !important;
}

.m-4 {
  margin: 1.25rem !important;
}

.m-5 {
  margin: 1.625rem !important;
}

.m-6 {
  margin: 1.875rem !important;
}

.m-7 {
  margin: 2.1875rem !important;
}

.m-8 {
  margin: 2.5rem !important;
}

.m-9 {
  margin: 2.8125rem !important;
}

.m-10 {
  margin: 3.125rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.3125rem !important;
  margin-left: 0.3125rem !important;
}

.mx-2 {
  margin-right: 0.625rem !important;
  margin-left: 0.625rem !important;
}

.mx-3 {
  margin-right: 0.9375rem !important;
  margin-left: 0.9375rem !important;
}

.mx-4 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-5 {
  margin-right: 1.625rem !important;
  margin-left: 1.625rem !important;
}

.mx-6 {
  margin-right: 1.875rem !important;
  margin-left: 1.875rem !important;
}

.mx-7 {
  margin-right: 2.1875rem !important;
  margin-left: 2.1875rem !important;
}

.mx-8 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-9 {
  margin-right: 2.8125rem !important;
  margin-left: 2.8125rem !important;
}

.mx-10 {
  margin-right: 3.125rem !important;
  margin-left: 3.125rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.3125rem !important;
  margin-bottom: 0.3125rem !important;
}

.my-2 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.my-3 {
  margin-top: 0.9375rem !important;
  margin-bottom: 0.9375rem !important;
}

.my-4 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-5 {
  margin-top: 1.625rem !important;
  margin-bottom: 1.625rem !important;
}

.my-6 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.my-7 {
  margin-top: 2.1875rem !important;
  margin-bottom: 2.1875rem !important;
}

.my-8 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-9 {
  margin-top: 2.8125rem !important;
  margin-bottom: 2.8125rem !important;
}

.my-10 {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.3125rem !important;
}

.mt-2 {
  margin-top: 0.625rem !important;
}

.mt-3 {
  margin-top: 0.9375rem !important;
}

.mt-4 {
  margin-top: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.625rem !important;
}

.mt-6 {
  margin-top: 1.875rem !important;
}

.mt-7 {
  margin-top: 2.1875rem !important;
}

.mt-8 {
  margin-top: 2.5rem !important;
}

.mt-9 {
  margin-top: 2.8125rem !important;
}

.mt-10 {
  margin-top: 3.125rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.3125rem !important;
}

.me-2 {
  margin-right: 0.625rem !important;
}

.me-3 {
  margin-right: 0.9375rem !important;
}

.me-4 {
  margin-right: 1.25rem !important;
}

.me-5 {
  margin-right: 1.625rem !important;
}

.me-6 {
  margin-right: 1.875rem !important;
}

.me-7 {
  margin-right: 2.1875rem !important;
}

.me-8 {
  margin-right: 2.5rem !important;
}

.me-9 {
  margin-right: 2.8125rem !important;
}

.me-10 {
  margin-right: 3.125rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.3125rem !important;
}

.mb-2 {
  margin-bottom: 0.625rem !important;
}

.mb-3 {
  margin-bottom: 0.9375rem !important;
}

.mb-4 {
  margin-bottom: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.625rem !important;
}

.mb-6 {
  margin-bottom: 1.875rem !important;
}

.mb-7 {
  margin-bottom: 2.1875rem !important;
}

.mb-8 {
  margin-bottom: 2.5rem !important;
}

.mb-9 {
  margin-bottom: 2.8125rem !important;
}

.mb-10 {
  margin-bottom: 3.125rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.3125rem !important;
}

.ms-2 {
  margin-left: 0.625rem !important;
}

.ms-3 {
  margin-left: 0.9375rem !important;
}

.ms-4 {
  margin-left: 1.25rem !important;
}

.ms-5 {
  margin-left: 1.625rem !important;
}

.ms-6 {
  margin-left: 1.875rem !important;
}

.ms-7 {
  margin-left: 2.1875rem !important;
}

.ms-8 {
  margin-left: 2.5rem !important;
}

.ms-9 {
  margin-left: 2.8125rem !important;
}

.ms-10 {
  margin-left: 3.125rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.3125rem !important;
}

.m-n2 {
  margin: -0.625rem !important;
}

.m-n3 {
  margin: -0.9375rem !important;
}

.m-n4 {
  margin: -1.25rem !important;
}

.m-n5 {
  margin: -1.625rem !important;
}

.m-n6 {
  margin: -1.875rem !important;
}

.m-n7 {
  margin: -2.1875rem !important;
}

.m-n8 {
  margin: -2.5rem !important;
}

.m-n9 {
  margin: -2.8125rem !important;
}

.m-n10 {
  margin: -3.125rem !important;
}

.mx-n1 {
  margin-right: -0.3125rem !important;
  margin-left: -0.3125rem !important;
}

.mx-n2 {
  margin-right: -0.625rem !important;
  margin-left: -0.625rem !important;
}

.mx-n3 {
  margin-right: -0.9375rem !important;
  margin-left: -0.9375rem !important;
}

.mx-n4 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n5 {
  margin-right: -1.625rem !important;
  margin-left: -1.625rem !important;
}

.mx-n6 {
  margin-right: -1.875rem !important;
  margin-left: -1.875rem !important;
}

.mx-n7 {
  margin-right: -2.1875rem !important;
  margin-left: -2.1875rem !important;
}

.mx-n8 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n9 {
  margin-right: -2.8125rem !important;
  margin-left: -2.8125rem !important;
}

.mx-n10 {
  margin-right: -3.125rem !important;
  margin-left: -3.125rem !important;
}

.my-n1 {
  margin-top: -0.3125rem !important;
  margin-bottom: -0.3125rem !important;
}

.my-n2 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.my-n3 {
  margin-top: -0.9375rem !important;
  margin-bottom: -0.9375rem !important;
}

.my-n4 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n5 {
  margin-top: -1.625rem !important;
  margin-bottom: -1.625rem !important;
}

.my-n6 {
  margin-top: -1.875rem !important;
  margin-bottom: -1.875rem !important;
}

.my-n7 {
  margin-top: -2.1875rem !important;
  margin-bottom: -2.1875rem !important;
}

.my-n8 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n9 {
  margin-top: -2.8125rem !important;
  margin-bottom: -2.8125rem !important;
}

.my-n10 {
  margin-top: -3.125rem !important;
  margin-bottom: -3.125rem !important;
}

.mt-n1 {
  margin-top: -0.3125rem !important;
}

.mt-n2 {
  margin-top: -0.625rem !important;
}

.mt-n3 {
  margin-top: -0.9375rem !important;
}

.mt-n4 {
  margin-top: -1.25rem !important;
}

.mt-n5 {
  margin-top: -1.625rem !important;
}

.mt-n6 {
  margin-top: -1.875rem !important;
}

.mt-n7 {
  margin-top: -2.1875rem !important;
}

.mt-n8 {
  margin-top: -2.5rem !important;
}

.mt-n9 {
  margin-top: -2.8125rem !important;
}

.mt-n10 {
  margin-top: -3.125rem !important;
}

.me-n1 {
  margin-right: -0.3125rem !important;
}

.me-n2 {
  margin-right: -0.625rem !important;
}

.me-n3 {
  margin-right: -0.9375rem !important;
}

.me-n4 {
  margin-right: -1.25rem !important;
}

.me-n5 {
  margin-right: -1.625rem !important;
}

.me-n6 {
  margin-right: -1.875rem !important;
}

.me-n7 {
  margin-right: -2.1875rem !important;
}

.me-n8 {
  margin-right: -2.5rem !important;
}

.me-n9 {
  margin-right: -2.8125rem !important;
}

.me-n10 {
  margin-right: -3.125rem !important;
}

.mb-n1 {
  margin-bottom: -0.3125rem !important;
}

.mb-n2 {
  margin-bottom: -0.625rem !important;
}

.mb-n3 {
  margin-bottom: -0.9375rem !important;
}

.mb-n4 {
  margin-bottom: -1.25rem !important;
}

.mb-n5 {
  margin-bottom: -1.625rem !important;
}

.mb-n6 {
  margin-bottom: -1.875rem !important;
}

.mb-n7 {
  margin-bottom: -2.1875rem !important;
}

.mb-n8 {
  margin-bottom: -2.5rem !important;
}

.mb-n9 {
  margin-bottom: -2.8125rem !important;
}

.mb-n10 {
  margin-bottom: -3.125rem !important;
}

.ms-n1 {
  margin-left: -0.3125rem !important;
}

.ms-n2 {
  margin-left: -0.625rem !important;
}

.ms-n3 {
  margin-left: -0.9375rem !important;
}

.ms-n4 {
  margin-left: -1.25rem !important;
}

.ms-n5 {
  margin-left: -1.625rem !important;
}

.ms-n6 {
  margin-left: -1.875rem !important;
}

.ms-n7 {
  margin-left: -2.1875rem !important;
}

.ms-n8 {
  margin-left: -2.5rem !important;
}

.ms-n9 {
  margin-left: -2.8125rem !important;
}

.ms-n10 {
  margin-left: -3.125rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.3125rem !important;
}

.p-2 {
  padding: 0.625rem !important;
}

.p-3 {
  padding: 0.9375rem !important;
}

.p-4 {
  padding: 1.25rem !important;
}

.p-5 {
  padding: 1.625rem !important;
}

.p-6 {
  padding: 1.875rem !important;
}

.p-7 {
  padding: 2.1875rem !important;
}

.p-8 {
  padding: 2.5rem !important;
}

.p-9 {
  padding: 2.8125rem !important;
}

.p-10 {
  padding: 3.125rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.3125rem !important;
  padding-left: 0.3125rem !important;
}

.px-2 {
  padding-right: 0.625rem !important;
  padding-left: 0.625rem !important;
}

.px-3 {
  padding-right: 0.9375rem !important;
  padding-left: 0.9375rem !important;
}

.px-4 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-5 {
  padding-right: 1.625rem !important;
  padding-left: 1.625rem !important;
}

.px-6 {
  padding-right: 1.875rem !important;
  padding-left: 1.875rem !important;
}

.px-7 {
  padding-right: 2.1875rem !important;
  padding-left: 2.1875rem !important;
}

.px-8 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-9 {
  padding-right: 2.8125rem !important;
  padding-left: 2.8125rem !important;
}

.px-10 {
  padding-right: 3.125rem !important;
  padding-left: 3.125rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
}

.py-2 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.py-3 {
  padding-top: 0.9375rem !important;
  padding-bottom: 0.9375rem !important;
}

.py-4 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-5 {
  padding-top: 1.625rem !important;
  padding-bottom: 1.625rem !important;
}

.py-6 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.py-7 {
  padding-top: 2.1875rem !important;
  padding-bottom: 2.1875rem !important;
}

.py-8 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-9 {
  padding-top: 2.8125rem !important;
  padding-bottom: 2.8125rem !important;
}

.py-10 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.3125rem !important;
}

.pt-2 {
  padding-top: 0.625rem !important;
}

.pt-3 {
  padding-top: 0.9375rem !important;
}

.pt-4 {
  padding-top: 1.25rem !important;
}

.pt-5 {
  padding-top: 1.625rem !important;
}

.pt-6 {
  padding-top: 1.875rem !important;
}

.pt-7 {
  padding-top: 2.1875rem !important;
}

.pt-8 {
  padding-top: 2.5rem !important;
}

.pt-9 {
  padding-top: 2.8125rem !important;
}

.pt-10 {
  padding-top: 3.125rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.3125rem !important;
}

.pe-2 {
  padding-right: 0.625rem !important;
}

.pe-3 {
  padding-right: 0.9375rem !important;
}

.pe-4 {
  padding-right: 1.25rem !important;
}

.pe-5 {
  padding-right: 1.625rem !important;
}

.pe-6 {
  padding-right: 1.875rem !important;
}

.pe-7 {
  padding-right: 2.1875rem !important;
}

.pe-8 {
  padding-right: 2.5rem !important;
}

.pe-9 {
  padding-right: 2.8125rem !important;
}

.pe-10 {
  padding-right: 3.125rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.3125rem !important;
}

.pb-2 {
  padding-bottom: 0.625rem !important;
}

.pb-3 {
  padding-bottom: 0.9375rem !important;
}

.pb-4 {
  padding-bottom: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.625rem !important;
}

.pb-6 {
  padding-bottom: 1.875rem !important;
}

.pb-7 {
  padding-bottom: 2.1875rem !important;
}

.pb-8 {
  padding-bottom: 2.5rem !important;
}

.pb-9 {
  padding-bottom: 2.8125rem !important;
}

.pb-10 {
  padding-bottom: 3.125rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.3125rem !important;
}

.ps-2 {
  padding-left: 0.625rem !important;
}

.ps-3 {
  padding-left: 0.9375rem !important;
}

.ps-4 {
  padding-left: 1.25rem !important;
}

.ps-5 {
  padding-left: 1.625rem !important;
}

.ps-6 {
  padding-left: 1.875rem !important;
}

.ps-7 {
  padding-left: 2.1875rem !important;
}

.ps-8 {
  padding-left: 2.5rem !important;
}

.ps-9 {
  padding-left: 2.8125rem !important;
}

.ps-10 {
  padding-left: 3.125rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #309255 !important;
}

.text-secondary {
  color: #e7f8ee !important;
}

.text-success {
  color: #198754 !important;
}

.text-info {
  color: #363b42 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #212832 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #52565b !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #309255 !important;
}

.bg-secondary {
  background-color: #e7f8ee !important;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-info {
  background-color: #363b42 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #212832 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.3125rem !important;
  }
  .gap-sm-2 {
    gap: 0.625rem !important;
  }
  .gap-sm-3 {
    gap: 0.9375rem !important;
  }
  .gap-sm-4 {
    gap: 1.25rem !important;
  }
  .gap-sm-5 {
    gap: 1.625rem !important;
  }
  .gap-sm-6 {
    gap: 1.875rem !important;
  }
  .gap-sm-7 {
    gap: 2.1875rem !important;
  }
  .gap-sm-8 {
    gap: 2.5rem !important;
  }
  .gap-sm-9 {
    gap: 2.8125rem !important;
  }
  .gap-sm-10 {
    gap: 3.125rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.3125rem !important;
  }
  .m-sm-2 {
    margin: 0.625rem !important;
  }
  .m-sm-3 {
    margin: 0.9375rem !important;
  }
  .m-sm-4 {
    margin: 1.25rem !important;
  }
  .m-sm-5 {
    margin: 1.625rem !important;
  }
  .m-sm-6 {
    margin: 1.875rem !important;
  }
  .m-sm-7 {
    margin: 2.1875rem !important;
  }
  .m-sm-8 {
    margin: 2.5rem !important;
  }
  .m-sm-9 {
    margin: 2.8125rem !important;
  }
  .m-sm-10 {
    margin: 3.125rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-sm-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-sm-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-sm-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-sm-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-sm-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-sm-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-sm-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-sm-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-sm-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-sm-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-sm-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-sm-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.625rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-5 {
    margin-top: 1.625rem !important;
  }
  .mt-sm-6 {
    margin-top: 1.875rem !important;
  }
  .mt-sm-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-sm-8 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-sm-10 {
    margin-top: 3.125rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.3125rem !important;
  }
  .me-sm-2 {
    margin-right: 0.625rem !important;
  }
  .me-sm-3 {
    margin-right: 0.9375rem !important;
  }
  .me-sm-4 {
    margin-right: 1.25rem !important;
  }
  .me-sm-5 {
    margin-right: 1.625rem !important;
  }
  .me-sm-6 {
    margin-right: 1.875rem !important;
  }
  .me-sm-7 {
    margin-right: 2.1875rem !important;
  }
  .me-sm-8 {
    margin-right: 2.5rem !important;
  }
  .me-sm-9 {
    margin-right: 2.8125rem !important;
  }
  .me-sm-10 {
    margin-right: 3.125rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.625rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-5 {
    margin-left: 1.625rem !important;
  }
  .ms-sm-6 {
    margin-left: 1.875rem !important;
  }
  .ms-sm-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-sm-8 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-sm-10 {
    margin-left: 3.125rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.3125rem !important;
  }
  .m-sm-n2 {
    margin: -0.625rem !important;
  }
  .m-sm-n3 {
    margin: -0.9375rem !important;
  }
  .m-sm-n4 {
    margin: -1.25rem !important;
  }
  .m-sm-n5 {
    margin: -1.625rem !important;
  }
  .m-sm-n6 {
    margin: -1.875rem !important;
  }
  .m-sm-n7 {
    margin: -2.1875rem !important;
  }
  .m-sm-n8 {
    margin: -2.5rem !important;
  }
  .m-sm-n9 {
    margin: -2.8125rem !important;
  }
  .m-sm-n10 {
    margin: -3.125rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-sm-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-sm-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-sm-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-sm-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-sm-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-sm-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-sm-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-sm-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-sm-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-sm-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-sm-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-sm-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-sm-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-sm-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-sm-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-sm-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-sm-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-sm-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-sm-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-sm-n10 {
    margin-top: -3.125rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.625rem !important;
  }
  .me-sm-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.25rem !important;
  }
  .me-sm-n5 {
    margin-right: -1.625rem !important;
  }
  .me-sm-n6 {
    margin-right: -1.875rem !important;
  }
  .me-sm-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-sm-n8 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-sm-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-sm-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-sm-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-sm-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-sm-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-sm-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-sm-n10 {
    margin-left: -3.125rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.3125rem !important;
  }
  .p-sm-2 {
    padding: 0.625rem !important;
  }
  .p-sm-3 {
    padding: 0.9375rem !important;
  }
  .p-sm-4 {
    padding: 1.25rem !important;
  }
  .p-sm-5 {
    padding: 1.625rem !important;
  }
  .p-sm-6 {
    padding: 1.875rem !important;
  }
  .p-sm-7 {
    padding: 2.1875rem !important;
  }
  .p-sm-8 {
    padding: 2.5rem !important;
  }
  .p-sm-9 {
    padding: 2.8125rem !important;
  }
  .p-sm-10 {
    padding: 3.125rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-sm-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-sm-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-sm-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-sm-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-sm-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-sm-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-sm-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-sm-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-sm-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-sm-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-sm-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-sm-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-sm-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-sm-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.625rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-5 {
    padding-top: 1.625rem !important;
  }
  .pt-sm-6 {
    padding-top: 1.875rem !important;
  }
  .pt-sm-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-sm-8 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-sm-10 {
    padding-top: 3.125rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.625rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-5 {
    padding-right: 1.625rem !important;
  }
  .pe-sm-6 {
    padding-right: 1.875rem !important;
  }
  .pe-sm-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-sm-8 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-sm-10 {
    padding-right: 3.125rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.625rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-5 {
    padding-left: 1.625rem !important;
  }
  .ps-sm-6 {
    padding-left: 1.875rem !important;
  }
  .ps-sm-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-sm-8 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-sm-10 {
    padding-left: 3.125rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.3125rem !important;
  }
  .gap-md-2 {
    gap: 0.625rem !important;
  }
  .gap-md-3 {
    gap: 0.9375rem !important;
  }
  .gap-md-4 {
    gap: 1.25rem !important;
  }
  .gap-md-5 {
    gap: 1.625rem !important;
  }
  .gap-md-6 {
    gap: 1.875rem !important;
  }
  .gap-md-7 {
    gap: 2.1875rem !important;
  }
  .gap-md-8 {
    gap: 2.5rem !important;
  }
  .gap-md-9 {
    gap: 2.8125rem !important;
  }
  .gap-md-10 {
    gap: 3.125rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.3125rem !important;
  }
  .m-md-2 {
    margin: 0.625rem !important;
  }
  .m-md-3 {
    margin: 0.9375rem !important;
  }
  .m-md-4 {
    margin: 1.25rem !important;
  }
  .m-md-5 {
    margin: 1.625rem !important;
  }
  .m-md-6 {
    margin: 1.875rem !important;
  }
  .m-md-7 {
    margin: 2.1875rem !important;
  }
  .m-md-8 {
    margin: 2.5rem !important;
  }
  .m-md-9 {
    margin: 2.8125rem !important;
  }
  .m-md-10 {
    margin: 3.125rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-md-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-md-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-md-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-md-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-md-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-md-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-md-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-md-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-md-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-md-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-md-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-md-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-md-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-md-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-md-2 {
    margin-top: 0.625rem !important;
  }
  .mt-md-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-md-4 {
    margin-top: 1.25rem !important;
  }
  .mt-md-5 {
    margin-top: 1.625rem !important;
  }
  .mt-md-6 {
    margin-top: 1.875rem !important;
  }
  .mt-md-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-md-8 {
    margin-top: 2.5rem !important;
  }
  .mt-md-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-md-10 {
    margin-top: 3.125rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.3125rem !important;
  }
  .me-md-2 {
    margin-right: 0.625rem !important;
  }
  .me-md-3 {
    margin-right: 0.9375rem !important;
  }
  .me-md-4 {
    margin-right: 1.25rem !important;
  }
  .me-md-5 {
    margin-right: 1.625rem !important;
  }
  .me-md-6 {
    margin-right: 1.875rem !important;
  }
  .me-md-7 {
    margin-right: 2.1875rem !important;
  }
  .me-md-8 {
    margin-right: 2.5rem !important;
  }
  .me-md-9 {
    margin-right: 2.8125rem !important;
  }
  .me-md-10 {
    margin-right: 3.125rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-md-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-md-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-md-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-md-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-md-2 {
    margin-left: 0.625rem !important;
  }
  .ms-md-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-md-4 {
    margin-left: 1.25rem !important;
  }
  .ms-md-5 {
    margin-left: 1.625rem !important;
  }
  .ms-md-6 {
    margin-left: 1.875rem !important;
  }
  .ms-md-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-md-8 {
    margin-left: 2.5rem !important;
  }
  .ms-md-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-md-10 {
    margin-left: 3.125rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.3125rem !important;
  }
  .m-md-n2 {
    margin: -0.625rem !important;
  }
  .m-md-n3 {
    margin: -0.9375rem !important;
  }
  .m-md-n4 {
    margin: -1.25rem !important;
  }
  .m-md-n5 {
    margin: -1.625rem !important;
  }
  .m-md-n6 {
    margin: -1.875rem !important;
  }
  .m-md-n7 {
    margin: -2.1875rem !important;
  }
  .m-md-n8 {
    margin: -2.5rem !important;
  }
  .m-md-n9 {
    margin: -2.8125rem !important;
  }
  .m-md-n10 {
    margin: -3.125rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-md-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-md-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-md-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-md-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-md-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-md-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-md-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-md-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-md-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-md-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-md-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-md-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-md-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-md-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-md-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-md-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-md-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-md-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-md-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-md-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-md-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-md-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-md-n10 {
    margin-top: -3.125rem !important;
  }
  .me-md-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-md-n2 {
    margin-right: -0.625rem !important;
  }
  .me-md-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-md-n4 {
    margin-right: -1.25rem !important;
  }
  .me-md-n5 {
    margin-right: -1.625rem !important;
  }
  .me-md-n6 {
    margin-right: -1.875rem !important;
  }
  .me-md-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-md-n8 {
    margin-right: -2.5rem !important;
  }
  .me-md-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-md-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-md-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-md-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-md-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-md-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-md-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-md-n10 {
    margin-left: -3.125rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.3125rem !important;
  }
  .p-md-2 {
    padding: 0.625rem !important;
  }
  .p-md-3 {
    padding: 0.9375rem !important;
  }
  .p-md-4 {
    padding: 1.25rem !important;
  }
  .p-md-5 {
    padding: 1.625rem !important;
  }
  .p-md-6 {
    padding: 1.875rem !important;
  }
  .p-md-7 {
    padding: 2.1875rem !important;
  }
  .p-md-8 {
    padding: 2.5rem !important;
  }
  .p-md-9 {
    padding: 2.8125rem !important;
  }
  .p-md-10 {
    padding: 3.125rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-md-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-md-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-md-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-md-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-md-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-md-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-md-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-md-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-md-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-md-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-md-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-md-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-md-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-md-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-md-2 {
    padding-top: 0.625rem !important;
  }
  .pt-md-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-md-4 {
    padding-top: 1.25rem !important;
  }
  .pt-md-5 {
    padding-top: 1.625rem !important;
  }
  .pt-md-6 {
    padding-top: 1.875rem !important;
  }
  .pt-md-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-md-8 {
    padding-top: 2.5rem !important;
  }
  .pt-md-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-md-10 {
    padding-top: 3.125rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-md-2 {
    padding-right: 0.625rem !important;
  }
  .pe-md-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-md-4 {
    padding-right: 1.25rem !important;
  }
  .pe-md-5 {
    padding-right: 1.625rem !important;
  }
  .pe-md-6 {
    padding-right: 1.875rem !important;
  }
  .pe-md-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-md-8 {
    padding-right: 2.5rem !important;
  }
  .pe-md-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-md-10 {
    padding-right: 3.125rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-md-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-md-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-md-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-md-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-md-2 {
    padding-left: 0.625rem !important;
  }
  .ps-md-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-md-4 {
    padding-left: 1.25rem !important;
  }
  .ps-md-5 {
    padding-left: 1.625rem !important;
  }
  .ps-md-6 {
    padding-left: 1.875rem !important;
  }
  .ps-md-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-md-8 {
    padding-left: 2.5rem !important;
  }
  .ps-md-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-md-10 {
    padding-left: 3.125rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.3125rem !important;
  }
  .gap-lg-2 {
    gap: 0.625rem !important;
  }
  .gap-lg-3 {
    gap: 0.9375rem !important;
  }
  .gap-lg-4 {
    gap: 1.25rem !important;
  }
  .gap-lg-5 {
    gap: 1.625rem !important;
  }
  .gap-lg-6 {
    gap: 1.875rem !important;
  }
  .gap-lg-7 {
    gap: 2.1875rem !important;
  }
  .gap-lg-8 {
    gap: 2.5rem !important;
  }
  .gap-lg-9 {
    gap: 2.8125rem !important;
  }
  .gap-lg-10 {
    gap: 3.125rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.3125rem !important;
  }
  .m-lg-2 {
    margin: 0.625rem !important;
  }
  .m-lg-3 {
    margin: 0.9375rem !important;
  }
  .m-lg-4 {
    margin: 1.25rem !important;
  }
  .m-lg-5 {
    margin: 1.625rem !important;
  }
  .m-lg-6 {
    margin: 1.875rem !important;
  }
  .m-lg-7 {
    margin: 2.1875rem !important;
  }
  .m-lg-8 {
    margin: 2.5rem !important;
  }
  .m-lg-9 {
    margin: 2.8125rem !important;
  }
  .m-lg-10 {
    margin: 3.125rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-lg-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-lg-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-lg-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-lg-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-lg-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-lg-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-lg-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-lg-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-lg-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-lg-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-lg-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-lg-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.625rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-5 {
    margin-top: 1.625rem !important;
  }
  .mt-lg-6 {
    margin-top: 1.875rem !important;
  }
  .mt-lg-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-lg-8 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-lg-10 {
    margin-top: 3.125rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.3125rem !important;
  }
  .me-lg-2 {
    margin-right: 0.625rem !important;
  }
  .me-lg-3 {
    margin-right: 0.9375rem !important;
  }
  .me-lg-4 {
    margin-right: 1.25rem !important;
  }
  .me-lg-5 {
    margin-right: 1.625rem !important;
  }
  .me-lg-6 {
    margin-right: 1.875rem !important;
  }
  .me-lg-7 {
    margin-right: 2.1875rem !important;
  }
  .me-lg-8 {
    margin-right: 2.5rem !important;
  }
  .me-lg-9 {
    margin-right: 2.8125rem !important;
  }
  .me-lg-10 {
    margin-right: 3.125rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.625rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-5 {
    margin-left: 1.625rem !important;
  }
  .ms-lg-6 {
    margin-left: 1.875rem !important;
  }
  .ms-lg-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-lg-8 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-lg-10 {
    margin-left: 3.125rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.3125rem !important;
  }
  .m-lg-n2 {
    margin: -0.625rem !important;
  }
  .m-lg-n3 {
    margin: -0.9375rem !important;
  }
  .m-lg-n4 {
    margin: -1.25rem !important;
  }
  .m-lg-n5 {
    margin: -1.625rem !important;
  }
  .m-lg-n6 {
    margin: -1.875rem !important;
  }
  .m-lg-n7 {
    margin: -2.1875rem !important;
  }
  .m-lg-n8 {
    margin: -2.5rem !important;
  }
  .m-lg-n9 {
    margin: -2.8125rem !important;
  }
  .m-lg-n10 {
    margin: -3.125rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-lg-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-lg-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-lg-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-lg-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-lg-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-lg-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-lg-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-lg-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-lg-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-lg-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-lg-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-lg-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-lg-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-lg-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-lg-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-lg-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-lg-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-lg-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-lg-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-lg-n10 {
    margin-top: -3.125rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.625rem !important;
  }
  .me-lg-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.25rem !important;
  }
  .me-lg-n5 {
    margin-right: -1.625rem !important;
  }
  .me-lg-n6 {
    margin-right: -1.875rem !important;
  }
  .me-lg-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-lg-n8 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-lg-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-lg-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-lg-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-lg-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-lg-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-lg-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-lg-n10 {
    margin-left: -3.125rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.3125rem !important;
  }
  .p-lg-2 {
    padding: 0.625rem !important;
  }
  .p-lg-3 {
    padding: 0.9375rem !important;
  }
  .p-lg-4 {
    padding: 1.25rem !important;
  }
  .p-lg-5 {
    padding: 1.625rem !important;
  }
  .p-lg-6 {
    padding: 1.875rem !important;
  }
  .p-lg-7 {
    padding: 2.1875rem !important;
  }
  .p-lg-8 {
    padding: 2.5rem !important;
  }
  .p-lg-9 {
    padding: 2.8125rem !important;
  }
  .p-lg-10 {
    padding: 3.125rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-lg-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-lg-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-lg-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-lg-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-lg-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-lg-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-lg-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-lg-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-lg-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-lg-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-lg-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-lg-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-lg-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-lg-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.625rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-5 {
    padding-top: 1.625rem !important;
  }
  .pt-lg-6 {
    padding-top: 1.875rem !important;
  }
  .pt-lg-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-lg-8 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-lg-10 {
    padding-top: 3.125rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.625rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-5 {
    padding-right: 1.625rem !important;
  }
  .pe-lg-6 {
    padding-right: 1.875rem !important;
  }
  .pe-lg-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-lg-8 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-lg-10 {
    padding-right: 3.125rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.625rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-5 {
    padding-left: 1.625rem !important;
  }
  .ps-lg-6 {
    padding-left: 1.875rem !important;
  }
  .ps-lg-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-lg-8 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-lg-10 {
    padding-left: 3.125rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.3125rem !important;
  }
  .gap-xl-2 {
    gap: 0.625rem !important;
  }
  .gap-xl-3 {
    gap: 0.9375rem !important;
  }
  .gap-xl-4 {
    gap: 1.25rem !important;
  }
  .gap-xl-5 {
    gap: 1.625rem !important;
  }
  .gap-xl-6 {
    gap: 1.875rem !important;
  }
  .gap-xl-7 {
    gap: 2.1875rem !important;
  }
  .gap-xl-8 {
    gap: 2.5rem !important;
  }
  .gap-xl-9 {
    gap: 2.8125rem !important;
  }
  .gap-xl-10 {
    gap: 3.125rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.3125rem !important;
  }
  .m-xl-2 {
    margin: 0.625rem !important;
  }
  .m-xl-3 {
    margin: 0.9375rem !important;
  }
  .m-xl-4 {
    margin: 1.25rem !important;
  }
  .m-xl-5 {
    margin: 1.625rem !important;
  }
  .m-xl-6 {
    margin: 1.875rem !important;
  }
  .m-xl-7 {
    margin: 2.1875rem !important;
  }
  .m-xl-8 {
    margin: 2.5rem !important;
  }
  .m-xl-9 {
    margin: 2.8125rem !important;
  }
  .m-xl-10 {
    margin: 3.125rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-xl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-xl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-xl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-xl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-xl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-xl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-xl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-xl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.625rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-5 {
    margin-top: 1.625rem !important;
  }
  .mt-xl-6 {
    margin-top: 1.875rem !important;
  }
  .mt-xl-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-xl-8 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-xl-10 {
    margin-top: 3.125rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.3125rem !important;
  }
  .me-xl-2 {
    margin-right: 0.625rem !important;
  }
  .me-xl-3 {
    margin-right: 0.9375rem !important;
  }
  .me-xl-4 {
    margin-right: 1.25rem !important;
  }
  .me-xl-5 {
    margin-right: 1.625rem !important;
  }
  .me-xl-6 {
    margin-right: 1.875rem !important;
  }
  .me-xl-7 {
    margin-right: 2.1875rem !important;
  }
  .me-xl-8 {
    margin-right: 2.5rem !important;
  }
  .me-xl-9 {
    margin-right: 2.8125rem !important;
  }
  .me-xl-10 {
    margin-right: 3.125rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.625rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-5 {
    margin-left: 1.625rem !important;
  }
  .ms-xl-6 {
    margin-left: 1.875rem !important;
  }
  .ms-xl-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-xl-8 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-xl-10 {
    margin-left: 3.125rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.3125rem !important;
  }
  .m-xl-n2 {
    margin: -0.625rem !important;
  }
  .m-xl-n3 {
    margin: -0.9375rem !important;
  }
  .m-xl-n4 {
    margin: -1.25rem !important;
  }
  .m-xl-n5 {
    margin: -1.625rem !important;
  }
  .m-xl-n6 {
    margin: -1.875rem !important;
  }
  .m-xl-n7 {
    margin: -2.1875rem !important;
  }
  .m-xl-n8 {
    margin: -2.5rem !important;
  }
  .m-xl-n9 {
    margin: -2.8125rem !important;
  }
  .m-xl-n10 {
    margin: -3.125rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xl-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-xl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-xl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-xl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-xl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xl-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-xl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-xl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-xl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-xl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-xl-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-xl-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-xl-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-xl-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-xl-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-xl-n10 {
    margin-top: -3.125rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.625rem !important;
  }
  .me-xl-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.25rem !important;
  }
  .me-xl-n5 {
    margin-right: -1.625rem !important;
  }
  .me-xl-n6 {
    margin-right: -1.875rem !important;
  }
  .me-xl-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-xl-n8 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-xl-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-xl-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-xl-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-xl-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-xl-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-xl-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-xl-n10 {
    margin-left: -3.125rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.3125rem !important;
  }
  .p-xl-2 {
    padding: 0.625rem !important;
  }
  .p-xl-3 {
    padding: 0.9375rem !important;
  }
  .p-xl-4 {
    padding: 1.25rem !important;
  }
  .p-xl-5 {
    padding: 1.625rem !important;
  }
  .p-xl-6 {
    padding: 1.875rem !important;
  }
  .p-xl-7 {
    padding: 2.1875rem !important;
  }
  .p-xl-8 {
    padding: 2.5rem !important;
  }
  .p-xl-9 {
    padding: 2.8125rem !important;
  }
  .p-xl-10 {
    padding: 3.125rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-xl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-xl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-xl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-xl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-xl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-xl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-xl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-xl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-xl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-xl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.625rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-5 {
    padding-top: 1.625rem !important;
  }
  .pt-xl-6 {
    padding-top: 1.875rem !important;
  }
  .pt-xl-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-xl-8 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-xl-10 {
    padding-top: 3.125rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.625rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-5 {
    padding-right: 1.625rem !important;
  }
  .pe-xl-6 {
    padding-right: 1.875rem !important;
  }
  .pe-xl-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-xl-8 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-xl-10 {
    padding-right: 3.125rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.625rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-5 {
    padding-left: 1.625rem !important;
  }
  .ps-xl-6 {
    padding-left: 1.875rem !important;
  }
  .ps-xl-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-xl-8 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-xl-10 {
    padding-left: 3.125rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1600px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.3125rem !important;
  }
  .gap-xxl-2 {
    gap: 0.625rem !important;
  }
  .gap-xxl-3 {
    gap: 0.9375rem !important;
  }
  .gap-xxl-4 {
    gap: 1.25rem !important;
  }
  .gap-xxl-5 {
    gap: 1.625rem !important;
  }
  .gap-xxl-6 {
    gap: 1.875rem !important;
  }
  .gap-xxl-7 {
    gap: 2.1875rem !important;
  }
  .gap-xxl-8 {
    gap: 2.5rem !important;
  }
  .gap-xxl-9 {
    gap: 2.8125rem !important;
  }
  .gap-xxl-10 {
    gap: 3.125rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.3125rem !important;
  }
  .m-xxl-2 {
    margin: 0.625rem !important;
  }
  .m-xxl-3 {
    margin: 0.9375rem !important;
  }
  .m-xxl-4 {
    margin: 1.25rem !important;
  }
  .m-xxl-5 {
    margin: 1.625rem !important;
  }
  .m-xxl-6 {
    margin: 1.875rem !important;
  }
  .m-xxl-7 {
    margin: 2.1875rem !important;
  }
  .m-xxl-8 {
    margin: 2.5rem !important;
  }
  .m-xxl-9 {
    margin: 2.8125rem !important;
  }
  .m-xxl-10 {
    margin: 3.125rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xxl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xxl-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-xxl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xxl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-xxl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-xxl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xxl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xxl-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-xxl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xxl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-xxl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-xxl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.625rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-5 {
    margin-top: 1.625rem !important;
  }
  .mt-xxl-6 {
    margin-top: 1.875rem !important;
  }
  .mt-xxl-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-xxl-8 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-xxl-10 {
    margin-top: 3.125rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.3125rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.625rem !important;
  }
  .me-xxl-3 {
    margin-right: 0.9375rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.25rem !important;
  }
  .me-xxl-5 {
    margin-right: 1.625rem !important;
  }
  .me-xxl-6 {
    margin-right: 1.875rem !important;
  }
  .me-xxl-7 {
    margin-right: 2.1875rem !important;
  }
  .me-xxl-8 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-9 {
    margin-right: 2.8125rem !important;
  }
  .me-xxl-10 {
    margin-right: 3.125rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.625rem !important;
  }
  .ms-xxl-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.25rem !important;
  }
  .ms-xxl-5 {
    margin-left: 1.625rem !important;
  }
  .ms-xxl-6 {
    margin-left: 1.875rem !important;
  }
  .ms-xxl-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-xxl-8 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-xxl-10 {
    margin-left: 3.125rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.3125rem !important;
  }
  .m-xxl-n2 {
    margin: -0.625rem !important;
  }
  .m-xxl-n3 {
    margin: -0.9375rem !important;
  }
  .m-xxl-n4 {
    margin: -1.25rem !important;
  }
  .m-xxl-n5 {
    margin: -1.625rem !important;
  }
  .m-xxl-n6 {
    margin: -1.875rem !important;
  }
  .m-xxl-n7 {
    margin: -2.1875rem !important;
  }
  .m-xxl-n8 {
    margin: -2.5rem !important;
  }
  .m-xxl-n9 {
    margin: -2.8125rem !important;
  }
  .m-xxl-n10 {
    margin: -3.125rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xxl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xxl-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-xxl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-xxl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-xxl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xxl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-xxl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -3.125rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.625rem !important;
  }
  .me-xxl-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.25rem !important;
  }
  .me-xxl-n5 {
    margin-right: -1.625rem !important;
  }
  .me-xxl-n6 {
    margin-right: -1.875rem !important;
  }
  .me-xxl-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-xxl-n8 {
    margin-right: -2.5rem !important;
  }
  .me-xxl-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-xxl-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -3.125rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.3125rem !important;
  }
  .p-xxl-2 {
    padding: 0.625rem !important;
  }
  .p-xxl-3 {
    padding: 0.9375rem !important;
  }
  .p-xxl-4 {
    padding: 1.25rem !important;
  }
  .p-xxl-5 {
    padding: 1.625rem !important;
  }
  .p-xxl-6 {
    padding: 1.875rem !important;
  }
  .p-xxl-7 {
    padding: 2.1875rem !important;
  }
  .p-xxl-8 {
    padding: 2.5rem !important;
  }
  .p-xxl-9 {
    padding: 2.8125rem !important;
  }
  .p-xxl-10 {
    padding: 3.125rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xxl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xxl-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-xxl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xxl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-xxl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-xxl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xxl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xxl-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-xxl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xxl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-xxl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-xxl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.625rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-5 {
    padding-top: 1.625rem !important;
  }
  .pt-xxl-6 {
    padding-top: 1.875rem !important;
  }
  .pt-xxl-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-xxl-8 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-xxl-10 {
    padding-top: 3.125rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.625rem !important;
  }
  .pe-xxl-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.25rem !important;
  }
  .pe-xxl-5 {
    padding-right: 1.625rem !important;
  }
  .pe-xxl-6 {
    padding-right: 1.875rem !important;
  }
  .pe-xxl-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-xxl-8 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-xxl-10 {
    padding-right: 3.125rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.625rem !important;
  }
  .ps-xxl-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.25rem !important;
  }
  .ps-xxl-5 {
    padding-left: 1.625rem !important;
  }
  .ps-xxl-6 {
    padding-left: 1.875rem !important;
  }
  .ps-xxl-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-xxl-8 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-xxl-10 {
    padding-left: 3.125rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/
/*-- Custom Font --*/
@font-face {
  font-family: 'Gordita';
  src: url("../fonts/Gordita-Light.eot");
  src: url("../fonts/Gordita-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Gordita-Light.woff2") format("woff2"), url("../fonts/Gordita-Light.woff") format("woff"), url("../fonts/Gordita-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gordita';
  src: url("../fonts/Gordita-Regular.eot");
  src: url("../fonts/Gordita-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Gordita-Regular.woff2") format("woff2"), url("../fonts/Gordita-Regular.woff") format("woff"), url("../fonts/Gordita-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gordita';
  src: url("../fonts/Gordita-Medium.eot");
  src: url("../fonts/Gordita-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Gordita-Medium.woff2") format("woff2"), url("../fonts/Gordita-Medium.woff") format("woff"), url("../fonts/Gordita-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gordita';
  src: url("../fonts/Gordita-Bold.eot");
  src: url("../fonts/Gordita-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Gordita-Bold.woff2") format("woff2"), url("../fonts/Gordita-Bold.woff") format("woff"), url("../fonts/Gordita-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  outline: none;
  visibility: visible;
  overflow-X: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: "";
}

body[dir="rtl"] {
  text-align: right;
}

a,
button {
  transition: all 0.3s ease 0s;
  outline: 0;
}

a:focus,
button:focus {
  outline: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  font-size: 15px;
  line-height: 1.8;
}

p:last-child {
  margin-bottom: 0;
}

ul, ol {
  padding: 0;
  list-style: none;
  margin: 0;
}

img {
  max-width: 100%;
}

/* Section Style */
.section,
.main-wrapper {
  float: left;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-wrapper-02 {
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .main-wrapper-02 {
    padding-bottom: 65px;
  }
}

/* Custom Container */
.custom-container {
  max-width: 1750px;
}

/* Section Padding */
.section-padding {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .section-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-padding-02 {
  padding-top: 80px;
}

@media only screen and (max-width: 767px) {
  .section-padding-02 {
    padding-top: 60px;
  }
}

/* Section Title */
.section-title .sub-title {
  font-size: 20px;
  font-weight: 500;
  color: #309255;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .section-title .sub-title {
    font-size: 16px;
  }
}

.section-title .main-title {
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1.4;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title .main-title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title .main-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .section-title .main-title {
    font-size: 24px;
  }
}

.section-title .main-title span {
  color: #309255;
  position: relative;
}

.section-title.section-title-white .sub-title {
  color: #fff;
}

.section-title.section-title-white .main-title {
  color: #fff;
}

.section-title.shape-01 .main-title span::before {
  position: absolute;
  content: '';
  background-image: url(../images/shape/shape-11.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 122px;
  height: 24px;
  left: 50%;
  bottom: -26px;
  transform: translateX(-50%);
}

@media only screen and (max-width: 575px) {
  .section-title.shape-01 .main-title span::before {
    display: none;
  }
}

.section-title.shape-02 {
  padding-bottom: 20px;
}

@media only screen and (max-width: 575px) {
  .section-title.shape-02 {
    padding-bottom: 0;
  }
}

.section-title.shape-02 .main-title span::before {
  position: absolute;
  content: '';
  background-image: url(../images/shape/shape-4.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 210px;
  height: 21px;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.shape-02 .main-title span::before {
    width: 140px;
    height: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .section-title.shape-02 .main-title span::before {
    display: none;
  }
}

.section-title.shape-03 {
  padding-bottom: 11px;
}

@media only screen and (max-width: 575px) {
  .section-title.shape-03 {
    padding-bottom: 0;
  }
}

.section-title.shape-03 .main-title span::before {
  position: absolute;
  content: '';
  background-image: url(../images/shape/shape-4.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 121px;
  height: 13px;
  right: 0;
  bottom: -11px;
}

@media only screen and (max-width: 575px) {
  .section-title.shape-03 .main-title span::before {
    display: none;
  }
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  transition: all 0.3s linear;
}

@media (prefers-reduced-motion: reduce) {
  .overlay {
    transition: none;
  }
}

.overlay.open {
  opacity: 0.7;
  visibility: visible;
}

/* Tab Content */
.tab-content .tab-pane {
  display: block;
  overflow: hidden;
  height: 0;
  visibility: hidden;
  max-width: 100%;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

/* Page Pagination */
.page-pagination {
  padding-top: 60px;
}

.page-pagination .pagination li {
  margin-right: 12px;
}

.page-pagination .pagination li:last-child {
  margin-right: 0;
}

.page-pagination .pagination li a {
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #212832;
  border: 1px solid rgba(48, 146, 85, 0.2);
  display: block;
  transform: all 0.3s ease 0s;
  border-radius: 50%;
}

@media only screen and (max-width: 575px) {
  .page-pagination .pagination li a {
    width: 35px;
    height: 35px;
    line-height: 33px;
    font-size: 14px;
  }
}

.page-pagination .pagination li a.active, .page-pagination .pagination li a:hover {
  color: #fff;
  background-color: #309255;
  border-color: #309255;
}

/* Custom Animation */
.animation-round {
  animation: round 8s linear 0s infinite normal forwards;
}

@keyframes round {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animation-left {
  animation: left 5s linear 0s infinite normal forwards;
}

@keyframes left {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(0);
  }
}

.animation-right {
  animation: right 5s linear 0s infinite normal forwards;
}

@keyframes right {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(40px);
  }
  100% {
    transform: translateX(0);
  }
}

.animation-up {
  animation: up 5s linear 0s infinite normal forwards;
}

@keyframes up {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0);
  }
}

.animation-down {
  animation: down 5s linear 0s infinite normal forwards;
}

@keyframes down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(40px);
  }
  100% {
    transform: translateY(0);
  }
}

/*----------------------------------------*/
/*  02. Component CSS
/*----------------------------------------*/
/*--
/*  2.1 - Button CSS
/*----------------------------------------*/
.btn {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .btn {
    font-size: 14px;
  }
}

.btn::before {
  width: 300px;
  height: 300px;
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  z-index: -1;
  transition: 0.4s all linear;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 50%;
}

.btn i {
  margin-left: 8px;
}

.btn:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

.btn-primary {
  color: #fff;
}

.btn-primary:focus {
  color: #fff;
}

.btn-hover-primary:hover {
  border-color: #309255;
  color: #fff;
}

.btn-hover-primary:hover::before {
  background-color: #309255;
}

.btn-outline-primary {
  border: 2px solid #309255;
  line-height: 61px;
  color: #212832;
}

.btn-outline-primary:hover {
  background: #309255;
  color: #fff;
}

.btn-secondary {
  color: #309255;
}

.btn-secondary:focus {
  color: #309255;
}

.btn-hover-secondary:hover {
  border-color: #e7f8ee;
  color: #fff;
}

.btn-hover-secondary:hover::before {
  background-color: #e7f8ee;
}

.btn-outline-secondary {
  border: 2px solid #e7f8ee;
  line-height: 61px;
  color: #212832;
}

.btn-outline-secondary:hover {
  background: #e7f8ee;
  color: #fff;
}

.btn-success {
  color: #fff;
}

.btn-success:focus {
  color: #fff;
}

.btn-hover-success:hover {
  border-color: #198754;
  color: #fff;
}

.btn-hover-success:hover::before {
  background-color: #198754;
}

.btn-outline-success {
  border: 2px solid #198754;
  line-height: 61px;
  color: #212832;
}

.btn-outline-success:hover {
  background: #198754;
  color: #fff;
}

.btn-info {
  color: #212832;
}

.btn-info:focus {
  color: #212832;
}

.btn-hover-info:hover {
  border-color: #363b42;
  color: #fff;
}

.btn-hover-info:hover::before {
  background-color: #363b42;
}

.btn-outline-info {
  border: 2px solid #363b42;
  line-height: 61px;
  color: #212832;
}

.btn-outline-info:hover {
  background: #363b42;
  color: #212832;
}

.btn-warning {
  color: #212832;
}

.btn-warning:focus {
  color: #212832;
}

.btn-hover-warning:hover {
  border-color: #ffc107;
  color: #212832;
}

.btn-hover-warning:hover::before {
  background-color: #ffc107;
}

.btn-outline-warning {
  border: 2px solid #ffc107;
  line-height: 61px;
  color: #212832;
}

.btn-outline-warning:hover {
  background: #ffc107;
  color: #212832;
}

.btn-danger {
  color: #fff;
}

.btn-danger:focus {
  color: #fff;
}

.btn-hover-danger:hover {
  border-color: #dc3545;
  color: #fff;
}

.btn-hover-danger:hover::before {
  background-color: #dc3545;
}

.btn-outline-danger {
  border: 2px solid #dc3545;
  line-height: 61px;
  color: #212832;
}

.btn-outline-danger:hover {
  background: #dc3545;
  color: #fff;
}

.btn-light {
  color: #212832;
}

.btn-light:focus {
  color: #212832;
}

.btn-hover-light:hover {
  border-color: #f8f9fa;
  color: #212832;
}

.btn-hover-light:hover::before {
  background-color: #f8f9fa;
}

.btn-outline-light {
  border: 2px solid #f8f9fa;
  line-height: 61px;
  color: #fff;
}

.btn-outline-light:hover {
  background: #f8f9fa;
  color: #212832;
}

.btn-dark {
  color: #fff;
}

.btn-dark:focus {
  color: #fff;
}

.btn-hover-dark:hover {
  border-color: #212832;
  color: #fff;
}

.btn-hover-dark:hover::before {
  background-color: #212832;
}

.btn-outline-dark {
  border: 2px solid #212832;
  line-height: 61px;
  color: #212832;
}

.btn-outline-dark:hover {
  background: #212832;
  color: #fff;
}

/*--
/*  2.2 - Blockquote CSS
/*----------------------------------------*/
.blockquote {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-top: 22px;
  padding-bottom: 8px;
  padding-left: 55px;
}

@media only screen and (max-width: 767px) {
  .blockquote {
    padding-left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .blockquote {
    display: block;
  }
}

.blockquote .quote {
  font-size: 150px;
  font-weight: 500;
  color: #309255;
  line-height: 120px;
  height: 60px;
  display: block;
}

.blockquote p {
  flex: 1;
  font-family: "Gordita";
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #212832 !important;
  margin-top: 0 !important;
  margin-bottom: 0;
  padding-left: 23px;
  line-height: 1.58;
}

@media only screen and (max-width: 767px) {
  .blockquote p {
    font-size: 18px !important;
    padding-left: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .blockquote p {
    padding-left: 0;
    padding-top: 15px;
    font-size: 16px !important;
  }
}

/*--
/*  2.3 - Form CSS
/*----------------------------------------*/
.single-form {
  margin-top: 20px;
}

.single-form input:not([type="checkbox"]):not([type="radio"]),
.single-form textarea {
  width: 100%;
  height: 60px;
  padding: 0 25px;
  font-size: 15px;
  color: #52565b;
  transition: all 0.3s ease 0s;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  background-color: #fff;
}

.single-form input:not([type="checkbox"]):not([type="radio"])::-webkit-input-placeholder,
.single-form textarea::-webkit-input-placeholder {
  opacity: 1;
}

.single-form input:not([type="checkbox"]):not([type="radio"]):-moz-placeholder,
.single-form textarea:-moz-placeholder {
  opacity: 1;
}

.single-form input:not([type="checkbox"]):not([type="radio"])::-moz-placeholder,
.single-form textarea::-moz-placeholder {
  opacity: 1;
}

.single-form input:not([type="checkbox"]):not([type="radio"]):-ms-input-placeholder,
.single-form textarea:-ms-input-placeholder {
  opacity: 1;
}

.single-form input:not([type="checkbox"]):not([type="radio"]) + input,
.single-form textarea + input {
  margin-top: 15px;
}

.single-form input:not([type="checkbox"]):not([type="radio"]):focus,
.single-form textarea:focus {
  border-color: #309255;
  outline: none;
}

.single-form label {
  font-size: 15px;
  color: #212832;
  margin-bottom: 12px;
}

.single-form input[type="checkbox"] {
  display: none;
}

.single-form input[type="checkbox"] + label {
  padding-left: 22px;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 15px;
}

.single-form input[type="checkbox"] + label span {
  width: 14px;
  height: 14px;
  border: 1px solid #e1e1e1;
  position: absolute;
  display: block;
  top: 4px;
  left: 0;
  transition: all 0.3s ease 0s;
}

.single-form input[type="checkbox"] + label span::before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: "\f107";
  font-family: FontAwesome;
  font-size: 14px;
  line-height: 12px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  color: #fff;
  text-align: center;
}

.single-form input[type="checkbox"]:checked + label span {
  background-color: #309255;
  border-color: #309255;
}

.single-form input[type="checkbox"]:checked + label span::before {
  opacity: 1;
  visibility: visible;
}

.single-form textarea {
  padding-top: 15px;
  height: 160px;
  resize: none;
}

.radio input[type="radio"] {
  display: none;
}

.radio input[type="radio"] + label {
  padding-left: 20px;
  position: relative;
  font-size: 14px;
  line-height: 27px;
}

.radio input[type="radio"] + label span {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 1px solid #212832;
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
}

.radio input[type="radio"] + label span::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #212832;
  top: 0;
  left: 0;
  transform: scale(0);
  transition: all 0.3s linear;
}

.radio input[type="radio"]:checked + label span::before {
  transform: scale(0.7);
}

.reviews-rating {
  margin-top: 15px;
}

.reviews-rating label {
  display: block;
  font-size: 14px;
  font-weight: 400;
  font-family: "Gordita";
  color: #52565b;
  margin-bottom: 0;
}

.reviews-rating .rating {
  display: flex;
  margin-top: 5px;
}

.reviews-rating .rating li {
  font-size: 16px;
  color: #d0d0d0;
  margin: 0 2px;
  cursor: pointer;
}

.reviews-rating .rating li i {
  transition: all 0.3s ease 0s;
}

.reviews-rating .rating li i.star, .reviews-rating .rating li i.hover {
  color: #ffba00;
}

.single-select2 {
  margin-top: 20px;
}

.single-select2 span {
  display: block;
}

.single-select2 span:focus {
  outline: none;
}

.single-select2 .form-select2 .select2 {
  width: 100% !important;
}

.single-select2 .form-select2 .select2-container--default .select2-selection--single {
  height: 50px;
  border-color: #d7d7d7;
  transition: all 0.3s linear;
  border-radius: 0;
  outline: none;
}

@media only screen and (max-width: 767px) {
  .single-select2 .form-select2 .select2-container--default .select2-selection--single {
    height: 40px;
  }
}

.single-select2 .form-select2 .select2-container--default .select2-selection--single .select2-selection__rendered {
  width: 100%;
  line-height: 48px;
  border-radius: 0px;
  padding-left: 20px;
  padding-right: 28px;
  font-style: italic;
  font-size: 14px;
  color: #999999;
}

@media only screen and (max-width: 767px) {
  .single-select2 .form-select2 .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 38px;
  }
}

.single-select2 .form-select2 .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 48px;
  width: 36px;
}

.single-select2 .form-select2 .select2-container--default.select2-container--open .select2-selection--single {
  border-color: #309255;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #ebebeb;
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  outline: none;
}

.select2-dropdown {
  border-color: #ebebeb;
}

.select2-results__option {
  font-size: 14px;
}

.gj-picker {
  border: 1px solid #eeecec;
  padding: 10px;
}

.gj-picker div[role="navigator"] div:first-child,
.gj-picker div[role="navigator"] div:last-child {
  max-width: 42px;
}

.gj-picker table tr td.selected.gj-cursor-pointer div {
  color: #fff;
  background: #309255;
}

.gj-picker table tr td.today div {
  color: #309255;
}

.gj-picker table tr td div,
.gj-picker table tr th div {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
}

/*--
/*  2.4 - Modal CSS
/*----------------------------------------*/
.modal .modal-dialog {
  max-width: 900px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal .modal-dialog {
    max-width: 660px;
  }
}

@media only screen and (max-width: 767px) {
  .modal .modal-dialog {
    max-width: 540px;
  }
}

@media only screen and (max-width: 575px) {
  .modal .modal-dialog {
    max-width: 90%;
    margin: 0 auto;
  }
}

.modal .modal-dialog .modal-content .modal-header {
  padding: 12px 16px;
}

.modal .modal-dialog .modal-content .modal-header .modal-title {
  font-size: 22px;
  font-weight: 500;
  color: #212832;
  font-family: "Gordita";
  margin-bottom: 0;
}

.modal .modal-dialog .modal-content .reviews-form {
  padding-top: 0;
}

/*----------------------------------------*/
/*  03. Header CSS
/*----------------------------------------*/
.header-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

/*--
/*  3.1 - Header Top CSS
/*----------------------------------------*/
.header-top {
  background-color: #212832;
}

.header-top-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 7px;
}

.header-top-left {
  padding-top: 10px;
}

.header-top-left p {
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1.4;
}

.header-top-left p a {
  color: #309255;
  display: inline-block;
  font-weight: 500;
}

.header-top-medal {
  padding-top: 10px;
  position: relative;
}

.header-top-medal::before {
  position: absolute;
  content: '';
  width: 41px;
  height: 14px;
  background-image: url(../images/shape/shape-1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  top: 50%;
  transform: translateY(-50%);
  left: -110px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-top-medal::before {
    left: -55px;
    width: 30px;
    height: 10px;
  }
}

.header-top-medal::after {
  position: absolute;
  content: '';
  width: 41px;
  height: 14px;
  background-image: url(../images/shape/shape-2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  top: 50%;
  transform: translateY(-50%);
  right: -110px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-top-medal::after {
    right: -55px;
    width: 30px;
    height: 10px;
  }
}

.header-top-medal .top-info {
  display: flex;
}

.header-top-medal .top-info p {
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0;
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-top-medal .top-info p {
    font-size: 14px;
  }
}

.header-top-medal .top-info p + p {
  margin-left: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-top-medal .top-info p + p {
    margin-left: 25px;
  }
}

.header-top-medal .top-info p a {
  color: #fff;
}

.header-top-medal .top-info p a:hover {
  color: #309255;
}

.header-top-medal .top-info p i {
  color: #309255;
  font-size: 20px;
  padding-right: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-top-medal .top-info p i {
    font-size: 18px;
    padding-right: 10px;
  }
}

.header-top-right {
  padding-top: 10px;
}

.header-top-right .social {
  display: flex;
}

.header-top-right .social li {
  margin-right: 20px;
}

.header-top-right .social li:last-child {
  margin-right: 0;
}

.header-top-right .social li a {
  font-size: 20px;
  color: #fff;
}

.header-top-right .social li a:hover {
  color: #309255;
}

/*--
/*  3.2 - Header Main CSS
/*----------------------------------------*/
.header-main-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(48, 146, 85, 0.25);
  padding: 16px 15px;
  border-radius: 15px;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 30px;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 575px) {
  .header-main-wrapper {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (min-width: 1600px) {
  .header-main-wrapper {
    padding: 16px 25px;
    margin-left: -25px;
    margin-right: -25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .header-logo a img {
    width: 160px;
  }
}

@media only screen and (max-width: 575px) {
  .header-logo a img {
    width: 130px;
  }
}

.header-menu ul {
  display: flex;
  justify-content: center;
}

.header-menu ul li {
  position: relative;
  padding: 16px 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-menu ul li {
    padding: 16px 20px;
  }
}

.header-menu ul li a {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  color: #212832;
  transition: all 0.3s ease 0s;
  display: block;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-menu ul li a {
    font-size: 16px;
  }
}

.header-menu ul li a::before {
  position: absolute;
  content: '';
  background-image: url(../images/shape/shape-3.png);
  width: 70px;
  height: 5px;
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
}

.header-menu ul li:hover > a {
  color: #309255;
}

.header-menu ul li.active > a {
  color: #309255;
}

.header-menu ul li.active > a::before {
  opacity: 1;
  visibility: visible;
}

.header-menu ul li ul {
  display: block;
}

.header-menu ul li:hover > .sub-menu,
.header-menu ul li:hover > .mega-sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-menu ul li:nth-last-of-type(2) .sub-menu li .sub-menu {
    left: auto;
    right: 100%;
  }
}

.header-menu .sub-menu {
  position: absolute;
  width: 225px;
  background-color: #fff;
  border-top: 2px solid #212832;
  padding: 10px 0px;
  box-shadow: 2px 2px 20px rgba(33, 40, 50, 0.1);
  top: 110%;
  left: 0;
  z-index: 9;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.header-menu .sub-menu li {
  padding: 0;
}

.header-menu .sub-menu li a {
  padding: 7px 20px;
  color: #212832;
  border: 0;
  font-size: 16px;
}

.header-menu .sub-menu li a::before {
  display: none;
}

.header-menu .sub-menu li .sub-menu {
  top: 0px;
  left: 100%;
  opacity: 0;
  visibility: hidden;
}

.header-menu .sub-menu li.menu-item-has-children > a::after {
  content: "\eaa0";
  font-family: IcoFont;
  margin-left: 5px;
  font-size: 20px;
  line-height: 20px;
  display: block;
  float: right;
}

.header-menu .sub-menu li:hover > a {
  padding-left: 25px;
}

.header-menu .sub-menu li:hover .sub-menu {
  top: -12px;
  opacity: 1;
  visibility: visible;
}

.header-menu .mega-sub-menu {
  position: absolute;
  top: 110%;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: 2px 2px 20px rgba(33, 40, 50, 0.1);
  background-color: #fff;
  z-index: 9;
  padding: 30px 15px;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-wrap: wrap;
}

.header-menu .mega-sub-menu > li {
  width: 25%;
  padding: 0 15px;
}

.header-menu .mega-sub-menu > li.manu-banner {
  width: 50%;
  margin-top: 20px;
  position: relative;
}

.header-menu .mega-sub-menu > li.manu-banner img {
  width: 100%;
}

.header-menu .mega-sub-menu > li.manu-banner a::before {
  content: "";
  background: rgba(255, 255, 255, 0.3);
  bottom: 50%;
  top: 50%;
  left: 0;
  right: 0;
  pointer-events: none;
  -webkit-transition: all 900ms linear;
  -moz-transition: all 900ms linear;
  -ms-transition: all 900ms linear;
  -o-transition: all 900ms linear;
  transition: all 900ms linear;
}

.header-menu .mega-sub-menu > li.manu-banner a::after {
  content: "";
  background: rgba(255, 255, 255, 0.3);
  left: 51%;
  right: 50%;
  top: 0;
  bottom: 0;
  pointer-events: none;
  -webkit-transition: all 900ms linear;
  -moz-transition: all 900ms linear;
  -ms-transition: all 900ms linear;
  -o-transition: all 900ms linear;
  transition: all 900ms linear;
}

.header-menu .mega-sub-menu > li.manu-banner a:hover::before {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 900ms linear;
  -moz-transition: all 900ms linear;
  -ms-transition: all 900ms linear;
  -o-transition: all 900ms linear;
  transition: all 900ms linear;
  opacity: 0;
}

.header-menu .mega-sub-menu > li.manu-banner a:hover::after {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 900ms linear;
  -moz-transition: all 900ms linear;
  -ms-transition: all 900ms linear;
  -o-transition: all 900ms linear;
  transition: all 900ms linear;
  opacity: 0;
}

.header-menu .mega-sub-menu > li .menu-title {
  color: #212832;
  line-height: 1;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  display: block;
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
}

.header-menu .mega-sub-menu > li .menu-title::after {
  display: none;
}

.header-menu .mega-sub-menu > li:hover > a {
  color: #309255;
}

.header-menu .mega-sub-menu > li .menu-item {
  flex-direction: column;
  margin-top: 20px;
}

.header-menu .mega-sub-menu > li .menu-item > li {
  padding: 0;
}

.header-menu .mega-sub-menu > li .menu-item > li > a {
  color: #52565b;
  text-transform: capitalize;
  line-height: 32px;
  font-weight: 400;
  font-size: 14px;
  display: block;
}

.header-menu .mega-sub-menu > li .menu-item > li:hover > a {
  padding-left: 5px;
  color: #309255;
}

@media only screen and (max-width: 767px) {
  .header-sign-in-up {
    display: flex;
    align-items: center;
  }
}

.header-sign-in-up ul {
  display: flex;
  align-items: center;
}

.header-sign-in-up ul li {
  margin-right: 30px;
}

.header-sign-in-up ul li:last-child {
  margin-right: 0;
}

.header-sign-in-up ul li a {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  color: #212832;
  transition: all 0.3s ease 0s;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sign-in-up ul li a {
    font-size: 16px;
  }
}

.header-sign-in-up ul li a:hover {
  color: #309255;
}

.header-sign-in-up ul li a.sign-up {
  height: 60px;
  line-height: 56px;
  padding: 0 35px;
  border: 1px solid #309255;
  border-radius: 10px;
  background-color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-sign-in-up ul li a.sign-up {
    height: 50px;
    line-height: 46px;
    padding: 0 25px;
  }
}

.header-sign-in-up ul li a.sign-up:hover {
  background-color: #309255;
  color: #fff;
}

.header-toggle .menu-toggle {
  display: inline-block;
  background: none;
  border: 0;
  padding: 0;
}

.header-toggle .menu-toggle span {
  width: 23px;
  height: 2px;
  background-color: #212832;
  display: block;
}

.header-toggle .menu-toggle span:nth-of-type(2) {
  margin: 5px 0;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  animation: sticky 1s;
  box-shadow: 2px 4px 8px rgba(33, 40, 50, 0.15);
  background-color: #fff;
  padding: 0;
}

.sticky .header-main-wrapper {
  margin-top: 0;
  border: 0;
}

@media only screen and (max-width: 575px) {
  .sticky .header-main-wrapper {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@-webkit-keyframes sticky {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes sticky {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

/*--
/*  3.3 - Mobile Menu CSS
/*----------------------------------------*/
.mobile-menu {
  position: fixed;
  top: 0;
  width: 300px;
  height: 100%;
  left: -305px;
  background-color: #fff;
  z-index: 999;
  padding-top: 70px;
  transition: all 0.3s ease 0s;
  overflow: auto;
}

.mobile-menu.open {
  left: 0;
}

.mobile-menu .menu-close {
  position: absolute;
  top: 20px;
  right: 25px;
  font-size: 30px;
  color: #212832;
}

.mobile-top {
  padding-top: 30px;
}

.mobile-top p {
  font-size: 15px;
  color: #212832;
  font-weight: 400;
  margin-bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  line-height: 1.4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mobile-top p {
    font-size: 14px;
  }
}

.mobile-top p + p {
  margin-top: 5px;
}

.mobile-top p a {
  color: #212832;
}

.mobile-top p a:hover {
  color: #309255;
}

.mobile-top p i {
  color: #309255;
  font-size: 20px;
  padding-right: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mobile-top p i {
    font-size: 18px;
    padding-right: 10px;
  }
}

.mobile-sign-in-up {
  padding-top: 30px;
}

.mobile-sign-in-up ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-sign-in-up ul li {
  margin-right: 20px;
}

.mobile-sign-in-up ul li:last-child {
  margin-right: 0;
}

.mobile-sign-in-up ul li a {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: #212832;
  transition: all 0.3s ease 0s;
  display: block;
  height: 45px;
  line-height: 41px;
  padding: 0 30px;
  border: 1px solid #309255;
  border-radius: 5px;
  background-color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mobile-sign-in-up ul li a {
    font-size: 16px;
  }
}

.mobile-sign-in-up ul li a:hover {
  background-color: #309255;
  color: #fff;
}

.mobile-menu-items {
  padding: 30px 0;
}

.mobile-menu-items ul {
  border-top: 1px solid #eeecec;
}

.mobile-menu-items ul li {
  position: relative;
}

.mobile-menu-items ul li a {
  display: block;
  width: 100%;
  background: none;
  text-align: left;
  padding: 10px 20px;
  border: 0;
  border-bottom: 1px solid #eeecec;
  font-size: 15px;
  font-weight: 500;
  color: #212832;
}

.mobile-menu-items ul li .mobile-menu-expand {
  position: absolute;
  right: 20px;
  top: 12px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.mobile-menu-items ul li .mobile-menu-expand::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background-color: #212832;
  top: 50%;
  transform: translateY(-50%);
}

.mobile-menu-items ul li .mobile-menu-expand::after {
  position: absolute;
  content: '';
  width: 1px;
  height: 100%;
  background-color: #212832;
  left: 50%;
  bottom: 0;
  transition: 0.3s;
  transform: translateX(-50%);
}

@media (prefers-reduced-motion: reduce) {
  .mobile-menu-items ul li .mobile-menu-expand::after {
    transition: none;
  }
}

.mobile-menu-items ul li.active-expand > .mobile-menu-expand::after {
  height: 0;
}

.mobile-menu-items ul li ul {
  border-top: 0;
}

.mobile-menu-items ul li ul li a {
  padding-left: 12%;
}

.mobile-menu-items ul li ul li ul li a {
  padding-left: 24%;
}

.mobile-social {
  padding-bottom: 30px;
}

.mobile-social .social {
  display: flex;
  justify-content: center;
}

.mobile-social .social li {
  margin-right: 20px;
}

.mobile-social .social li:last-child {
  margin-right: 0;
}

.mobile-social .social li a {
  font-size: 20px;
  color: #212832;
}

.mobile-social .social li a:hover {
  color: #309255;
}

/*--
/*  3.4 - Login Header CSS
/*----------------------------------------*/
.login-header {
  background-color: #212832;
}

.login-header-wrapper.navbar {
  padding: 0 0;
}

.login-header-logo {
  padding: 25px 25px;
  background-color: #28313d;
}

.login-header-logo a + a {
  margin-left: 15px;
}

@media only screen and (max-width: 575px) {
  .login-header-logo a + a {
    display: none;
  }
}

.login-header-logo.logo-2 {
  margin-right: 50px;
  padding: 35px 50px;
  background-color: #28313d;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-header-logo.logo-2 {
    padding: 30px 20px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .login-header-logo.logo-2 {
    padding: 20px 20px;
    margin-right: 30px;
  }
}

.login-header-search {
  position: relative;
  width: 500px;
  margin-left: auto;
  margin-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-header-search {
    width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-header-search {
    width: 350px;
  }
}

@media only screen and (max-width: 767px) {
  .login-header-search {
    position: static;
    width: auto;
    margin-right: 0;
  }
}

.login-header-search .search-input {
  position: relative;
  padding: 0;
  background: none;
  display: block;
  z-index: 11;
  border-radius: 0;
  border: 0;
}

@media only screen and (max-width: 767px) {
  .login-header-search .search-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #28313d;
    align-items: center;
    z-index: 113;
    display: none;
  }
}

.login-header-search .search-input input {
  width: 100%;
  height: 60px;
  border: 0;
  border-radius: 50px;
  padding: 0 30px;
  padding-right: 53px;
  outline: none;
  transition: all 0.3s ease 0s;
  background-color: #28313d;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.login-header-search .search-input.show {
  display: flex;
  animation: sticky 0.3s;
}

.login-header-search button {
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 53px;
  text-align: center;
  border-radius: 10px;
  background-color: transparent;
  border: 0;
  top: 5px;
  right: 5px;
  font-size: 16px;
  color: #309255;
  z-index: 112;
}

@media only screen and (max-width: 767px) {
  .login-header-search button {
    position: relative;
    top: 0;
    right: 0;
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .login-header-search button {
    position: relative;
    top: 0;
    right: 0;
    font-size: 20px;
  }
}

.login-header-search.search-2 {
  margin-right: 0;
  margin-left: 0;
}

@media only screen and (max-width: 767px) {
  .login-header-search.search-2 {
    margin-left: auto;
  }
}

@-webkit-keyframes search {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes search {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.login-header-action {
  display: flex;
  align-items: center;
  padding-right: 25px;
}

@media only screen and (max-width: 767px) {
  .login-header-action {
    padding-right: 5px;
  }
}

.login-header-action > * {
  margin: 0px 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .login-header-action > * {
    margin: 0px 7px;
  }
}

.login-header-action .notification {
  font-size: 36px;
  color: #309255;
  position: relative;
  padding-top: 3px;
  border: 0;
  background: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-header-action .notification {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .login-header-action .notification {
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .login-header-action .notification {
    font-size: 22px;
  }
}

.login-header-action .notification .active {
  width: 12px;
  height: 12px;
  background-color: #ff6e30;
  border: 2px solid #212832;
  position: absolute;
  top: 12px;
  right: 9px;
  border-radius: 5px;
}

@media only screen and (max-width: 575px) {
  .login-header-action .notification .active {
    right: -2px;
  }
}

.login-header-action .author img {
  width: 60px;
  border-radius: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-header-action .author img {
    width: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .login-header-action .author img {
    width: 45px;
  }
}

.login-header-action .more {
  width: 36px;
  height: 36px;
  border: 0;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-header-action .more {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .login-header-action .more {
    width: 28px;
    height: 28px;
  }
}

.login-header-action .more span {
  width: 4px;
  height: 4px;
  background-color: #309255;
  border-radius: 50%;
  display: inline-block;
  margin: 0 1.5px;
}

@media only screen and (max-width: 575px) {
  .login-header-action .more span {
    width: 3px;
    height: 3px;
    margin: 0 1px;
  }
}

.login-header-action .dropdown .dropdown-menu {
  width: 160px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 10px 30px 0 rgba(33, 40, 50, 0.1);
  background-color: #fff;
  left: auto;
  right: 0;
}

.login-header-action .dropdown .dropdown-menu.dropdown-notification {
  width: 360px;
  padding: 0;
  right: -150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-header-action .dropdown .dropdown-menu.dropdown-notification {
    right: -110px;
  }
}

@media only screen and (max-width: 767px) {
  .login-header-action .dropdown .dropdown-menu.dropdown-notification {
    right: -140px;
  }
}

@media only screen and (max-width: 575px) {
  .login-header-action .dropdown .dropdown-menu.dropdown-notification {
    width: 270px;
    right: -90px;
  }
}

.login-header-action .dropdown .dropdown-menu li {
  border-bottom: 1px solid #ebedf2;
}

.login-header-action .dropdown .dropdown-menu li:last-child {
  border-bottom: 0;
}

.login-header-action .dropdown .dropdown-menu li > a {
  display: block;
  color: #212832;
  font-size: 13px;
  font-weight: 600;
  padding: 9px 14px;
  transition: all 0.3s ease 0s;
}

.login-header-action .dropdown .dropdown-menu li > a:hover {
  color: #309255;
}

.login-header-action .dropdown .dropdown-menu .notification-items-list .notification-item {
  display: flex;
  border-bottom: 1px solid #e0dddb;
  padding: 20px 25px;
}

@media only screen and (max-width: 575px) {
  .login-header-action .dropdown .dropdown-menu .notification-items-list .notification-item {
    padding: 10px 15px;
  }
}

.login-header-action .dropdown .dropdown-menu .notification-items-list .notification-item .notify-icon {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  padding: 0 9px;
}

@media only screen and (max-width: 575px) {
  .login-header-action .dropdown .dropdown-menu .notification-items-list .notification-item .notify-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 0 8px;
    font-size: 13px;
  }
}

.login-header-action .dropdown .dropdown-menu .notification-items-list .notification-item .dropdown-body {
  flex: 1;
}

.login-header-action .dropdown .dropdown-menu .notification-items-list .notification-item .dropdown-body p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  margin-bottom: 0;
  margin-top: 5px;
  font-weight: 400;
  font-size: 14px;
}

@media only screen and (max-width: 575px) {
  .login-header-action .dropdown .dropdown-menu .notification-items-list .notification-item .dropdown-body p {
    width: 150px;
  }
}

.login-header-action .dropdown .dropdown-menu .notification-items-list .notification-item .notify-time {
  width: 100%;
  white-space: nowrap;
  color: #828690;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-size: 13px;
  padding-left: 9px;
}

@media only screen and (max-width: 575px) {
  .login-header-action .dropdown .dropdown-menu .notification-items-list .notification-item .notify-time {
    font-size: 12px;
  }
}

.login-header-action .dropdown .dropdown-menu .all-notification {
  display: block;
  padding: 15px 30px 15px;
  text-align: center;
  font-size: 14px;
  color: #212832;
  font-weight: 400;
}

.login-header-action.action-2 {
  margin-left: auto;
}

@media only screen and (max-width: 767px) {
  .login-header-action.action-2 {
    margin-left: 0;
  }
}

/*----------------------------------------*/
/*  04. Page CSS
/*----------------------------------------*/
/*--
/*  4.1 - Slider CSS
/*----------------------------------------*/
.slider-section {
  background-color: #eefbf3;
  height: 940px;
  position: relative;
  overflow: hidden;
  z-index: 2;
  padding-top: 188px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slider-section {
    height: 880px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-section {
    height: 840px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-section {
    height: auto;
    padding-top: 200px;
    display: block;
  }
}

.slider-section .slider-shape {
  position: absolute;
  left: 120px;
  top: 195px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-section .slider-shape {
    left: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-section .slider-shape {
    display: none;
  }
}

.slider-images {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 56%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-images {
    width: 55%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-images {
    position: relative;
    width: 55%;
    margin-left: auto;
  }
}

.slider-content {
  max-width: 410px;
}

.slider-content .sub-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
  color: #309255;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-content .sub-title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-content .sub-title {
    font-size: 14px;
  }
}

.slider-content .main-title {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 0;
  margin-top: 25px;
}

@media only screen and (max-width: 575px) {
  .slider-content .main-title {
    font-size: 24px;
  }
}

.slider-content .main-title span {
  color: #309255;
  position: relative;
}

.slider-content .main-title span::before {
  position: absolute;
  content: '';
  background-image: url(../images/shape/shape-4.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 214px;
  height: 21px;
  left: 50%;
  bottom: -26px;
  transform: translateX(-50%);
}

@media only screen and (max-width: 575px) {
  .slider-content .main-title span::before {
    display: none;
  }
}

.slider-content p {
  font-size: 18px;
  margin-top: 55px;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .slider-content p {
    margin-top: 35px;
  }
}

.slider-content .btn {
  margin-top: 28px;
}

.slider-courses-box {
  position: absolute;
  top: 38%;
  left: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-courses-box {
    top: 57%;
    left: 45%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-courses-box {
    top: 63%;
    left: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .slider-courses-box {
    left: 62%;
  }
}

.slider-courses-box .shape-1 {
  position: absolute;
  top: -15px;
  right: -130px;
}

@media only screen and (max-width: 767px) {
  .slider-courses-box .shape-1 {
    right: -100px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-courses-box .shape-1 {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .slider-courses-box .shape-2 {
    width: 140px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-courses-box .shape-2 {
    width: 100px;
  }
}

.slider-courses-box .box-content {
  width: 140px;
  height: 140px;
  text-align: center;
  background-color: #309255;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .slider-courses-box .box-content {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-courses-box .box-content {
    width: 80px;
    height: 80px;
  }
}

.slider-courses-box .box-content i {
  font-size: 30px;
  color: #fff;
  display: block;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .slider-courses-box .box-content i {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-courses-box .box-content i {
    font-size: 16px;
  }
}

.slider-courses-box .box-content .count {
  font-weight: 700;
  font-size: 28px;
  color: #fff;
  display: block;
  line-height: 1.1;
}

@media only screen and (max-width: 767px) {
  .slider-courses-box .box-content .count {
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-courses-box .box-content .count {
    font-size: 16px;
  }
}

.slider-courses-box .box-content p {
  font-weight: 400;
  font-size: 17px;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .slider-courses-box .box-content p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-courses-box .box-content p {
    font-size: 12px;
  }
}

.slider-rating-box {
  position: absolute;
  top: 24%;
  right: 115px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-rating-box {
    right: 15px;
    top: 20%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-rating-box {
    top: 18%;
    right: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-rating-box {
    right: 15px;
  }
}

.slider-rating-box .box-rating {
  width: 140px;
  height: 140px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .slider-rating-box .box-rating {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-rating-box .box-rating {
    width: 80px;
    height: 80px;
  }
}

.slider-rating-box .box-rating .count {
  font-size: 30px;
  font-weight: 500;
  color: #309255;
}

@media only screen and (max-width: 767px) {
  .slider-rating-box .box-rating .count {
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-rating-box .box-rating .count {
    font-size: 18px;
  }
}

.slider-rating-box .box-rating .count i {
  color: #ffa200;
  font-size: 14px;
  display: inline-block;
}

@media only screen and (max-width: 575px) {
  .slider-rating-box .box-rating .count i {
    font-size: 12px;
  }
}

.slider-rating-box .box-rating p {
  color: #52565b;
  margin-bottom: 0;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .slider-rating-box .box-rating p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-rating-box .box-rating p {
    font-size: 10px;
  }
}

.slider-rating-box .shape {
  margin-top: 45px;
  padding-right: 30px;
}

@media only screen and (max-width: 767px) {
  .slider-rating-box .shape {
    width: 120px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-rating-box .shape {
    display: none;
  }
}

.slider-video {
  position: absolute;
  left: 0;
  bottom: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-video {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-video {
    width: 45%;
  }
}

.slider-video .video-play {
  position: absolute;
  top: 53%;
  left: 38%;
  transform: translateX(-50%) translateY(-50%);
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (max-width: 767px) {
  .slider-video .video-play {
    width: 100px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-video .video-play {
    width: 80px;
  }
}

.slider-video .video-play .play {
  font-size: 30px;
  color: #ffa200;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .slider-video .video-play .play {
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-video .video-play .play {
    font-size: 22px;
  }
}

/*--
/*  4.2 - Courses CSS
/*----------------------------------------*/
.courses-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .courses-top {
    display: block;
  }
}

.courses-top .section-title {
  margin-top: -37px;
}

@media only screen and (max-width: 767px) {
  .courses-top .section-title {
    margin-top: 0;
  }
}

.courses-search {
  position: relative;
  max-width: 500px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .courses-search {
    margin-top: 55px;
  }
}

@media only screen and (max-width: 575px) {
  .courses-search {
    margin-top: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .courses-search {
    max-width: 420px;
  }
}

.courses-search input {
  width: 100%;
  height: 64px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  padding: 0 30px;
  padding-right: 90px;
  outline: none;
  transition: all 0.3s ease 0s;
}

.courses-search input:focus {
  border-color: #309255;
}

.courses-search button {
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 54px;
  text-align: center;
  border-radius: 10px;
  background-color: #deede4;
  border: 0;
  top: 7px;
  right: 7px;
  font-size: 16px;
  color: #309255;
}

.courses-search.search-2 {
  max-width: 100%;
}

@media only screen and (min-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1599px) {
  .courses-search.search-2 {
    max-width: 340px;
  }
}

.courses-search.search-2 input {
  height: 60px;
}

.courses-search.search-2 button {
  line-height: 46px;
  background-color: #309255;
  color: #fff;
  width: 46px;
  height: 46px;
}

.courses-tabs-menu {
  background-color: #eefbf3;
  padding: 40px 100px;
  border-radius: 10px;
  margin-top: 50px;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .courses-tabs-menu {
    padding: 30px 60px;
  }
}

.courses-tabs-menu .nav {
  flex-wrap: nowrap;
}

.courses-tabs-menu .nav li button {
  width: 100%;
  height: 60px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  background-color: #fff;
  color: #212832;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s ease 0s;
  padding: 0 15px;
  white-space: nowrap;
}

.courses-tabs-menu .nav li button.active, .courses-tabs-menu .nav li button:hover {
  border-color: #309255;
  color: #309255;
}

.courses-active .swiper-button-next,
.courses-active .swiper-button-prev {
  opacity: 1;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 20px 0px rgba(48, 146, 85, 0.05);
  color: #212832;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 575px) {
  .courses-active .swiper-button-next,
  .courses-active .swiper-button-prev {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}

.courses-active .swiper-button-next::after,
.courses-active .swiper-button-prev::after {
  display: none;
}

.courses-active .swiper-button-next:hover,
.courses-active .swiper-button-prev:hover {
  background-color: #309255;
  color: #fff;
}

.courses-active .swiper-button-prev {
  left: 30px;
}

@media only screen and (max-width: 575px) {
  .courses-active .swiper-button-prev {
    left: 15px;
  }
}

.courses-active .swiper-button-next {
  right: 30px;
}

@media only screen and (max-width: 575px) {
  .courses-active .swiper-button-next {
    right: 15px;
  }
}

.courses-wrapper {
  padding-top: 20px;
}

.single-courses {
  margin-top: 30px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 15px;
  padding: 20px;
  transition: all 0.3s ease 0s;
}

.single-courses .courses-images {
  position: relative;
}

.single-courses .courses-images a {
  display: block;
}

.single-courses .courses-images a img {
  width: 100%;
  border-radius: 15px;
}

.single-courses .courses-images .courses-option {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

.single-courses .courses-images .courses-option .option-toggle {
  width: 35px;
  height: 35px;
  border: 0;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-courses .courses-images .courses-option .option-toggle span {
  width: 4px;
  height: 4px;
  background-color: #309255;
  border-radius: 50%;
  display: block;
  margin: 0 1.5px;
}

.single-courses .courses-images .courses-option .dropdown-menu {
  padding: 10px 20px;
  border: 0;
  background-color: rgba(48, 146, 85, 0.9);
  border-radius: 10px;
  min-width: 180px;
  top: -20px !important;
  left: auto !important;
  right: 15px !important;
  z-index: -1;
}

.single-courses .courses-images .courses-option .dropdown-menu li {
  padding: 5px 0;
}

.single-courses .courses-images .courses-option .dropdown-menu li a {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
}

.single-courses .courses-images .courses-option .dropdown-menu li a i {
  margin-right: 5px;
}

.single-courses .courses-content {
  padding-top: 25px;
}

.single-courses .courses-content .courses-author {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single-courses .courses-content .courses-author .author {
  display: flex;
  align-items: center;
}

.single-courses .courses-content .courses-author .author .author-thumb a {
  display: block;
}

.single-courses .courses-content .courses-author .author .author-thumb a img {
  width: 50px;
  border-radius: 50%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-courses .courses-content .courses-author .author .author-thumb a img {
    width: 45px;
  }
}

.single-courses .courses-content .courses-author .author .author-name {
  flex: 1;
  padding-left: 12px;
}

.single-courses .courses-content .courses-author .author .author-name .name {
  color: #52565b;
  font-family: "Gordita";
  font-size: 14px;
  font-weight: 400;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-courses .courses-content .courses-author .author .author-name .name {
    font-size: 13px;
  }
}

.single-courses .courses-content .courses-author .author .author-name .name:hover {
  color: #309255;
}

.single-courses .courses-content .courses-author .author .author-name .name-2 {
  position: relative;
  color: #309255;
  font-family: "Gordita";
  font-size: 14px;
  font-weight: 400;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-courses .courses-content .courses-author .author .author-name .name-2 {
    font-size: 13px;
  }
}

.single-courses .courses-content .courses-author .author .author-name .name-2::before {
  content: '||';
  color: #52565b;
  font-family: "Gordita";
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
  margin-right: 7px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-courses .courses-content .courses-author .author .author-name .name-2::before {
    font-size: 13px;
  }
}

.single-courses .courses-content .courses-author .tag a {
  width: 100px;
  height: 35px;
  line-height: 35px;
  background-color: #e7f8ee;
  border-radius: 5px;
  font-size: 14px;
  color: #309255;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  padding: 0 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-courses .courses-content .courses-author .tag a {
    width: 80px;
    font-size: 13px;
  }
}

.single-courses .courses-content .courses-author .tag a:hover {
  background-color: #309255;
  color: #fff;
}

.single-courses .courses-content .title a {
  font-size: 16px;
  font-weight: 500;
  color: #212832;
  margin-top: 13px;
  display: inline-block;
  line-height: 1.4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-courses .courses-content .title a {
    font-size: 14px;
  }
}

.single-courses .courses-content .title a:hover {
  color: #309255;
}

.single-courses .courses-content .courses-meta {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.single-courses .courses-content .courses-meta span {
  font-size: 14px;
  font-weight: 400;
  color: #52565b;
  margin-right: 20px;
  display: inline-block;
  line-height: 1;
}

.single-courses .courses-content .courses-meta span:last-child {
  margin-right: 0;
}

.single-courses .courses-content .courses-meta span i {
  color: #309255;
  margin-right: 5px;
  font-size: 20px;
  display: inline-block;
  line-height: 1;
}

.single-courses .courses-content .courses-price-review {
  background-color: #eefbf3;
  padding: 15px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-courses .courses-content .courses-price-review {
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 575px) {
  .single-courses .courses-content .courses-price-review {
    flex-wrap: wrap;
  }
}

.single-courses .courses-content .courses-price-review .courses-price {
  white-space: nowrap;
}

.single-courses .courses-content .courses-price-review .courses-price .sale-parice {
  font-size: 18px;
  font-weight: 700;
  color: #309255;
  margin-right: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .single-courses .courses-content .courses-price-review .courses-price .sale-parice {
    font-size: 16px;
  }
}

.single-courses .courses-content .courses-price-review .courses-price .old-parice {
  font-size: 15px;
  font-weight: 500;
  color: #212832;
  text-decoration: line-through;
  margin-right: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .single-courses .courses-content .courses-price-review .courses-price .old-parice {
    font-size: 13px;
  }
}

.single-courses .courses-content .courses-price-review .courses-review {
  white-space: nowrap;
}

.single-courses .courses-content .courses-price-review .courses-review .rating-count {
  font-size: 15px;
  font-weight: 500;
  color: #212832;
  margin-right: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .single-courses .courses-content .courses-price-review .courses-review .rating-count {
    font-size: 13px;
  }
}

.single-courses .courses-content .courses-price-review .courses-review .rating-star {
  position: relative;
}

.single-courses .courses-content .courses-price-review .courses-review .rating-star::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #d0d0d0;
  font-size: 14px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-courses .courses-content .courses-price-review .courses-review .rating-star::before {
    font-size: 13px;
    letter-spacing: 1px;
  }
}

.single-courses .courses-content .courses-price-review .courses-review .rating-star .rating-bar {
  position: absolute;
  top: -2px;
  left: 0;
  height: 100%;
  overflow: hidden;
}

.single-courses .courses-content .courses-price-review .courses-review .rating-star .rating-bar::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #ffba00;
  font-size: 14px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-courses .courses-content .courses-price-review .courses-review .rating-star .rating-bar::before {
    font-size: 13px;
    letter-spacing: 1px;
  }
}

.single-courses .courses-content .courses-rating p {
  font-size: 14px;
  font-weight: 400;
  color: #52565b;
  margin-bottom: 0;
}

.single-courses .courses-content .courses-rating p a {
  color: #309255;
}

.single-courses .courses-content .courses-rating .rating-progress-bar {
  width: 100%;
  height: 3px;
  background-color: #d3ded7;
  margin-top: 10px;
}

.single-courses .courses-content .courses-rating .rating-progress-bar .rating-line {
  height: 100%;
  background-color: #309255;
}

.single-courses .courses-content .courses-rating .rating-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
}

.single-courses .courses-content .courses-rating .rating-meta .rating-star {
  position: relative;
}

.single-courses .courses-content .courses-rating .rating-meta .rating-star::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #d0d0d0;
  font-size: 14px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-courses .courses-content .courses-rating .rating-meta .rating-star::before {
    font-size: 13px;
    letter-spacing: 1px;
  }
}

.single-courses .courses-content .courses-rating .rating-meta .rating-star .rating-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
}

.single-courses .courses-content .courses-rating .rating-meta .rating-star .rating-bar::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #ffba00;
  font-size: 14px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-courses .courses-content .courses-rating .rating-meta .rating-star .rating-bar::before {
    font-size: 13px;
    letter-spacing: 1px;
  }
}

.single-courses:hover {
  border-color: #309255;
}

.courses-btn {
  margin-top: 40px;
}

.courses-btn .btn {
  border: 1px solid #309255;
  line-height: 58px;
}

.courses-category-wrapper {
  background-color: #e7f8ee;
  padding: 10px 40px 40px;
  border-radius: 10px;
}

@media only screen and (min-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1599px) {
  .courses-category-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

@media only screen and (max-width: 575px) {
  .courses-category-wrapper {
    padding: 1px 30px 30px;
  }
}

.courses-category-wrapper .category-menu {
  display: flex;
  margin-left: -7px;
  margin-right: -7px;
  margin-top: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .courses-category-wrapper .category-menu {
    flex-wrap: wrap;
  }
}

.courses-category-wrapper .category-menu li {
  padding: 15px 7px 0;
}

.courses-category-wrapper .category-menu li a {
  width: 160px;
  height: 60px;
  line-height: 58px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  transition: all 0.3s ease 0s;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  color: #212832;
  padding: 0 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .courses-category-wrapper .category-menu li a {
    width: auto;
  }
}

@media only screen and (max-width: 575px) {
  .courses-category-wrapper .category-menu li a {
    font-size: 14px;
    height: 50px;
    line-height: 48px;
    padding: 0 12px;
  }
}

.courses-category-wrapper .category-menu li a.active, .courses-category-wrapper .category-menu li a:hover {
  background-color: #309255;
  border-color: #309255;
  color: #fff;
}

.courses-category-wrapper .courses-search {
  margin-top: 30px;
}

.courses-wrapper-02 {
  padding-top: 30px;
}

/*--
/*  4.3 - Call to Action CSS
/*----------------------------------------*/
.call-to-action-wrapper {
  background-color: #e7f8ee;
  border-radius: 10px;
  padding: 20px 100px 50px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .call-to-action-wrapper {
    padding: 20px 60px 50px;
  }
}

@media only screen and (max-width: 575px) {
  .call-to-action-wrapper {
    padding: 0px 30px 30px;
  }
}

.call-to-action-wrapper .cat-shape-01 {
  position: absolute;
  bottom: 30px;
  left: 30px;
}

@media only screen and (max-width: 575px) {
  .call-to-action-wrapper .cat-shape-01 {
    display: none;
  }
}

.call-to-action-wrapper .cat-shape-02 {
  position: absolute;
  top: 50%;
  left: 57.5%;
  transform: translate(-50%, -50%);
  width: 179px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .call-to-action-wrapper .cat-shape-02 {
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .call-to-action-wrapper .cat-shape-02 {
    display: none;
  }
}

.call-to-action-wrapper .cat-shape-03 {
  position: absolute;
  top: 30px;
  right: 30px;
}

@media only screen and (max-width: 575px) {
  .call-to-action-wrapper .cat-shape-03 {
    display: none;
  }
}

.call-to-action-wrapper .section-title {
  max-width: 415px;
  margin-top: 25px;
}

.call-to-action-btn {
  text-align: right;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .call-to-action-btn {
    text-align: left;
  }
}

/*--
/*  4.4 - How It Work CSS
/*----------------------------------------*/
.how-it-work-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

@media only screen and (max-width: 767px) {
  .how-it-work-wrapper {
    display: block;
  }
}

.how-it-work-wrapper .work-arrow {
  margin-top: 30px;
}

.single-work {
  max-width: 300px;
  border-radius: 10px;
  background-color: #e7f8ee;
  padding: 38px;
  padding-bottom: 35px;
  margin-top: 30px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-work {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-work {
    margin-left: auto;
    margin-right: auto;
  }
}

.single-work .shape-1 {
  position: absolute;
  left: -17px;
  top: 0;
  z-index: -1;
}

.single-work .shape-2 {
  position: absolute;
  top: -7px;
  right: -34px;
  z-index: -1;
}

.single-work .shape-3 {
  position: absolute;
  bottom: -25px;
  right: -10px;
  z-index: -1;
}

.single-work .work-icon i {
  width: 65px;
  height: 65px;
  line-height: 68px;
  text-align: center;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  background-color: #fff;
  color: #309255;
  display: inline-block;
  font-size: 32px;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-work .work-icon i {
    width: 45px;
    height: 45px;
    line-height: 48px;
    font-size: 22px;
  }
}

.single-work .work-content {
  padding-top: 33px;
}

.single-work .work-content .title {
  font-size: 25px;
  font-weight: 500;
  color: #212832;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-work .work-content .title {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-work .work-content .title {
    font-size: 20px;
  }
}

.single-work .work-content p {
  font-size: 14px;
  color: #696969;
  margin-bottom: 0;
  margin-top: 15px;
}

.single-work:hover .work-icon i {
  border-color: #309255;
  background-color: #309255;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .work-arrow {
    display: none;
  }
}

/*--
/*  4.5 - Download App CSS
/*----------------------------------------*/
.download-section {
  background-color: #309255;
  position: relative;
  overflow: hidden;
}

.download-section .app-shape-1 {
  width: 560px;
  height: 560px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  left: 70px;
  bottom: 85px;
  position: absolute;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .download-section .app-shape-1 {
    width: 360px;
    height: 360px;
  }
}

@media only screen and (max-width: 767px) {
  .download-section .app-shape-1 {
    width: 260px;
    height: 260px;
    bottom: 50px;
    left: 30px;
  }
}

.download-section .app-shape-2 {
  width: 560px;
  height: 560px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  left: 31%;
  top: 60px;
  position: absolute;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .download-section .app-shape-2 {
    width: 360px;
    height: 360px;
  }
}

@media only screen and (max-width: 767px) {
  .download-section .app-shape-2 {
    width: 260px;
    height: 260px;
    top: auto;
    bottom: -40px;
  }
}

.download-section .app-shape-3 {
  width: 560px;
  height: 560px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  right: 15%;
  bottom: 70%;
  position: absolute;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .download-section .app-shape-3 {
    width: 360px;
    height: 360px;
  }
}

@media only screen and (max-width: 767px) {
  .download-section .app-shape-3 {
    width: 260px;
    height: 260px;
  }
}

.download-section .app-shape-4 {
  width: 560px;
  height: 560px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  right: -243px;
  top: -50px;
  position: absolute;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .download-section .app-shape-4 {
    width: 360px;
    height: 360px;
  }
}

@media only screen and (max-width: 767px) {
  .download-section .app-shape-4 {
    width: 260px;
    height: 260px;
    right: -100px;
    top: 30px;
  }
}

.download-app-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .download-app-wrapper {
    display: block;
  }
}

.download-app-wrapper .section-title {
  max-width: 520px;
  margin-top: 25px;
  padding-right: 15px;
}

@media only screen and (max-width: 767px) {
  .download-app-wrapper .section-title {
    padding-right: 0;
  }
}

.download-app-wrapper .shape-1 {
  position: absolute;
  top: 40%;
  left: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .download-app-wrapper .shape-1 {
    display: none;
  }
}

.download-app-btn {
  padding-top: 20px;
  padding-left: 15px;
}

@media only screen and (max-width: 767px) {
  .download-app-btn {
    padding-left: 0;
  }
}

.download-app-btn .app-btn {
  display: flex;
}

@media only screen and (max-width: 575px) {
  .download-app-btn .app-btn {
    flex-wrap: wrap;
  }
}

.download-app-btn .app-btn li {
  margin-right: 30px;
  padding-top: 20px;
}

.download-app-btn .app-btn li:last-child {
  margin-right: 0;
}

.download-app-btn .app-btn li a {
  width: 180px;
  height: 63px;
  display: inline-block;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.15);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .download-app-btn .app-btn li a {
    width: 150px;
    height: 53px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .download-app-btn .app-btn li a img {
    width: 120px;
  }
}

/*--
/*  4.6 - Testimonial CSS
/*----------------------------------------*/
.testimonial-wrapper {
  padding-top: 50px;
}

.single-testimonial {
  text-align: center;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  padding: 45px 45px 40px;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 575px) {
  .single-testimonial {
    padding: 30px 30px;
  }
}

.single-testimonial::before {
  position: absolute;
  content: '';
  background-image: url(../images/shape/shape-18.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 101px;
  height: 172px;
  right: 100px;
  top: -27px;
}

@media only screen and (max-width: 575px) {
  .single-testimonial::before {
    right: 50px;
  }
}

.single-testimonial .testimonial-author .author-thumb {
  position: relative;
}

.single-testimonial .testimonial-author .author-thumb img {
  display: block;
  width: 90px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 50%;
  padding: 8px;
  margin: 0 auto;
}

.single-testimonial .testimonial-author .author-thumb i {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #309255;
  color: #fff;
  font-size: 13px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  bottom: -13px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.single-testimonial .testimonial-author .rating-star {
  position: relative;
  display: inline-block;
  margin-top: 35px;
}

.single-testimonial .testimonial-author .rating-star::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #d0d0d0;
  font-size: 14px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial .testimonial-author .rating-star::before {
    font-size: 13px;
    letter-spacing: 1px;
  }
}

.single-testimonial .testimonial-author .rating-star .rating-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  display: inline-block;
}

.single-testimonial .testimonial-author .rating-star .rating-bar::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #ffba00;
  font-size: 14px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-testimonial .testimonial-author .rating-star .rating-bar::before {
    font-size: 13px;
    letter-spacing: 1px;
  }
}

.single-testimonial .testimonial-content {
  padding-top: 15px;
}

.single-testimonial .testimonial-content p {
  font-size: 15px;
  color: #52565b;
  font-weight: 400;
  margin-bottom: 0;
}

.single-testimonial .testimonial-content .name {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 16px;
}

.single-testimonial .testimonial-content .designation {
  font-size: 14px;
  font-weight: 400;
  color: #309255;
  margin-top: 5px;
  display: block;
}

.testimonial-active .swiper-pagination {
  position: relative;
  bottom: 0;
  margin-top: 43px;
  height: 18px;
}

.testimonial-active .swiper-pagination .swiper-pagination-bullet {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #d1e5d9;
  opacity: 1;
  margin: 0 7px;
  transition: all 0.3s ease 0s;
}

.testimonial-active .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #309255;
}

/*--
/*  4.7 - Brand Logo CSS
/*----------------------------------------*/
.brand-logo-wrapper {
  background-color: #e7f8ee;
  padding: 60px 100px;
  border-radius: 10px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .brand-logo-wrapper {
    padding: 50px 50px;
  }
}

@media only screen and (max-width: 575px) {
  .brand-logo-wrapper {
    padding: 30px 30px;
  }
}

.brand-logo-wrapper .shape-1 {
  position: absolute;
  top: 60px;
  right: 31%;
}

@media only screen and (max-width: 767px) {
  .brand-logo-wrapper .shape-1 {
    display: none;
  }
}

.brand-logo-wrapper .shape-2 {
  position: absolute;
  top: 50px;
  right: 45px;
}

@media only screen and (max-width: 767px) {
  .brand-logo-wrapper .shape-2 {
    display: none;
  }
}

.brand-logo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}

.single-brand {
  margin-top: 30px;
  text-align: center;
}

/*--
/*  4.8 - Blog CSS
/*----------------------------------------*/
.blog-wrapper {
  padding-top: 20px;
}

.single-blog {
  margin-top: 30px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 15px;
  padding: 20px;
  transition: all 0.3s ease 0s;
}

.single-blog .blog-image a {
  display: block;
}

.single-blog .blog-image a img {
  width: 100%;
  border-radius: 15px;
}

.single-blog .blog-content {
  padding-top: 25px;
}

.single-blog .blog-content .blog-author {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single-blog .blog-content .blog-author .author {
  display: flex;
  align-items: center;
}

.single-blog .blog-content .blog-author .author .author-thumb a {
  display: block;
}

.single-blog .blog-content .blog-author .author .author-thumb a img {
  width: 50px;
  border-radius: 50%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-blog .blog-content .blog-author .author .author-thumb a img {
    width: 45px;
  }
}

.single-blog .blog-content .blog-author .author .author-name {
  flex: 1;
  padding-left: 12px;
}

.single-blog .blog-content .blog-author .author .author-name .name {
  color: #52565b;
  font-size: 14px;
  font-weight: 400;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-blog .blog-content .blog-author .author .author-name .name {
    font-size: 13px;
  }
}

.single-blog .blog-content .blog-author .author .author-name .name:hover {
  color: #309255;
}

.single-blog .blog-content .blog-author .tag a {
  width: 100px;
  height: 35px;
  line-height: 35px;
  background-color: #e7f8ee;
  border-radius: 5px;
  font-size: 14px;
  color: #309255;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  padding: 0 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-blog .blog-content .blog-author .tag a {
    width: 80px;
    font-size: 13px;
  }
}

.single-blog .blog-content .blog-author .tag a:hover {
  background-color: #309255;
  color: #fff;
}

.single-blog .blog-content .title a {
  font-size: 16px;
  font-weight: 500;
  color: #212832;
  margin-top: 13px;
  display: inline-block;
  line-height: 1.4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-blog .blog-content .title a {
    font-size: 14px;
  }
}

.single-blog .blog-content .title a:hover {
  color: #309255;
}

.single-blog .blog-content .blog-meta {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.single-blog .blog-content .blog-meta span {
  font-size: 14px;
  font-weight: 400;
  color: #52565b;
  margin-right: 20px;
  display: inline-block;
  line-height: 1;
}

.single-blog .blog-content .blog-meta span:last-child {
  margin-right: 0;
}

.single-blog .blog-content .blog-meta span i {
  color: #309255;
  margin-right: 5px;
  font-size: 20px;
  display: inline-block;
  line-height: 1;
}

.single-blog .blog-content .btn {
  padding: 0 20px;
  height: 45px;
  line-height: 39px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  font-size: 15px;
  margin-top: 30px;
}

.single-blog:hover {
  border-color: #309255;
}

/*--
/*  4.9 - Page Banner CSS
/*----------------------------------------*/
.page-banner {
  height: 550px;
  background-color: #e7f8ee;
  display: flex;
  align-items: center;
  padding-top: 189px;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .page-banner {
    height: 400px;
    padding-top: 103px;
  }
}

.page-banner .shape-1 {
  position: absolute;
  top: 32%;
  left: 100px;
}

@media only screen and (max-width: 767px) {
  .page-banner .shape-1 {
    width: 60px;
    left: 30px;
  }
}

.page-banner .shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .page-banner .shape-2 {
    width: 220px;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner .shape-2 {
    width: 180px;
  }
}

.page-banner .shape-3 {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .page-banner .shape-3 {
    width: 305px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-banner .shape-3 {
    width: 225px;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner .shape-3 {
    width: 195px;
  }
}

.page-banner .shape-author {
  position: absolute;
  top: 45%;
  right: 9.5%;
  border-radius: 50%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .page-banner .shape-author {
    width: 150px;
    top: 63%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-banner .shape-author {
    width: 150px;
    top: 52%;
  }
}

@media only screen and (max-width: 767px) {
  .page-banner .shape-author {
    width: 100px;
    top: auto;
    bottom: 11%;
    right: 11%;
  }
}

@media only screen and (max-width: 575px) {
  .page-banner .shape-author {
    bottom: 6%;
    width: 80px;
  }
}

.page-banner-content {
  max-width: 570px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .page-banner-content {
    max-width: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .page-banner-content {
    max-width: 360px;
  }
}

.page-banner-content .breadcrumb {
  margin-bottom: 0;
}

.page-banner-content .breadcrumb li {
  font-size: 17px;
  font-weight: 500;
  color: #212832;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .page-banner-content .breadcrumb li {
    font-size: 15px;
  }
}

.page-banner-content .breadcrumb li + li::before {
  content: '//';
  font-size: 17px;
  font-weight: 500;
  color: #212832;
  margin: 0 8px;
}

@media only screen and (max-width: 767px) {
  .page-banner-content .breadcrumb li + li::before {
    font-size: 15px;
  }
}

.page-banner-content .breadcrumb li.active {
  color: #309255;
}

.page-banner-content .title {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 15px;
  padding-bottom: 15px;
  line-height: 1.6;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .page-banner-content .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .page-banner-content .title {
    font-size: 24px;
  }
}

.page-banner-content .title span {
  position: relative;
  color: #309255;
}

.page-banner-content .title span::before {
  position: absolute;
  content: '';
  background-image: url(../images/shape/shape-4.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 120px;
  height: 13px;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .page-banner-content .title span::before {
    display: none;
  }
}

.shape-icon-box {
  position: absolute;
  top: 47%;
  left: 60%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shape-icon-box {
    left: 55%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shape-icon-box {
    top: 35%;
    left: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .shape-icon-box {
    top: 30%;
    left: 57%;
  }
}

@media only screen and (max-width: 575px) {
  .shape-icon-box {
    left: 62%;
  }
}

.shape-icon-box .icon-shape-1 {
  position: absolute;
  top: -15px;
  right: -130px;
}

@media only screen and (max-width: 767px) {
  .shape-icon-box .icon-shape-1 {
    right: -100px;
  }
}

@media only screen and (max-width: 575px) {
  .shape-icon-box .icon-shape-1 {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .shape-icon-box .icon-shape-2 {
    width: 100px;
  }
}

@media only screen and (max-width: 575px) {
  .shape-icon-box .icon-shape-2 {
    width: 100px;
  }
}

.shape-icon-box .box-content {
  width: 140px;
  height: 140px;
  text-align: center;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 50%;
  margin: 0 auto;
  padding: 10px;
}

@media only screen and (max-width: 767px) {
  .shape-icon-box .box-content {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 575px) {
  .shape-icon-box .box-content {
    width: 80px;
    height: 80px;
  }
}

.shape-icon-box .box-content .box-wrapper {
  background-color: #309255;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.shape-icon-box .box-content .box-wrapper i {
  font-size: 70px;
  color: #fff;
  display: block;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .shape-icon-box .box-content .box-wrapper i {
    font-size: 40px;
  }
}

/*--
/*  4.11 - About CSS
/*----------------------------------------*/
.about-images {
  position: relative;
  overflow: hidden;
  margin-top: 50px;
}

.about-images .images img {
  border-radius: 10px;
  width: 100%;
}

.about-images .about-years {
  width: 125px;
  background-color: #309255;
  padding: 10px 20px;
  text-align: center;
  border-radius: 10px;
  position: absolute;
  top: 50px;
  right: 30px;
}

.about-images .about-years .years-icon {
  background-color: #fff;
  text-align: center;
  width: 85px;
  height: 85px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
  position: relative;
}

.about-images .about-years p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-top: 6px;
}

.about-images .about-years p strong {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
}

.about-content {
  margin-top: 45px;
  max-width: 530px;
  margin-left: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .about-content {
    margin-left: 0;
  }
}

.about-content .sub-title {
  font-size: 20px;
  font-weight: 500;
  color: #309255;
  margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
  .about-content .sub-title {
    font-size: 16px;
  }
}

.about-content .main-title {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1.4;
  padding-bottom: 24px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content .main-title {
    font-size: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .about-content .main-title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .about-content .main-title {
    font-size: 24px;
    padding-bottom: 0;
  }
}

.about-content .main-title span {
  color: #309255;
  position: relative;
}

.about-content .main-title span::before {
  position: absolute;
  content: '';
  background-image: url(../images/shape/shape-4.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 210px;
  height: 21px;
  left: 50%;
  bottom: -24px;
  transform: translateX(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-content .main-title span::before {
    width: 140px;
    height: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .about-content .main-title span::before {
    display: none;
  }
}

.about-content p {
  margin-top: 25px;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content p {
    margin-top: 15px;
  }
}

.about-content .btn {
  margin-top: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content .btn {
    margin-top: 25px;
  }
}

.about-item {
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  padding: 30px;
  margin-top: 30px;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-item {
    padding: 20px;
  }
}

.about-item .item-icon-title {
  display: flex;
  align-items: center;
}

.about-item .item-icon-title .item-icon i {
  width: 80px;
  height: 80px;
  line-height: 86px;
  text-align: center;
  border-radius: 50%;
  background-color: #e7f8ee;
  color: #309255;
  font-size: 36px;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .about-item .item-icon-title .item-icon i {
    width: 60px;
    height: 60px;
    line-height: 66px;
    font-size: 28px;
  }
}

.about-item .item-icon-title .item-title {
  flex: 1;
  padding-left: 20px;
}

.about-item .item-icon-title .item-title .title {
  font-size: 22px;
  font-weight: 500;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .about-item .item-icon-title .item-title .title {
    font-size: 18px;
  }
}

.about-item p {
  margin-top: 25px;
  margin-bottom: 0;
}

.about-item:hover {
  border-color: #309255;
}

.about-item:hover .item-icon-title .item-icon i {
  background-color: #309255;
  color: #fff;
}

/*--
/*  4.12 - Team CSS
/*----------------------------------------*/
.team-wrapper {
  margin-bottom: -7px;
}

.single-team {
  text-align: center;
  margin-top: 50px;
}

.single-team .team-thumb img {
  border-radius: 50%;
  padding: 10px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  transition: all 0.3s ease 0s;
}

.single-team .team-content {
  padding-top: 15px;
}

.single-team .team-content .rating {
  display: inline-flex;
  align-items: center;
}

.single-team .team-content .rating .count {
  font-size: 14px;
  font-weight: 500;
  color: #212832;
}

.single-team .team-content .rating i {
  color: #ffba00;
  margin: 0 5px;
}

.single-team .team-content .rating .text {
  font-size: 13px;
  font-family: serif;
  font-weight: 400;
  color: #848886;
}

.single-team .team-content .name {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-team .team-content .name {
    font-size: 16px;
  }
}

.single-team .team-content .designation {
  display: block;
  font-size: 14px;
  color: #309255;
  margin-top: 10px;
}

.single-team:hover .team-thumb img {
  border-color: #309255;
}

/*--
/*  4.13 - Courses Details CSS
/*----------------------------------------*/
.courses-details {
  margin-top: 50px;
}

.courses-details .courses-details-images {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
}

.courses-details .courses-details-images img {
  border-radius: 10px;
  width: 100%;
}

.courses-details .courses-details-images .tags {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: #ffba00;
  font-size: 14px;
  font-family: "Gordita";
  font-weight: 400;
  padding: 0 25px;
  border-radius: 5px;
  height: 35px;
  line-height: 35px;
  display: inline-block;
  color: #fff;
}

.courses-details .courses-details-images .courses-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 575px) {
  .courses-details .courses-details-images .courses-play {
    width: 70px;
  }
}

.courses-details .courses-details-images .courses-play .play {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .courses-details .courses-details-images .courses-play .play {
    font-size: 16px;
  }
}

.courses-details .title {
  font-size: 25px;
  font-weight: 500;
  color: #212832;
  line-height: 1.5;
  margin-top: 30px;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .courses-details .title {
    font-size: 18px;
  }
}

.courses-details .courses-details-admin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

@media only screen and (max-width: 767px) {
  .courses-details .courses-details-admin {
    display: block;
  }
}

.courses-details .courses-details-admin .admin-author {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

@media only screen and (max-width: 575px) {
  .courses-details .courses-details-admin .admin-author {
    flex-wrap: wrap;
  }
}

.courses-details .courses-details-admin .admin-author .author-thumb img {
  width: 50px;
  border-radius: 50%;
}

.courses-details .courses-details-admin .admin-author .author-content {
  flex: 1;
  padding-left: 15px;
  padding-bottom: 5px;
}

@media only screen and (max-width: 575px) {
  .courses-details .courses-details-admin .admin-author .author-content {
    flex-wrap: wrap;
  }
}

.courses-details .courses-details-admin .admin-author .author-content .name {
  font-size: 17px;
  font-weight: 500;
  color: #212832;
  display: inline-block;
  line-height: 22px;
  position: relative;
}

.courses-details .courses-details-admin .admin-author .author-content .name::after {
  content: '||';
  font-size: 24px;
  font-weight: 300;
  color: #c5c5c5;
  position: relative;
  margin: 0 18px;
  bottom: -2px;
}

.courses-details .courses-details-admin .admin-author .author-content .Enroll {
  font-size: 14px;
  font-weight: 400;
  color: #309255;
  display: inline-block;
  line-height: 22px;
}

.courses-details .courses-details-admin .admin-rating {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

@media only screen and (max-width: 767px) {
  .courses-details .courses-details-admin .admin-rating {
    margin-left: 65px;
  }
}

.courses-details .courses-details-admin .admin-rating .rating-count {
  font-size: 15px;
  font-weight: 500;
  color: #212832;
  margin-right: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .courses-details .courses-details-admin .admin-rating .rating-count {
    font-size: 13px;
  }
}

.courses-details .courses-details-admin .admin-rating .rating-star {
  position: relative;
  margin: 0 8px;
}

.courses-details .courses-details-admin .admin-rating .rating-star::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #d0d0d0;
  font-size: 14px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-details .courses-details-admin .admin-rating .rating-star::before {
    font-size: 13px;
    letter-spacing: 1px;
  }
}

.courses-details .courses-details-admin .admin-rating .rating-star .rating-bar {
  position: absolute;
  top: -2px;
  left: 0;
  height: 100%;
  overflow: hidden;
}

.courses-details .courses-details-admin .admin-rating .rating-star .rating-bar::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #ffba00;
  font-size: 14px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-details .courses-details-admin .admin-rating .rating-star .rating-bar::before {
    font-size: 13px;
    letter-spacing: 1px;
  }
}

.courses-details .courses-details-admin .admin-rating .rating-text {
  font-size: 14px;
  font-weight: 400;
  color: #52565b;
  margin-bottom: 0;
}

.courses-details-tab {
  padding-top: 30px;
}

.courses-details-tab .details-tab-menu {
  background-color: #e7f8ee;
  border-radius: 10px;
  padding: 15px;
  padding-top: 5px;
}

.courses-details-tab .details-tab-menu .nav li {
  padding: 10px 10px 0;
}

.courses-details-tab .details-tab-menu .nav li button {
  width: 130px;
  height: 45px;
  line-height: 41px;
  text-align: center;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  color: #212832;
  background-color: #fff;
  transition: all 0.3s ease 0s;
}

.courses-details-tab .details-tab-menu .nav li button:hover, .courses-details-tab .details-tab-menu .nav li button.active {
  background-color: #309255;
  border-color: #309255;
  color: #fff;
}

.courses-details-tab .details-tab-content .tab-title {
  font-size: 25px;
  font-weight: 500;
  color: #212832;
  margin-bottom: 0;
}

.courses-details-tab .details-tab-content p {
  margin-bottom: 0;
  margin-top: 15px;
  color: #52565b;
  font-size: 15px;
  font-weight: 400;
}

.courses-details-tab .details-tab-content .tab-description .description-wrapper {
  margin-top: 25px;
}

.courses-details-tab .details-tab-content .tab-instructors {
  margin-top: 25px;
}

.courses-details-tab .details-tab-content .tab-instructors .single-team {
  margin-top: 35px;
}

.courses-details-tab .details-tab-content .tab-rating-content {
  margin-top: 45px;
}

.courses-details-tab .details-tab-content .tab-rating-box {
  margin-top: 45px;
  text-align: center;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  padding: 40px 30px;
}

.courses-details-tab .details-tab-content .tab-rating-box .count {
  font-size: 30px;
  font-weight: 500;
  color: #309255;
  line-height: 1;
}

.courses-details-tab .details-tab-content .tab-rating-box .count i {
  font-size: 14px;
  color: #ffa200;
}

.courses-details-tab .details-tab-content .tab-rating-box p {
  margin-top: 0;
}

.courses-details-tab .details-tab-content .tab-rating-box .rating-box-wrapper {
  padding-top: 20px;
}

.courses-details-tab .details-tab-content .tab-rating-box .single-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
}

.courses-details-tab .details-tab-content .tab-rating-box .single-rating .rating-star {
  position: relative;
}

.courses-details-tab .details-tab-content .tab-rating-box .single-rating .rating-star::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #d0d0d0;
  font-size: 14px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-details-tab .details-tab-content .tab-rating-box .single-rating .rating-star::before {
    font-size: 13px;
    letter-spacing: 1px;
  }
}

.courses-details-tab .details-tab-content .tab-rating-box .single-rating .rating-star .rating-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
}

.courses-details-tab .details-tab-content .tab-rating-box .single-rating .rating-star .rating-bar::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #ffba00;
  font-size: 14px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-details-tab .details-tab-content .tab-rating-box .single-rating .rating-star .rating-bar::before {
    font-size: 13px;
    letter-spacing: 1px;
  }
}

.courses-details-tab .details-tab-content .tab-rating-box .single-rating .rating-progress-bar {
  width: calc(100% - 95px);
  height: 5px;
  background-color: #d3ded7;
  border-radius: 5px;
  overflow: hidden;
}

.courses-details-tab .details-tab-content .tab-rating-box .single-rating .rating-progress-bar .rating-line {
  height: 100%;
  background-color: #309255;
}

.courses-details-tab .details-tab-content .tab-reviews {
  margin-top: 25px;
}

.courses-details-tab .details-tab-content .tab-reviews .reviews-wrapper {
  margin-top: 30px;
}

.courses-details-tab .details-tab-content .tab-reviews .single-review {
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  padding: 30px;
  position: relative;
  overflow: hidden;
}

.courses-details-tab .details-tab-content .tab-reviews .single-review::before {
  position: absolute;
  content: '';
  background-image: url(../images/shape/shape-18.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 101px;
  height: 172px;
  right: 100px;
  top: -50px;
}

@media only screen and (max-width: 575px) {
  .courses-details-tab .details-tab-content .tab-reviews .single-review::before {
    right: 50px;
  }
}

.courses-details-tab .details-tab-content .tab-reviews .single-review .review-author {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .courses-details-tab .details-tab-content .tab-reviews .single-review .review-author {
    display: block;
  }
}

.courses-details-tab .details-tab-content .tab-reviews .single-review .review-author .author-thumb {
  position: relative;
  display: inline-block;
}

.courses-details-tab .details-tab-content .tab-reviews .single-review .review-author .author-thumb img {
  display: block;
  width: 90px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 50%;
  padding: 8px;
}

.courses-details-tab .details-tab-content .tab-reviews .single-review .review-author .author-thumb i {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background-color: #309255;
  color: #fff;
  font-size: 13px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  bottom: -13px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.courses-details-tab .details-tab-content .tab-reviews .single-review .review-author .author-content {
  flex: 1;
  padding-left: 30px;
}

@media only screen and (max-width: 575px) {
  .courses-details-tab .details-tab-content .tab-reviews .single-review .review-author .author-content {
    padding-left: 0;
    padding-top: 20px;
  }
}

.courses-details-tab .details-tab-content .tab-reviews .single-review .review-author .author-content .name {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0;
}

.courses-details-tab .details-tab-content .tab-reviews .single-review .review-author .author-content .designation {
  font-size: 14px;
  font-weight: 400;
  color: #309255;
  margin-top: 5px;
  display: block;
}

.courses-details-tab .details-tab-content .tab-reviews .single-review .review-author .author-content .rating-star {
  position: relative;
  margin-top: 5px;
  display: inline-block;
}

.courses-details-tab .details-tab-content .tab-reviews .single-review .review-author .author-content .rating-star::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #d0d0d0;
  font-size: 14px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-details-tab .details-tab-content .tab-reviews .single-review .review-author .author-content .rating-star::before {
    font-size: 13px;
    letter-spacing: 1px;
  }
}

.courses-details-tab .details-tab-content .tab-reviews .single-review .review-author .author-content .rating-star .rating-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  display: inline-block;
}

.courses-details-tab .details-tab-content .tab-reviews .single-review .review-author .author-content .rating-star .rating-bar::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #ffba00;
  font-size: 14px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-details-tab .details-tab-content .tab-reviews .single-review .review-author .author-content .rating-star .rating-bar::before {
    font-size: 13px;
    letter-spacing: 1px;
  }
}

.courses-details-tab .details-tab-content .tab-reviews .single-review p {
  margin-top: 30px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
  color: #52565b;
}

.courses-details-tab .details-tab-content .tab-reviews .reviews-btn {
  padding-top: 30px;
  text-align: center;
}

.reviews-active .swiper-pagination {
  position: relative;
  bottom: 0;
  margin-top: 22px;
}

.reviews-active .swiper-pagination .swiper-pagination-bullet {
  background-color: #d1e5d9;
  opacity: 1;
  width: 12px;
  height: 12px;
}

.reviews-active .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #309255;
}

/*--
/*  4.14 - Blog Details CSS
/*----------------------------------------*/
.blog-details-wrapper {
  margin-top: 50px;
}

.blog-details-wrapper .title {
  font-size: 25px;
  font-weight: 500;
  color: #212832;
  margin-bottom: 0;
  line-height: 1.5;
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .blog-details-wrapper .title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-details-wrapper .title {
    font-size: 18px;
  }
}

.blog-details-admin-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .blog-details-admin-meta {
    display: block;
  }
}

.blog-details-admin-meta .author {
  display: flex;
  align-items: center;
}

.blog-details-admin-meta .author .author-thumb a {
  display: block;
}

.blog-details-admin-meta .author .author-thumb a img {
  width: 50px;
  border-radius: 50%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .blog-details-admin-meta .author .author-thumb a img {
    width: 45px;
  }
}

.blog-details-admin-meta .author .author-name {
  flex: 1;
  padding-left: 12px;
}

.blog-details-admin-meta .author .author-name .name {
  color: #52565b;
  font-size: 14px;
  font-weight: 400;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .blog-details-admin-meta .author .author-name .name {
    font-size: 13px;
  }
}

.blog-details-admin-meta .author .author-name .name:hover {
  color: #309255;
}

.blog-details-admin-meta .blog-meta {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .blog-details-admin-meta .blog-meta {
    padding-left: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-details-admin-meta .blog-meta {
    flex-wrap: wrap;
  }
}

.blog-details-admin-meta .blog-meta span {
  font-size: 14px;
  font-weight: 400;
  color: #52565b;
  margin-right: 40px;
  display: inline-block;
  line-height: 1;
}

@media only screen and (max-width: 575px) {
  .blog-details-admin-meta .blog-meta span {
    margin-right: 25px;
    margin-top: 10px;
  }
}

.blog-details-admin-meta .blog-meta span:last-child {
  margin-right: 0;
}

.blog-details-admin-meta .blog-meta span i {
  color: #309255;
  margin-right: 5px;
  font-size: 20px;
  display: inline-block;
  line-height: 1;
}

.blog-details-admin-meta .blog-meta .tag a {
  width: 100px;
  height: 35px;
  line-height: 35px;
  background-color: #e7f8ee;
  border-radius: 5px;
  font-size: 14px;
  color: #309255;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  padding: 0 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .blog-details-admin-meta .blog-meta .tag a {
    width: 80px;
    font-size: 13px;
  }
}

.blog-details-admin-meta .blog-meta .tag a:hover {
  background-color: #309255;
  color: #fff;
}

.blog-details-description {
  padding-top: 6px;
  padding-bottom: 20px;
}

.blog-details-description p {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
  margin-top: 15px;
  color: #52565b;
}

.blog-details-description img {
  border-radius: 10px;
  margin-top: 35px;
}

.blog-details-description h3, .blog-details-description .h3 {
  margin-top: 30px;
  font-size: 25px;
  font-weight: 500;
  color: #212832;
  margin-bottom: 0;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .blog-details-description h3, .blog-details-description .h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-details-description h3, .blog-details-description .h3 {
    font-size: 18px;
  }
}

.blog-details-label {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

@media only screen and (max-width: 575px) {
  .blog-details-label {
    display: block;
  }
}

.blog-details-label .label {
  font-size: 25px;
  font-weight: 500;
  color: #212832;
  margin-bottom: 0;
  line-height: 1.5;
  margin-top: 10px;
  padding-right: 20px;
}

.blog-details-label .tag-list {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.blog-details-label .tag-list li {
  padding-right: 20px;
  padding-top: 10px;
}

.blog-details-label .tag-list li:last-child {
  padding-right: 0;
}

.blog-details-label .tag-list li a {
  width: 110px;
  height: 45px;
  line-height: 43px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  color: #212832;
  text-align: center;
  display: block;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .blog-details-label .tag-list li a {
    width: auto;
    padding: 0 14px;
  }
}

.blog-details-label .tag-list li a:hover {
  border-color: #309255;
  background-color: #309255;
  color: #fff;
}

.blog-details-label .social {
  display: flex;
  flex-wrap: wrap;
}

.blog-details-label .social li {
  padding-right: 20px;
  padding-top: 10px;
}

.blog-details-label .social li:last-child {
  padding-right: 0;
}

.blog-details-label .social li a {
  display: block;
  width: 50px;
  height: 50px;
  line-height: 52px;
  border: 1px solid rgba(48, 146, 85, 0.25);
  border-radius: 10px;
  text-align: center;
  font-size: 18px;
  color: #212832;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-label .social li a {
    width: 45px;
    height: 45px;
    line-height: 47px;
  }
}

.blog-details-label .social li a:hover {
  border-color: #309255;
  background-color: #309255;
  color: #fff;
}

.blog-details-comment .title {
  font-size: 25px;
  font-weight: 500;
  color: #212832;
  margin-bottom: 0;
  line-height: 1.5;
}

.comment-wrapper {
  margin-top: 50px;
}

.comment-items .comment-reply {
  padding-left: 100px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .comment-items .comment-reply {
    padding-left: 0;
  }
}

.comment-items .comment-reply::before {
  position: absolute;
  content: '';
  width: 51px;
  height: 84px;
  background-image: url(../images/shape/shape-25.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  left: 30px;
  top: -33px;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .comment-items .comment-reply::before {
    display: none;
  }
}

.single-comment {
  padding: 30px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  margin-top: 30px;
  position: relative;
  background-color: #fff;
}

.single-comment .comment-author {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .single-comment .comment-author {
    display: block;
  }
}

.single-comment .comment-author .author-thumb img {
  width: 90px;
  padding: 10px;
  border-radius: 50%;
  border: 1px solid rgba(48, 146, 85, 0.2);
}

.single-comment .comment-author .author-content {
  flex: 1;
  padding-left: 30px;
  padding-right: 115px;
}

@media only screen and (max-width: 767px) {
  .single-comment .comment-author .author-content {
    padding-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .single-comment .comment-author .author-content {
    padding-left: 0;
    padding-top: 20px;
  }
}

.single-comment .comment-author .author-content .name {
  font-size: 22px;
  font-weight: 500;
  color: #212832;
}

.single-comment .comment-author .author-content .meta {
  display: flex;
  flex-wrap: wrap;
}

.single-comment .comment-author .author-content .meta span {
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.single-comment .comment-author .author-content .meta .designation {
  color: #309255;
}

.single-comment .comment-author .author-content .meta .designation::after {
  content: '||';
  font-size: 14px;
  font-weight: 400;
  color: #309255;
  margin: 0 8px;
}

.single-comment .comment-author .author-content .meta .time {
  color: #ff8a00;
}

.single-comment p {
  padding-top: 10px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
  color: #000;
}

.single-comment .reply {
  padding: 0 25px;
  height: 45px;
  line-height: 45px;
  border-radius: 10px;
  background-color: #e7f8ee;
  color: #309255;
  display: inline-block;
  transition: all 0.3s ease 0s;
  position: absolute;
  top: 30px;
  right: 30px;
}

@media only screen and (max-width: 767px) {
  .single-comment .reply {
    position: relative;
    top: 0;
    right: 0;
    margin-top: 15px;
  }
}

.single-comment .reply i {
  margin-right: 5px;
}

.single-comment .reply:hover {
  background-color: #309255;
  color: #fff;
}

.comment-form {
  margin-top: 50px;
}

.comment-form .form-wrapper {
  padding-top: 10px;
}

.comment-form .form-wrapper .single-form textarea {
  height: 280px;
}

/*--
/*  4.15 - Registration & Login Form CSS
/*----------------------------------------*/
.register-login-wrapper {
  padding: 70px;
  padding-top: 20px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
}

@media only screen and (max-width: 575px) {
  .register-login-wrapper {
    padding: 30px;
  }
}

.register-login-images {
  background-color: #e7f8ee;
  padding: 45px 40px 0;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 50px;
}

@media only screen and (max-width: 575px) {
  .register-login-images {
    margin-top: 30px;
    padding: 25px 20px 0;
  }
}

.register-login-images .shape-1 {
  width: 270px;
  height: 270px;
  border-radius: 50%;
  background-color: #309255;
  top: 50px;
  left: 20%;
  position: absolute;
  z-index: -1;
}

@media only screen and (max-width: 575px) {
  .register-login-images .shape-1 {
    width: 120px;
    height: 120px;
  }
}

.register-login-images .shape-1 img {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  left: 20%;
}

@media only screen and (max-width: 575px) {
  .register-login-images .shape-1 img {
    width: 30px;
  }
}

.register-login-images .images {
  width: 100%;
}

.register-login-form {
  max-width: 400px;
  margin-left: auto;
  margin-top: 45px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .register-login-form {
    max-width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .register-login-form {
    margin-top: 30px;
  }
}

.register-login-form .title {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1.4;
  padding-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .register-login-form .title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .register-login-form .title {
    font-size: 20px;
  }
}

.register-login-form .title span {
  color: #309255;
  position: relative;
}

.register-login-form .title span::before {
  position: absolute;
  content: '';
  background-image: url(../images/shape/shape-4.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 121px;
  height: 13px;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
}

@media only screen and (max-width: 575px) {
  .register-login-form .title span::before {
    display: none;
  }
}

.register-login-form .form-wrapper {
  padding-top: 30px;
}

@media only screen and (max-width: 575px) {
  .register-login-form .form-wrapper {
    padding-top: 10px;
  }
}

.register-login-form .single-form .btn {
  margin-top: 20px;
  transition: all 0.3s ease 0s;
}

.register-login-form .single-form .btn::before {
  width: 600px;
  height: 600px;
}

.register-login-form .single-form .btn.btn-outline {
  border: 1px solid rgba(48, 146, 85, 0.2);
  line-height: 58px;
}

.register-login-form .single-form .btn.btn-outline:hover {
  background-color: #309255;
  border-color: #309255;
  color: #fff;
}

/*--
/*  4.16 - FAQ CSS
/*----------------------------------------*/
.faq-tab-menu {
  background-color: #e7f8ee;
  padding: 25px 85px 40px;
  border-radius: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-tab-menu {
    padding: 25px 35px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-tab-menu {
    padding: 15px 30px 30px;
  }
}

@media only screen and (max-width: 575px) {
  .faq-tab-menu {
    padding: 15px 20px 30px;
  }
}

.faq-tab-menu .nav li {
  padding: 15px 15px 0;
}

@media only screen and (max-width: 575px) {
  .faq-tab-menu .nav li {
    padding: 15px 5px 0;
  }
}

.faq-tab-menu .nav li button {
  height: 60px;
  line-height: 58px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #212832;
  background-color: #fff;
  transition: all 0.3s ease 0s;
  padding: 0;
  padding: 0 20px;
  white-space: nowrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-tab-menu .nav li button {
    width: 170px !important;
  }
}

@media only screen and (max-width: 575px) {
  .faq-tab-menu .nav li button {
    font-size: 13px;
    height: 50px;
    line-height: 48px;
    padding: 0 12px;
  }
}

.faq-tab-menu .nav li button.active, .faq-tab-menu .nav li button:hover {
  border-color: #309255;
  color: #309255;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .faq-tab-menu .nav.nav-justified .nav-item,
  .faq-tab-menu .nav.nav-justified .nav-link {
    flex-grow: 0;
  }
}

.faq-wrapper {
  padding-top: 30px;
}

.single-faq-item {
  border: 1px solid rgba(48, 146, 85, 0.2);
  padding: 10px 70px 35px;
  border-radius: 10px;
  margin-top: 20px;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .single-faq-item {
    padding: 1px 45px 30px;
  }
}

@media only screen and (max-width: 575px) {
  .single-faq-item {
    padding: 1px 30px 30px;
  }
}

.single-faq-item .faq-title {
  max-width: 330px;
  margin-top: 25px;
}

.single-faq-item .faq-title .title {
  font-size: 20px;
  font-weight: 500;
  color: #212832;
  line-height: 1.5;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 575px) {
  .single-faq-item .faq-title .title {
    font-size: 18px;
  }
}

.single-faq-item .faq-text {
  margin-top: 25px;
}

.single-faq-item:hover {
  background-color: #e7f8ee;
  border-color: #e7f8ee;
}

.single-faq-item:hover .faq-title .title {
  color: #309255;
}

.faq-btn {
  margin-top: 50px;
}

/*--
/*  4.17 - 404 Error CSS
/*----------------------------------------*/
.error-images {
  margin-top: 50px;
}

.error-content {
  max-width: 470px;
  margin-left: auto;
  margin-top: 45px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .error-content {
    max-width: 430px;
    margin-left: 0;
  }
}

.error-content .sub-title {
  font-size: 20px;
  font-weight: 500;
  font-family: "Gordita";
  color: #309255;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .error-content .sub-title {
    font-size: 16px;
  }
}

.error-content .main-title {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1.4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .error-content .main-title {
    font-size: 35px;
  }
}

@media only screen and (max-width: 575px) {
  .error-content .main-title {
    font-size: 24px;
  }
}

.error-content .main-title span {
  color: #309255;
  position: relative;
}

.error-content .main-title span::before {
  position: absolute;
  content: '';
  background-image: url(../images/shape/shape-4.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 210px;
  height: 21px;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-content .main-title span::before {
    width: 140px;
    height: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .error-content .main-title span::before {
    display: none;
  }
}

.error-content P {
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 0;
}

.error-content .btn {
  margin-top: 35px;
}

/*--
/*  4.18 - Contact CSS
/*----------------------------------------*/
.contact-map-wrapper iframe {
  display: block;
  width: 100%;
  height: 500px;
  border-radius: 10px;
}

@media only screen and (max-width: 575px) {
  .contact-map-wrapper iframe {
    height: 300px;
  }
}

.contact-wrapper {
  padding: 70px;
  padding-top: 20px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .contact-wrapper {
    padding: 40px;
    padding-top: 0;
  }
}

@media only screen and (max-width: 575px) {
  .contact-wrapper {
    padding: 30px;
    padding-top: 0;
  }
}

.contact-info {
  background-color: #e7f8ee;
  padding: 70px 60px;
  border-radius: 15px;
  margin-top: 50px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-info {
    padding: 50px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-info {
    padding: 40px 30px;
  }
}

@media only screen and (max-width: 575px) {
  .contact-info {
    margin-top: 30px;
    padding: 30px 20px;
  }
}

.contact-info .shape {
  position: absolute;
  top: 50px;
  right: 50px;
}

@media only screen and (max-width: 575px) {
  .contact-info .shape {
    top: 25px;
    right: 25px;
  }
}

.single-contact-info {
  display: flex;
  align-items: center;
  padding: 30px 0;
}

@media only screen and (max-width: 575px) {
  .single-contact-info {
    display: block;
    text-align: center;
  }
}

.single-contact-info + .single-contact-info {
  border-top: 1px solid rgba(48, 146, 85, 0.2);
}

.single-contact-info .info-icon {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 50%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-contact-info .info-icon {
    width: 80px;
    height: 80px;
  }
}

@media only screen and (max-width: 575px) {
  .single-contact-info .info-icon {
    margin: 0 auto;
  }
}

.single-contact-info .info-icon i {
  width: 70px;
  height: 70px;
  line-height: 78px;
  background-color: #fff;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 50%;
  font-size: 30px;
  color: #309255;
  text-align: center;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-contact-info .info-icon i {
    width: 60px;
    height: 60px;
    line-height: 68px;
    font-size: 24px;
  }
}

.single-contact-info .info-content {
  flex: 1;
  padding-left: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-contact-info .info-content {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .single-contact-info .info-content {
    padding-left: 0;
    padding-top: 20px;
  }
}

.single-contact-info .info-content .title {
  font-size: 14px;
  font-weight: 400;
  color: #309255;
  margin-bottom: 0;
}

.single-contact-info .info-content p {
  font-size: 22px;
  font-weight: 500;
  color: #212832;
  margin-bottom: 0;
  margin-top: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-contact-info .info-content p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .single-contact-info .info-content p {
    font-size: 16px;
  }
}

.single-contact-info:hover .info-icon i {
  background-color: #309255;
  border-color: #309255;
  color: #fff;
}

.contact-form {
  max-width: 400px;
  margin-left: auto;
  margin-top: 45px;
}

@media only screen and (max-width: 575px) {
  .contact-form {
    margin-top: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .contact-form {
    max-width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .contact-form {
    margin-top: 30px;
  }
}

.contact-form .title {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1.4;
  padding-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .contact-form .title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .contact-form .title {
    font-size: 20px;
  }
}

.contact-form .title span {
  color: #309255;
  position: relative;
}

.contact-form .title span::before {
  position: absolute;
  content: '';
  background-image: url(../images/shape/shape-4.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 121px;
  height: 13px;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
}

@media only screen and (max-width: 575px) {
  .contact-form .title span::before {
    display: none;
  }
}

.contact-form .form-wrapper {
  padding-top: 30px;
}

@media only screen and (max-width: 575px) {
  .contact-form .form-wrapper {
    padding-top: 10px;
  }
}

.contact-form .single-form .btn {
  margin-top: 20px;
  transition: all 0.3s ease 0s;
}

.contact-form .single-form .btn::before {
  width: 600px;
  height: 600px;
}

.contact-form .single-form .btn.btn-outline {
  border: 1px solid rgba(48, 146, 85, 0.2);
  line-height: 58px;
}

.contact-form .single-form .btn.btn-outline:hover {
  background-color: #309255;
  border-color: #309255;
  color: #fff;
}

.contact-form .single-form .btn i {
  display: inline-block;
  font-size: 30px;
  position: relative;
  line-height: 1;
  top: 7px;
  padding-left: 30px;
}

.form-message {
  margin-bottom: 0;
}

.form-message.error, .form-message.success {
  font-size: 16px;
  font-weight: 500;
  color: #212832;
  background: #ddd;
  padding: 10px 15px;
  margin-top: 15px;
}

.form-message.error {
  color: #ff0000;
}

/*--
/*  4.19 - Courses Enroll CSS
/*----------------------------------------*/
.courses-enroll-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.courses-video-player {
  width: 71.8%;
  padding-bottom: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .courses-video-player {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .courses-video-player {
    padding-bottom: 60px;
  }
}

.courses-video-player .vidcontainer {
  background: #010101;
  position: relative;
  height: 690px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-video-player .vidcontainer {
    height: 490px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .courses-video-player .vidcontainer {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .courses-video-player .vidcontainer {
    height: 420px;
  }
}

@media only screen and (max-width: 575px) {
  .courses-video-player .vidcontainer {
    height: 340px;
  }
}

.courses-video-player video {
  width: 100%;
  height: 690px;
  text-align: center;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-video-player video {
    height: 490px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .courses-video-player video {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .courses-video-player video {
    height: 420px;
  }
}

@media only screen and (max-width: 575px) {
  .courses-video-player video {
    height: 340px;
  }
}

.courses-video-player .video-play-bar {
  background-color: rgba(33, 40, 50, 0.8);
  position: absolute;
  bottom: 20px;
  left: 30px;
  right: 30px;
  padding: 20px 40px;
  z-index: 13;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  transition-delay: 1.5s;
}

@media only screen and (max-width: 575px) {
  .courses-video-player .video-play-bar {
    left: 20px;
    right: 20px;
    padding: 15px 15px;
  }
}

.courses-video-player .topControl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
}

.courses-video-player .topControl .progress {
  width: calc(100% - 110px);
  height: 3px;
  position: relative;
  cursor: pointer;
  background-color: #7a8088;
}

@media only screen and (max-width: 575px) {
  .courses-video-player .topControl .progress {
    width: calc(100% - 100px);
  }
}

.courses-video-player .topControl .progress span {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}

.courses-video-player .topControl .progress .timeBar {
  z-index: 10;
  width: 0;
  background-color: #ff0000;
}

.courses-video-player .topControl .progress .bufferBar {
  z-index: 5;
  width: 0;
  background-color: rgba(255, 255, 255, 0.6);
}

.courses-video-player .topControl .time {
  white-space: nowrap;
  text-align: right;
  font-size: 12px;
  font-family: "Gordita";
  font-weight: 400;
  color: #fff;
}

.courses-video-player .controllers {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-left: -5px;
  margin-right: -5px;
}

.courses-video-player .controllers .controllers-right {
  white-space: nowrap;
  text-align: right;
}

.courses-video-player .controllers button {
  border: 0;
  background: none;
  color: #fff;
  margin: 0 10px;
  outline: none;
  font-size: 15px;
  display: inline-block;
  padding: 0;
}

@media only screen and (max-width: 575px) {
  .courses-video-player .controllers button {
    font-size: 14px;
  }
}

.courses-video-player .controllers .btnPlay:after {
  content: "\f04b";
  font-family: 'FontAwesome';
}

.courses-video-player .controllers .paused:after {
  content: "\f04c";
}

.courses-video-player .controllers .sound:after {
  content: "\f027";
  font-family: 'FontAwesome';
}

.courses-video-player .controllers .sound2:after {
  content: "\f028";
}

.courses-video-player .controllers .muted:after {
  content: "\f026";
}

.courses-video-player .controllers .volume {
  position: relative;
  cursor: pointer;
  width: 70px;
  height: 5px;
  background-color: #7a8088;
  display: inline-block;
  margin-bottom: 2px;
}

.courses-video-player .controllers .volume .volumeBar {
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ff0000;
  z-index: 10;
}

.courses-video-player .controllers .speedcnt {
  display: none;
  position: absolute;
  right: 60px;
  bottom: 46px;
  background-color: #fff;
  border-radius: 5px;
  transition: all 0.3s ease 0s;
}

.courses-video-player .controllers .speedcnt li {
  text-align: center;
  font-family: "Gordita";
  font-weight: 400;
  font-size: 11px;
  padding: 5px 20px;
  cursor: pointer;
  display: block;
  border-bottom: 1px solid rgba(48, 146, 85, 0.2);
  transition: all 0.3s ease 0s;
  color: #52565b;
}

.courses-video-player .controllers .speedcnt li:last-child {
  border-bottom: 0;
}

.courses-video-player .controllers .speedcnt li.selected {
  font-weight: 500;
  background-color: #309255;
  color: #fff;
}

.courses-video-player .controllers .btnFS:after {
  content: "\f065";
  font-family: 'FontAwesome';
}

.courses-video-player .controllers .disabled {
  pointer-events: none;
  cursor: not-allowed;
  color: rgba(255, 255, 255, 0.5);
}

.courses-video-player .bigplay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 11;
  color: #fff;
  font-size: 90px;
  line-height: 90px;
  text-align: center;
  cursor: pointer;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 575px) {
  .courses-video-player .bigplay {
    font-size: 45px;
  }
}

.courses-video-player .bigplay i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.courses-video-player .loading {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
}

.courses-video-player .loading .spinner {
  border-color: #fff;
  border-right-color: transparent;
  width: 70px;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -35px;
  margin-left: -35px;
}

.courses-video-player:hover .video-play-bar {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.courses-enroll-content {
  padding: 0 70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .courses-enroll-content {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 575px) {
  .courses-enroll-content {
    padding: 0 20px;
  }
}

.courses-enroll-content .courses-enroll-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .courses-enroll-content .courses-enroll-title {
    display: block;
  }
}

.courses-enroll-content .courses-enroll-title .title {
  max-width: 760px;
  font-size: 25px;
  font-weight: 500;
  line-height: 1.5;
  color: #212832;
  margin-bottom: 0;
  margin-top: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-enroll-content .courses-enroll-title .title {
    max-width: 380px;
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  .courses-enroll-content .courses-enroll-title .title {
    font-size: 18px;
  }
}

.courses-enroll-content .courses-enroll-title p {
  font-size: 15px;
  font-weight: 400;
  margin-top: 10px;
}

.courses-enroll-content .courses-enroll-title p i {
  color: #ff4200;
  margin-right: 3px;
}

.courses-enroll-content .courses-enroll-title p span {
  color: #ff4200;
}

.courses-enroll-content .courses-enroll-tab {
  background-color: #e7f8ee;
  padding: 5px 15px 15px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .courses-enroll-content .courses-enroll-tab {
    display: block;
  }
}

.courses-enroll-content .courses-enroll-tab .enroll-tab-menu .nav {
  margin-left: -7px;
  margin-right: -7px;
}

.courses-enroll-content .courses-enroll-tab .enroll-tab-menu .nav li {
  padding: 10px 7px 0;
}

.courses-enroll-content .courses-enroll-tab .enroll-tab-menu .nav li button {
  border: 1px solid rgba(48, 146, 85, 0.2);
  background-color: #fff;
  border-radius: 10px;
  padding: 0 15px;
  height: 45px;
  line-height: 41px;
  text-align: center;
  display: block;
  font-weight: 500;
  font-size: 15px;
  color: #212832;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-enroll-content .courses-enroll-tab .enroll-tab-menu .nav li button {
    font-size: 14px;
    padding: 0 13px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .courses-enroll-content .courses-enroll-tab .enroll-tab-menu .nav li button {
    font-size: 14px;
    padding: 0 13px;
  }
}

.courses-enroll-content .courses-enroll-tab .enroll-tab-menu .nav li button.active, .courses-enroll-content .courses-enroll-tab .enroll-tab-menu .nav li button:hover {
  background-color: #309255;
  border-color: #309255;
  color: #fff;
}

.courses-enroll-content .courses-enroll-tab .enroll-share {
  padding-top: 10px;
}

.courses-enroll-content .courses-enroll-tab .enroll-share a {
  border: 1px solid rgba(48, 146, 85, 0.2);
  background-color: #fff;
  border-radius: 10px;
  padding: 0 25px;
  height: 45px;
  line-height: 43px;
  text-align: center;
  display: inline-block;
  font-weight: 500;
  font-size: 15px;
  color: #212832;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-enroll-content .courses-enroll-tab .enroll-share a {
    font-size: 14px;
    padding: 0 20px;
  }
}

.courses-enroll-content .courses-enroll-tab .enroll-share a i {
  margin-right: 5px;
  font-size: 20px;
  color: #309255;
  transition: all 0.3s ease 0s;
}

.courses-enroll-content .courses-enroll-tab .enroll-share a:hover {
  background-color: #309255;
  border-color: #309255;
  color: #fff;
}

.courses-enroll-content .courses-enroll-tab .enroll-share a:hover i {
  color: #fff;
}

.courses-enroll-content .courses-enroll-tab-content {
  border: 1px solid rgba(48, 146, 85, 0.2);
  padding: 10px 50px 35px;
  border-radius: 10px;
  margin-top: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-enroll-content .courses-enroll-tab-content {
    padding: 1px 40px 25px;
  }
}

@media only screen and (max-width: 575px) {
  .courses-enroll-content .courses-enroll-tab-content {
    padding: 1px 20px 15px;
  }
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-title {
  padding-top: 20px;
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-title .title {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.5;
  color: #212832;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-enroll-content .courses-enroll-tab-content .enroll-tab-title .title {
    font-size: 20px;
  }
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content {
  padding-top: 25px;
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content p {
  font-size: 15px;
  font-weight: 400;
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .table {
  border-top: 1px solid rgba(48, 146, 85, 0.2);
  margin-bottom: 25px;
  margin-top: 25px;
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .table tbody tr th {
  width: 170px;
  border-color: rgba(48, 146, 85, 0.2);
  padding: 12px 30px;
  padding-right: 0;
  font-size: 15px;
  font-weight: 500;
  color: #212832;
  white-space: nowrap;
}

@media only screen and (max-width: 575px) {
  .courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .table tbody tr th {
    font-size: 14px;
    padding: 8px 15px;
    padding-right: 0;
    min-width: 90px;
  }
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .table tbody tr th span {
  display: block;
  float: right;
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .table tbody tr td {
  border-color: rgba(48, 146, 85, 0.2);
  padding: 12px 30px;
  font-size: 15px;
  font-weight: 400;
  color: #52565b;
}

@media only screen and (max-width: 575px) {
  .courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .table tbody tr td {
    font-size: 14px;
    padding: 8px 15px;
  }
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .text {
  font-weight: 500;
  color: #212832;
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor {
  border-bottom: 1px solid rgba(48, 146, 85, 0.2);
  position: relative;
  overflow: hidden;
  padding: 25px 0 20px;
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor:first-child {
  padding-top: 0;
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor .review-author {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor .review-author {
    display: block;
  }
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor .review-author .author-thumb {
  position: relative;
  display: inline-block;
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor .review-author .author-thumb img {
  display: block;
  width: 90px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 50%;
  padding: 8px;
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor .review-author .author-content {
  flex: 1;
  padding-left: 30px;
}

@media only screen and (max-width: 575px) {
  .courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor .review-author .author-content {
    padding-left: 0;
    padding-top: 20px;
  }
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor .review-author .author-content .name {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0;
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor .review-author .author-content .designation {
  font-size: 14px;
  font-weight: 400;
  color: #309255;
  margin-top: 5px;
  display: block;
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor .review-author .author-content .rating-star {
  position: relative;
  margin-top: 5px;
  display: inline-block;
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor .review-author .author-content .rating-star::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #d0d0d0;
  font-size: 14px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor .review-author .author-content .rating-star::before {
    font-size: 13px;
    letter-spacing: 1px;
  }
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor .review-author .author-content .rating-star .rating-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  display: inline-block;
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor .review-author .author-content .rating-star .rating-bar::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #ffba00;
  font-size: 14px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor .review-author .author-content .rating-star .rating-bar::before {
    font-size: 13px;
    letter-spacing: 1px;
  }
}

.courses-enroll-content .courses-enroll-tab-content .enroll-tab-content .single-instructor p {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
  color: #212832;
}

.courses-video-playlist {
  width: 28.2%;
  background-color: #eefbf3;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .courses-video-playlist {
    width: 100%;
  }
}

.courses-video-playlist .playlist-title {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 30px 25px;
  background-color: #dff0e6;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .courses-video-playlist .playlist-title {
    padding: 15px 40px 25px;
  }
}

@media only screen and (max-width: 575px) {
  .courses-video-playlist .playlist-title {
    padding: 5px 20px 15px;
  }
}

.courses-video-playlist .playlist-title .title {
  font-size: 22px;
  font-weight: 500;
  color: #212832;
  margin-bottom: 0;
  margin-top: 10px;
}

@media only screen and (max-width: 575px) {
  .courses-video-playlist .playlist-title .title {
    font-size: 18px;
  }
}

.courses-video-playlist .playlist-title span {
  font-size: 18px;
  font-weight: 500;
  color: #309255;
  display: inline-block;
  margin-top: 10px;
}

@media only screen and (max-width: 575px) {
  .courses-video-playlist .playlist-title span {
    font-size: 16px;
  }
}

.courses-video-playlist .video-playlist .accordion-item {
  text-align: left;
  border: 0;
  margin-bottom: 0;
  background: none;
}

.courses-video-playlist .video-playlist .accordion-item button {
  border: 0;
  background: none;
  text-align: left;
  display: block;
  width: 100%;
  border-bottom: 1px solid #cde9d7;
  padding: 8px 30px;
  padding-right: 50px;
  background-color: #e0f1e7;
  position: relative;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .courses-video-playlist .video-playlist .accordion-item button {
    padding: 8px 40px;
  }
}

@media only screen and (max-width: 575px) {
  .courses-video-playlist .video-playlist .accordion-item button {
    padding: 8px 20px;
    padding-right: 40px;
  }
}

.courses-video-playlist .video-playlist .accordion-item button p {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  color: #309255;
  transition: all 0.3s ease 0s;
  max-width: 420px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .courses-video-playlist .video-playlist .accordion-item button p {
    font-size: 14px;
  }
}

.courses-video-playlist .video-playlist .accordion-item button::before {
  content: '\eaa1';
  font-family: IcoFont;
  font-size: 20px;
  color: #309255;
  position: absolute;
  top: 8px;
  right: 30px;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 575px) {
  .courses-video-playlist .video-playlist .accordion-item button::before {
    right: 20px;
  }
}

.courses-video-playlist .video-playlist .accordion-item button.collapsed {
  background-color: #eefbf3;
}

.courses-video-playlist .video-playlist .accordion-item button.collapsed p {
  color: #212832;
}

.courses-video-playlist .video-playlist .accordion-item button.collapsed::before {
  content: '\eab2';
  color: #212832;
}

.courses-video-playlist .video-playlist .total-duration {
  font-size: 13px;
  font-weight: 400;
  color: #848886;
  margin-top: 6px;
  display: block;
}

.courses-video-playlist .video-playlist .vids a {
  display: block;
  padding: 8px 80px;
  padding-right: 30px;
  border-bottom: 1px solid #cde9d7;
  position: relative;
}

.courses-video-playlist .video-playlist .vids a::before {
  position: absolute;
  content: '';
  width: 13px;
  height: 13px;
  border: 3px solid #309255;
  border-radius: 50%;
  top: 13px;
  left: 48px;
}

.courses-video-playlist .video-playlist .vids a p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #212832;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .courses-video-playlist .video-playlist .vids a p {
    font-size: 13px;
  }
}

.courses-video-playlist .video-playlist .vids a.active::before {
  background-color: #309255;
  border-color: #acd6bc;
}

.courses-video-playlist .video-playlist .vids a.active p {
  color: #309255;
}

/*--
/*  4.20 - Courses Admin CSS
/*----------------------------------------*/
.sidebar-wrapper {
  background-color: #309255;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 991;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .sidebar-wrapper {
    position: fixed;
    height: auto;
    width: 100%;
    top: auto;
    bottom: 0;
  }
}

.sidebar-wrapper .menu-list {
  padding: 40px 22px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .sidebar-wrapper .menu-list {
    display: flex;
    justify-content: center;
    padding: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .sidebar-wrapper .menu-list {
    padding: 10px;
  }
}

.sidebar-wrapper .menu-list a {
  width: 55px;
  height: 55px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease 0s;
  font-size: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .sidebar-wrapper .menu-list a {
    margin: 0 10px;
    width: 45px;
    height: 45px;
  }
}

@media only screen and (max-width: 575px) {
  .sidebar-wrapper .menu-list a {
    margin: 0 7px;
  }
}

.sidebar-wrapper .menu-list a::before {
  position: absolute;
  content: '';
  width: 4px;
  height: 25px;
  background-color: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  top: 50%;
  left: -24px;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .sidebar-wrapper .menu-list a::before {
    width: 25px;
    height: 4px;
    left: 50%;
    top: auto;
    bottom: -17px;
    transform: translateX(-50%) translateY(0);
  }
}

@media only screen and (max-width: 575px) {
  .sidebar-wrapper .menu-list a::before {
    bottom: -12px;
  }
}

.sidebar-wrapper .menu-list a + a {
  margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .sidebar-wrapper .menu-list a + a {
    margin-top: 0;
  }
}

.sidebar-wrapper .menu-list a.active, .sidebar-wrapper .menu-list a:hover {
  background-color: #3c9d60;
  border-color: #3c9d60;
}

.sidebar-wrapper .menu-list a.active::before, .sidebar-wrapper .menu-list a:hover::before {
  opacity: 1;
  visibility: visible;
}

.page-content-wrapper {
  padding-left: 100px;
  padding-top: 40px;
  padding-bottom: 80px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .page-content-wrapper {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .page-content-wrapper {
    padding-bottom: 60px;
  }
}

.page-content-wrapper .custom-container {
  padding: 0 15px;
}

@media only screen and (min-width: 1600px) {
  .page-content-wrapper .custom-container {
    padding: 0 50px;
  }
}

.page-content-wrapper .message {
  display: flex;
  align-items: flex-start;
  background-color: #e7f8ee;
  border-radius: 10px;
  padding: 26px 40px;
}

@media only screen and (max-width: 767px) {
  .page-content-wrapper .message {
    padding: 26px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .page-content-wrapper .message {
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .page-content-wrapper .message {
    padding: 26px 30px;
  }
}

.page-content-wrapper .message .message-icon {
  margin-top: 3px;
}

.page-content-wrapper .message .message-content {
  padding-left: 40px;
  flex: 1;
}

@media only screen and (max-width: 767px) {
  .page-content-wrapper .message .message-content {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .page-content-wrapper .message .message-content {
    padding-left: 0;
    padding-top: 15px;
  }
}

.admin-courses-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
}

@media only screen and (max-width: 767px) {
  .admin-courses-tab {
    display: block;
  }
}

.admin-courses-tab .title {
  font-size: 32px;
  font-weight: 500;
  color: #212832;
  padding-top: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .admin-courses-tab .title {
    font-size: 26px;
  }
}

.admin-courses-tab .courses-tab-wrapper {
  display: flex;
  align-items: center;
  margin-left: -10px;
  margin-right: -10px;
}

@media only screen and (max-width: 767px) {
  .admin-courses-tab .courses-tab-wrapper {
    display: block;
  }
}

.admin-courses-tab .courses-tab-wrapper > * {
  margin: 0 10px;
}

.admin-courses-tab .courses-tab-wrapper .courses-select {
  padding-top: 20px;
}

.admin-courses-tab .courses-tab-wrapper .courses-select .nice-select {
  float: none;
  display: inline-block;
  padding: 0 40px 0 20px;
  height: 50px;
  line-height: 48px;
  border-radius: 10px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  font-family: "Gordita";
  font-weight: 500;
  font-size: 15px;
  color: #212832;
}

@media only screen and (max-width: 767px) {
  .admin-courses-tab .courses-tab-wrapper .courses-select .nice-select {
    display: block;
  }
}

.admin-courses-tab .courses-tab-wrapper .courses-select .nice-select::after {
  width: 6px;
  height: 6px;
  border-color: #212832;
  right: 20px;
}

@media only screen and (max-width: 767px) {
  .admin-courses-tab .courses-tab-wrapper .courses-select .nice-select .list {
    width: 100%;
  }
}

.admin-courses-tab .courses-tab-wrapper .courses-select .nice-select .list li {
  min-height: 35px;
  line-height: 35px;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .admin-courses-tab .courses-tab-wrapper .nav {
    justify-content: center;
  }
}

.admin-courses-tab .courses-tab-wrapper .nav li {
  margin-right: 20px;
  padding-top: 20px;
}

.admin-courses-tab .courses-tab-wrapper .nav li:last-child {
  margin-right: 0;
}

.admin-courses-tab .courses-tab-wrapper .nav li button {
  width: 50px;
  height: 50px;
  line-height: 48px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  text-align: center;
  padding: 0;
  background-color: #fff;
  font-size: 20px;
  color: #212832;
  border-radius: 10px;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .admin-courses-tab .courses-tab-wrapper .nav li button {
    width: 45px;
    height: 45px;
    line-height: 43px;
    font-size: 14px;
  }
}

.admin-courses-tab .courses-tab-wrapper .nav li button.active, .admin-courses-tab .courses-tab-wrapper .nav li button:hover {
  background-color: #309255;
  border-color: #309255;
  color: #fff;
}

.admin-courses-tab .courses-tab-wrapper .tab-btn {
  padding-top: 20px;
}

.admin-courses-tab .courses-tab-wrapper .tab-btn .btn {
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  padding: 0 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .admin-courses-tab .courses-tab-wrapper .tab-btn .btn {
    height: 45px;
    line-height: 45px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .admin-courses-tab .courses-tab-wrapper .tab-btn .btn {
    padding: 0 15px;
    width: 100%;
  }
}

.admin-courses-tab .courses-tab-wrapper .tab-btn .btn::before {
  width: 500px;
  height: 500px;
}

.courses-item {
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  padding: 20px;
  margin-top: 30px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .courses-item {
    padding: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .courses-item {
    flex-wrap: wrap;
    padding: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .courses-item {
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .courses-item {
    padding: 15px;
  }
}

.courses-item .item-thumb a img {
  border-radius: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .courses-item .item-thumb a img {
    width: 130px;
  }
}

.courses-item .content-title {
  max-width: 545px;
  padding-left: 45px;
  flex: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .courses-item .content-title {
    padding-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .courses-item .content-title {
    max-width: 460px;
  }
}

@media only screen and (max-width: 767px) {
  .courses-item .content-title {
    padding-left: 0;
    padding-top: 20px;
  }
}

.courses-item .content-title .meta {
  display: flex;
  flex-wrap: wrap;
}

.courses-item .content-title .meta .action {
  height: 18px;
  line-height: 18px;
  padding: 0 13px;
  border-radius: 50px;
  display: inline-block;
  font-size: 11px;
  font-family: "Gordita";
  font-weight: 400;
}

.courses-item .content-title .meta .action + .action {
  margin-left: 10px;
}

.courses-item .content-title .meta .action:nth-of-type(1) {
  background-color: #ff6e30;
  color: #fff;
}

.courses-item .content-title .meta .action:nth-of-type(2) {
  background-color: #dff5ef;
  color: #06b284;
}

.courses-item .content-title .meta .action:nth-of-type(3) {
  background-color: #e1e6fa;
  color: #0066ff;
}

.courses-item .content-title .title {
  margin-bottom: 0;
}

.courses-item .content-title .title a {
  font-size: 20px;
  font-weight: 500;
  color: #212832;
  margin-top: 18px;
  display: block;
  line-height: 1.5;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .courses-item .content-title .title a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .courses-item .content-title .title a {
    font-size: 16px;
  }
}

.courses-item .content-title .title a:hover {
  color: #309255;
}

.courses-item .content-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  margin-left: -18px;
  margin-right: -18px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (max-width: 767px) {
  .courses-item .content-wrapper {
    margin-left: -7px;
    margin-right: -7px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-item .content-wrapper {
    margin-left: -10px;
    margin-right: -10px;
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .courses-item .content-wrapper {
    margin-left: -5px;
    margin-right: -5px;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .courses-item .content-wrapper {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.courses-item .content-wrapper > * {
  margin: 15px 18px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (max-width: 767px) {
  .courses-item .content-wrapper > * {
    margin: 15px 7px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .courses-item .content-wrapper > * {
    margin: 15px 10px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .courses-item .content-wrapper > * {
    margin: 15px 5px 0;
  }
}

.courses-item .content-wrapper .content-box {
  width: 180px;
  padding: 15px 25px;
  border-radius: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .courses-item .content-wrapper .content-box {
    width: 150px;
    padding: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .courses-item .content-wrapper .content-box {
    width: 143px;
    padding: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .courses-item .content-wrapper .content-box {
    padding: 15px;
    width: auto;
  }
}

.courses-item .content-wrapper .content-box p {
  font-size: 15px;
  font-weight: 400;
  color: #212832;
  margin-bottom: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .courses-item .content-wrapper .content-box p {
    font-size: 14px;
  }
}

.courses-item .content-wrapper .content-box .count {
  font-size: 22px;
  font-weight: 700;
  margin-top: 3px;
  display: block;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .courses-item .content-wrapper .content-box .count {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .courses-item .content-wrapper .content-box .count {
    font-size: 16px;
  }
}

.courses-item .content-wrapper .content-box .count .rating-star {
  position: relative;
}

.courses-item .content-wrapper .content-box .count .rating-star::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #bfbfbf;
  font-size: 13px;
  letter-spacing: 1px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .courses-item .content-wrapper .content-box .count .rating-star::before {
    font-size: 12px;
  }
}

.courses-item .content-wrapper .content-box .count .rating-star .rating-bar {
  position: absolute;
  top: -2px;
  left: 0;
  height: 100%;
  overflow: hidden;
}

.courses-item .content-wrapper .content-box .count .rating-star .rating-bar::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #ff9600;
  font-size: 13px;
  letter-spacing: 1px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .courses-item .content-wrapper .content-box .count .rating-star .rating-bar::before {
    font-size: 12px;
  }
}

.courses-item .content-wrapper .content-box:nth-of-type(1) {
  background-color: #e5ecff;
}

.courses-item .content-wrapper .content-box:nth-of-type(1) .count {
  color: #0f42cd;
}

.courses-item .content-wrapper .content-box:nth-of-type(2) {
  background-color: #def2e6;
}

.courses-item .content-wrapper .content-box:nth-of-type(2) .count {
  color: #309255;
}

.courses-item .content-wrapper .content-box:nth-of-type(3) {
  background-color: #ffe8df;
}

.courses-item .content-wrapper .content-box:nth-of-type(3) .count {
  color: #ff6e30;
}

.courses-item .content-wrapper .courses-select .nice-select {
  float: none;
  display: inline-block;
  padding: 0 40px 0 20px;
  height: 50px;
  line-height: 48px;
  border-radius: 10px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  font-weight: 500;
  font-size: 15px;
  color: #212832;
}

.courses-item .content-wrapper .courses-select .nice-select::after {
  width: 6px;
  height: 6px;
  border-color: #212832;
  right: 20px;
}

.courses-item .content-wrapper .courses-select .nice-select .list li {
  min-height: 35px;
  line-height: 35px;
  font-size: 14px;
}

.courses-resources {
  background-color: #eefbf3;
  padding: 70px 100px;
  border-radius: 10px;
  margin-top: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .courses-resources {
    padding: 70px 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .courses-resources {
    padding: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .courses-resources {
    margin-top: 60px;
    padding: 50px 40px;
  }
}

@media only screen and (max-width: 575px) {
  .courses-resources {
    padding: 20px 20px;
  }
}

.courses-resources .title {
  font-size: 25px;
  font-weight: 500;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .courses-resources .title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  .courses-resources .title {
    font-size: 20px;
  }
}

.single-resources {
  text-align: center;
  margin-top: 30px;
}

.single-resources .resources-icon {
  border: 1px solid rgba(48, 146, 85, 0.2);
  display: inline-block;
  padding: 13px;
  border-radius: 50%;
}

.single-resources .resources-icon a {
  position: relative;
  display: inline-block;
  background-color: #d3ecdd;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
}

.single-resources .resources-icon a img {
  border-radius: 50%;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 575px) {
  .single-resources .resources-icon a img {
    width: 60px;
  }
}

.single-resources .resources-icon a .hover {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.single-resources .title {
  margin-bottom: 0;
}

.single-resources .title a {
  font-size: 22px;
  font-weight: 500;
  margin-top: 28px;
  display: inline-block;
}

@media only screen and (max-width: 575px) {
  .single-resources .title a {
    font-size: 18px;
  }
}

.single-resources:hover .resources-icon a {
  background-color: #309255;
}

.single-resources:hover .resources-icon a img {
  opacity: 0;
  visibility: hidden;
}

.single-resources:hover .resources-icon a .hover {
  opacity: 1;
  visibility: visible;
}

/* --- CSS ----*/
.admin-tab-menu {
  padding: 40px 30px;
  background-color: #e5f4eb;
  position: absolute;
  top: 0;
  left: 99px;
  z-index: 9;
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .admin-tab-menu {
    height: auto;
    left: 0;
    position: relative;
  }
}

.admin-tab-menu a {
  display: block;
  width: 280px;
  height: 55px;
  line-height: 53px;
  border-radius: 10px;
  padding: 0 50px 0 20px;
  border: 1px solid #c1e0cd;
  background-color: #daeee2;
  text-align: left;
  position: relative;
  transition: all 0.3s ease 0s;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .admin-tab-menu a {
    width: 230px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .admin-tab-menu a {
    width: 205px;
    font-size: 14px;
    padding: 0 45px 0 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .admin-tab-menu a {
    width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .admin-tab-menu a {
    font-size: 14px;
    padding: 0 45px 0 15px;
  }
}

.admin-tab-menu a span {
  width: 25px;
  height: 25px;
  line-height: 23px;
  text-align: center;
  background-color: #309255;
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .admin-tab-menu a span {
    right: 17px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .admin-tab-menu a span {
    right: 17px;
    width: 21px;
    height: 21px;
    line-height: 20px;
  }
}

.admin-tab-menu a::before {
  position: absolute;
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #309255;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .admin-tab-menu a::before {
    left: 15px;
  }
}

.admin-tab-menu a + a {
  margin-top: 20px;
}

.admin-tab-menu a.active, .admin-tab-menu a:hover {
  padding-left: 40px;
  font-weight: 500;
  background-color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .admin-tab-menu a.active, .admin-tab-menu a:hover {
    padding-left: 25px;
  }
}

.admin-tab-menu a.active::before, .admin-tab-menu a:hover::before {
  opacity: 1;
  visibility: visible;
}

.main-content-wrapper {
  padding-top: 40px;
  padding-bottom: 80px;
  padding-left: 340px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .main-content-wrapper {
    padding-left: 289px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-content-wrapper {
    padding-left: 266px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .main-content-wrapper {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .main-content-wrapper {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 1600px) {
  .main-content-wrapper .container-fluid, .main-content-wrapper .container-sm, .main-content-wrapper .container-md, .main-content-wrapper .container-lg, .main-content-wrapper .container-xl, .main-content-wrapper .container-xxl {
    padding: 0 60px;
  }
}

.admin-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .admin-top-bar {
    display: block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .admin-top-bar.students-top {
    display: flex;
  }
}

.overview-box {
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .overview-box {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media only screen and (max-width: 767px) {
  .overview-box {
    flex-wrap: wrap;
    margin: 0;
  }
}

.overview-box .single-box {
  border-radius: 10px;
  padding: 15px 30px;
  width: 200px;
  margin: 20px 20px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .overview-box .single-box {
    margin: 20px 10px 0;
    width: 175px;
    padding: 15px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .overview-box .single-box {
    margin: 20px 10px 0;
    width: 185px;
    padding: 15px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .overview-box .single-box {
    margin: 20px 10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .overview-box .single-box {
    width: 100%;
    margin: 20px 0 0;
  }
}

.overview-box .single-box .title {
  font-size: 15px;
  font-weight: 400;
  color: #212832;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .overview-box .single-box .title {
    font-size: 14px;
  }
}

.overview-box .single-box .count {
  font-size: 30px;
  font-weight: 500;
  margin-top: 5px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 768px) and (max-width: 991px) {
  .overview-box .single-box .count {
    font-size: 20px;
  }
}

.overview-box .single-box .count .rating-star {
  position: relative;
}

.overview-box .single-box .count .rating-star::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #d0d0d0;
  font-size: 13px;
  letter-spacing: 1px;
}

.overview-box .single-box .count .rating-star .rating-bar {
  position: absolute;
  top: -2px;
  left: 0;
  height: 100%;
  overflow: hidden;
}

.overview-box .single-box .count .rating-star .rating-bar::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #ffba00;
  font-size: 13px;
  letter-spacing: 1px;
}

.overview-box .single-box p {
  font-size: 13px;
  font-weight: 500;
  color: #212832;
  margin-top: 3px;
}

.overview-box .single-box p span {
  color: #309255;
}

.overview-box .single-box:nth-of-type(1) {
  background-color: #e5ecff;
}

.overview-box .single-box:nth-of-type(1) .count {
  color: #0f42cd;
}

.overview-box .single-box:nth-of-type(2) {
  background-color: #def2e6;
}

.overview-box .single-box:nth-of-type(2) .count {
  color: #309255;
}

.overview-box .single-box:nth-of-type(3) {
  background-color: #ffe8df;
}

.overview-box .single-box:nth-of-type(3) .count {
  color: #ff6e30;
}

.graph {
  padding: 25px 70px 45px;
  border-radius: 10px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  margin-top: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .graph {
    padding: 15px 30px 25px;
  }
}

.graph .graph-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .graph .graph-title {
    display: block;
  }
}

.graph .graph-title .title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0;
  padding-top: 15px;
  line-height: 1.4;
}

@media only screen and (max-width: 575px) {
  .graph .graph-title .title {
    font-size: 18px;
  }
}

.graph .graph-content {
  margin-top: 20px;
}

.graph .graph-btn {
  text-align: center;
  margin-top: 20px;
}

.graph .graph-btn .btn {
  font-size: 15px;
  white-space: nowrap;
}

@media only screen and (max-width: 575px) {
  .graph .graph-btn .btn {
    padding: 0 20px;
  }
}

.student-box {
  border-radius: 10px;
  padding: 25px 30px;
  width: 180px;
  margin-top: 20px;
  background-color: #def2e6;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .student-box {
    margin: 20px 10px 0;
  }
}

@media only screen and (max-width: 767px) {
  .student-box {
    width: 100%;
    margin: 20px 0 0;
  }
}

.student-box .title {
  font-size: 15px;
  font-weight: 400;
  color: #212832;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .student-box .title {
    font-size: 14px;
  }
}

.student-box .count {
  font-size: 32px;
  font-weight: 700;
  margin-top: 5px;
  color: #309255;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .student-box .count {
    font-size: 20px;
  }
}

.students-wrapper {
  padding-top: 10px;
}

.single-student {
  display: flex;
  align-items: center;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  padding: 25px;
  margin-top: 30px;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-student {
    padding: 15px;
  }
}

.single-student .student-images img {
  width: 85px;
  border-radius: 50%;
  border: 1px solid rgba(48, 146, 85, 0.2);
  padding: 7px;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-student .student-images img {
    padding: 4px;
  }
}

.single-student .student-content {
  padding-left: 30px;
  max-width: 220px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-student .student-content {
    padding-left: 15px;
    max-width: 200px;
  }
}

.single-student .student-content .name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .single-student .student-content .name {
    font-size: 16px;
  }
}

.single-student .student-content .country {
  color: #309255;
  font-size: 13px;
  font-weight: 400;
  display: block;
  margin-top: 7px;
}

.single-student .student-content .country img {
  width: 15px;
  margin-top: -6px;
}

.single-student .student-content p {
  font-size: 13px;
  font-weight: 500;
  color: #212832;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.single-student .student-content .date {
  font-size: 13px;
  font-weight: 400;
  color: #838785;
  display: block;
  margin-top: 2px;
}

.single-student .student-content .date i {
  color: #309255;
  margin-right: 5px;
}

.single-student:hover {
  border-color: #309255;
}

.single-student:hover .student-images img {
  border-color: #309255;
}

.students-active {
  position: relative;
}

.students-active .students-arrow {
  text-align: center;
  margin-top: 30px;
}

.students-active .swiper-button-next,
.students-active .swiper-button-prev {
  opacity: 1;
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid rgba(48, 146, 85, 0.2);
  color: #212832;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease 0s;
  position: relative;
  top: 0;
  margin-top: 0;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 575px) {
  .students-active .swiper-button-next,
  .students-active .swiper-button-prev {
    width: 35px;
    height: 35px;
    line-height: 32px;
  }
}

.students-active .swiper-button-next::after,
.students-active .swiper-button-prev::after {
  display: none;
}

.students-active .swiper-button-next:hover,
.students-active .swiper-button-prev:hover {
  background-color: #309255;
  color: #fff;
}

.students-active .swiper-button-prev {
  left: 0;
}

.students-active .swiper-button-next {
  right: 0;
}

.students-map {
  padding: 30px 45px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  margin-top: 50px;
}

@media only screen and (max-width: 575px) {
  .students-map {
    padding: 30px 20px;
  }
}

.students-map .title {
  font-size: 20px;
  font-weight: 500;
  background-color: #eaf8f0;
  border: 1px solid #daebe0;
  border-radius: 10px;
  text-align: center;
  padding: 16px;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .students-map .title {
    font-size: 16px;
  }
}

.students-map .map {
  margin-top: 30px;
}

.students-map .map #vmap {
  height: 530px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .students-map .map #vmap {
    height: 470px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .students-map .map #vmap {
    height: 340px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .students-map .map #vmap {
    height: 340px;
  }
}

.single-student-widget {
  padding: 25px 30px;
  border-radius: 10px;
  margin-top: 50px;
}

@media only screen and (max-width: 575px) {
  .single-student-widget {
    padding: 25px 15px;
  }
}

.single-student-widget.widget-color-01 {
  background-color: #fff6f1;
}

.single-student-widget.widget-color-01 .widget-title {
  background-color: #f9d9c7;
}

.single-student-widget.widget-color-01 .single-item .item-bar {
  background-color: #f9d9c7;
}

.single-student-widget.widget-color-01 .single-item .item-bar .bar-line {
  background-color: #ff6c00;
}

.single-student-widget.widget-color-01 .single-item .item-percentage p span {
  color: #ff6c00;
}

.single-student-widget.widget-color-01 .page-pagination .pagination li a {
  border: 1px solid #e1d0c4;
}

.single-student-widget.widget-color-01 .page-pagination .pagination li a.active, .single-student-widget.widget-color-01 .page-pagination .pagination li a:hover {
  color: #fff;
  background-color: #ff6c00;
  border-color: #ff6c00;
}

.single-student-widget.widget-color-02 {
  background-color: #eefbf3;
}

.single-student-widget.widget-color-02 .widget-title {
  background-color: #cdedd9;
}

.single-student-widget.widget-color-02 .single-item .item-bar {
  background-color: #c6e7d3;
}

.single-student-widget.widget-color-02 .single-item .item-bar .bar-line {
  background-color: #309255;
}

.single-student-widget.widget-color-02 .single-item .item-percentage p span {
  color: #309255;
}

.single-student-widget.widget-color-02 .page-pagination .pagination li a {
  border: 1px solid #e1d0c4;
}

.single-student-widget.widget-color-02 .page-pagination .pagination li a.active, .single-student-widget.widget-color-02 .page-pagination .pagination li a:hover {
  color: #fff;
  background-color: #309255;
  border-color: #309255;
}

.single-student-widget.widget-color-03 {
  background-color: #ecf1ff;
}

.single-student-widget.widget-color-03 .widget-title {
  background-color: #d3dfff;
}

.single-student-widget.widget-color-03 .single-item .item-bar {
  background-color: #b7d7fc;
}

.single-student-widget.widget-color-03 .single-item .item-bar .bar-line {
  background-color: #0f42cd;
}

.single-student-widget.widget-color-03 .single-item .item-percentage p span {
  color: #0f42cd;
}

.single-student-widget.widget-color-03 .page-pagination .pagination li a {
  border: 1px solid #c8d4f2;
}

.single-student-widget.widget-color-03 .page-pagination .pagination li a.active, .single-student-widget.widget-color-03 .page-pagination .pagination li a:hover {
  color: #fff;
  background-color: #0f42cd;
  border-color: #0f42cd;
}

.single-student-widget .widget-title {
  font-size: 18px;
  font-weight: 500;
  border-radius: 10px;
  text-align: center;
  padding: 14px;
  margin-bottom: 0;
}

.single-student-widget .widget-items {
  padding-top: 25px;
}

.single-student-widget .single-item {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 85px;
  margin-top: 15px;
  height: 37px;
}

.single-student-widget .single-item .item-flag {
  display: flex;
  align-items: center;
}

.single-student-widget .single-item .item-flag .flag {
  padding-right: 15px;
}

@media only screen and (max-width: 575px) {
  .single-student-widget .single-item .item-flag .flag {
    padding-right: 8px;
  }
}

.single-student-widget .single-item .item-flag .flag img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

@media only screen and (max-width: 575px) {
  .single-student-widget .single-item .item-flag .flag img {
    width: 30px;
    height: 30px;
  }
}

.single-student-widget .single-item .title {
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  color: #52565b;
  white-space: nowrap;
}

.single-student-widget .single-item .item-bar {
  width: calc(100% - 145px);
  height: 5px;
  border-radius: 50px;
  margin-left: auto;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .single-student-widget .single-item .item-bar {
    width: calc(100% - 130px);
  }
}

.single-student-widget .single-item .item-bar .bar-line {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.single-student-widget .single-item .item-percentage {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.single-student-widget .single-item .item-percentage p {
  font-size: 14px;
  font-weight: 500;
  color: #212832;
  white-space: nowrap;
  margin-bottom: 0;
}

.single-student-widget .single-item .item-percentage p span {
  font-size: 12px;
  margin-right: 3px;
}

.single-student-widget .page-pagination {
  padding-top: 40px;
}

.single-student-widget .page-pagination .pagination li a {
  border: 1px solid rgba(48, 146, 85, 0.2);
}

.single-student-widget .page-pagination .pagination li a.active, .single-student-widget .page-pagination .pagination li a:hover {
  color: #fff;
  background-color: #309255;
}

.new-courses {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px 50px;
  margin-top: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .new-courses {
    padding: 0 50px 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .new-courses {
    padding: 0 50px 50px;
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .new-courses {
    padding: 0 30px 50px;
  }
}

.new-courses::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(48, 146, 85, 0.95);
  z-index: -1;
}

.new-courses .new-courses-title {
  margin-top: 45px;
}

.new-courses .new-courses-title .title {
  font-size: 22px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0;
  line-height: 1.5;
}

@media only screen and (max-width: 575px) {
  .new-courses .new-courses-title .title {
    font-size: 18px;
  }
  .new-courses .new-courses-title .title br {
    display: none;
  }
}

.new-courses .shape {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 60%;
}

.new-courses .new-courses-btn {
  margin-top: 50px;
}

.new-courses .new-courses-btn .btn {
  font-size: 15px;
  font-weight: 500;
  background-color: #ffda30;
  white-space: nowrap;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (max-width: 575px) {
  .new-courses .new-courses-btn .btn {
    padding: 0 25px;
  }
}

.new-courses .new-courses-btn .btn:hover {
  background-color: #212832;
  color: #fff;
}

.engagement-meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 5px;
}

.engagement-meta .meta {
  font-size: 15px;
  font-weight: 500;
  color: #212832;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  margin-right: 50px;
  padding-top: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .engagement-meta .meta {
    margin-right: 30px;
    font-size: 14px;
  }
}

.engagement-meta .meta img {
  margin-right: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .engagement-meta .meta img {
    width: 16px;
  }
}

.engagement-meta .meta:last-child {
  margin-right: 0;
}

.courses-select {
  padding-top: 20px;
}

.courses-select .nice-select {
  float: none;
  display: inline-block;
  padding: 0 50px 0 30px;
  height: 60px;
  line-height: 58px;
  border-radius: 10px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  font-weight: 500;
  font-size: 20px;
  color: #212832;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .courses-select .nice-select {
    padding: 0 40px 0 20px;
    height: 50px;
    line-height: 48px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .courses-select .nice-select {
    display: block;
  }
}

.courses-select .nice-select::after {
  width: 8px;
  height: 8px;
  border-color: #212832;
  right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .courses-select .nice-select::after {
    right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .courses-select .nice-select .list {
    width: 100%;
  }
}

.courses-select .nice-select .list li {
  font-size: 15px;
}

.courses-select .title {
  font-size: 22px;
  font-weight: 500;
  color: #212832;
  margin-top: 25px;
}

.months-select {
  padding-top: 20px;
}

.months-select .nice-select {
  float: none;
  display: inline-block;
  padding: 0 40px 0 20px;
  height: 50px;
  line-height: 48px;
  border-radius: 10px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  background-color: #eaf8f0;
  font-weight: 500;
  font-size: 15px;
  color: #212832;
}

@media only screen and (max-width: 767px) {
  .months-select .nice-select {
    display: block;
  }
}

.months-select .nice-select::after {
  width: 7px;
  height: 7px;
  border-color: #212832;
  right: 20px;
}

@media only screen and (max-width: 767px) {
  .months-select .nice-select .list {
    width: 100%;
  }
}

.months-select .nice-select .list li {
  font-size: 15px;
}

.engagement-courses {
  padding-top: 40px;
  padding-left: 1px;
  padding-right: 1px;
}

.engagement-courses .courses-top {
  background-color: #e5f4eb;
  border: 0;
  border-radius: 10px;
}

.engagement-courses .courses-top ul {
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 767px) {
  .engagement-courses .courses-top ul {
    display: block;
    text-align: center;
  }
}

.engagement-courses .courses-top ul li {
  font-size: 18px;
  font-weight: 500;
  color: #212832;
  padding: 15px;
  white-space: nowrap;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .engagement-courses .courses-top ul li {
    font-size: 14px;
  }
}

.engagement-courses .courses-top ul li:nth-of-type(1) {
  width: 500px;
  padding-left: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .engagement-courses .courses-top ul li:nth-of-type(1) {
    width: 430px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .engagement-courses .courses-top ul li:nth-of-type(1) {
    padding-left: 50px;
    width: 280px;
  }
}

@media only screen and (max-width: 767px) {
  .engagement-courses .courses-top ul li:nth-of-type(1) {
    padding-left: 0;
    width: auto;
  }
}

.engagement-courses .courses-top ul li:nth-of-type(2) {
  width: 170px;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .engagement-courses .courses-top ul li:nth-of-type(2) {
    width: auto;
  }
}

.engagement-courses .courses-top ul li:nth-of-type(3) {
  width: 170px;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .engagement-courses .courses-top ul li:nth-of-type(3) {
    width: auto;
    margin-left: auto;
  }
}

.engagement-courses .courses-list ul li {
  padding: 20px;
  margin-top: 20px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .engagement-courses .courses-list ul li {
    padding: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .engagement-courses .courses-list ul li {
    display: block;
    text-align: center;
  }
}

.engagement-courses .courses-list ul li .courses {
  display: flex;
  align-items: center;
  max-width: 600px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .engagement-courses .courses-list ul li .courses {
    display: block;
  }
}

.engagement-courses .courses-list ul li .courses .thumb img {
  width: 100px;
  border-radius: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .engagement-courses .courses-list ul li .courses .thumb img {
    width: 70px;
  }
}

.engagement-courses .courses-list ul li .courses .content {
  flex: 1;
  padding-left: 30px;
  max-width: 330px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .engagement-courses .courses-list ul li .courses .content {
    padding-left: 15px;
    width: 285px;
  }
}

@media only screen and (max-width: 767px) {
  .engagement-courses .courses-list ul li .courses .content {
    padding-left: 0;
    padding-top: 25px;
    margin: 0 auto;
  }
}

.engagement-courses .courses-list ul li .courses .content .title {
  margin-bottom: 0;
}

.engagement-courses .courses-list ul li .courses .content .title a {
  font-size: 18px;
  font-weight: 500;
  color: #212832;
  display: inline-block;
  line-height: 1.4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .engagement-courses .courses-list ul li .courses .content .title a {
    font-size: 14px;
  }
}

.engagement-courses .courses-list ul li .courses .content .title a:hover {
  color: #309255;
}

.engagement-courses .courses-list ul li .taught span,
.engagement-courses .courses-list ul li .student span {
  font-size: 18px;
  font-weight: 500;
  color: #212832;
  display: block;
  padding: 0 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .engagement-courses .courses-list ul li .taught span,
  .engagement-courses .courses-list ul li .student span {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .engagement-courses .courses-list ul li .taught span,
  .engagement-courses .courses-list ul li .student span {
    padding-top: 20px;
  }
}

.engagement-courses .courses-list ul li .button {
  margin-left: auto;
}

@media only screen and (max-width: 767px) {
  .engagement-courses .courses-list ul li .button {
    padding-top: 20px;
  }
}

.engagement-courses .courses-list ul li .button .btn {
  height: 50px;
  line-height: 48px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  padding: 0 30px;
  font-size: 15px;
  white-space: nowrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .engagement-courses .courses-list ul li .button .btn {
    font-size: 13px;
    padding: 0 20px;
  }
}

.engagement-courses .courses-list ul li > *:nth-of-type(1) {
  width: 500px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .engagement-courses .courses-list ul li > *:nth-of-type(1) {
    width: auto;
  }
}

.engagement-courses .courses-list ul li > *:nth-of-type(2) {
  width: 170px;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .engagement-courses .courses-list ul li > *:nth-of-type(2) {
    width: auto;
  }
}

.engagement-courses .courses-list ul li > *:nth-of-type(3) {
  width: 170px;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .engagement-courses .courses-list ul li > *:nth-of-type(3) {
    width: auto;
  }
}

.filter-check {
  display: flex;
  padding-top: 10px;
  flex-wrap: wrap;
}

.filter-check .courses-select {
  padding-top: 0px;
}

.filter-check > li {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-top: 10px;
  white-space: nowrap;
}

.filter-check > li:last-child {
  margin-right: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .filter-check > li {
    margin-right: 26px;
  }
}

.filter-check > li label {
  font-size: 16px;
  font-weight: 400;
  color: #212832;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.filter-check > li input {
  display: none;
}

.filter-check > li input + label {
  cursor: pointer;
}

.filter-check > li input + label span {
  width: 18px;
  height: 18px;
  border: 1px solid #8691a1;
  border-radius: 2px;
  display: inline-block;
  margin-right: 13px;
  position: relative;
  transition: all 0.3s ease 0s;
}

.filter-check > li input + label span::before {
  position: absolute;
  content: '\eed6';
  font-family: IcoFont;
  color: #fff;
  font-size: 18px;
  opacity: 0;
  visibility: hidden;
  top: 0;
  left: -1px;
  line-height: 17px;
  transition: all 0.3s ease 0s;
}

.filter-check > li input:checked + label {
  font-weight: 500;
}

.filter-check > li input:checked + label span {
  background-color: #309255;
  border-color: #309255;
}

.filter-check > li input:checked + label span::before {
  opacity: 1;
  visibility: visible;
}

.rating-select .nice-select {
  float: none;
  display: inline-block;
  padding: 0 15px 0 0px;
  height: auto;
  line-height: 1;
  border-radius: 0;
  border: 0;
  font-weight: 500;
  font-size: 17px;
  color: #212832;
  margin-left: 8px;
}

@media only screen and (max-width: 767px) {
  .rating-select .nice-select {
    display: block;
  }
}

.rating-select .nice-select::after {
  width: 6px;
  height: 6px;
  border-color: #212832;
  right: 2px;
}

@media only screen and (max-width: 767px) {
  .rating-select .nice-select .list {
    width: 100%;
  }
}

.rating-select .nice-select .list li {
  font-size: 15px;
}

.top-bar-filter-right {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
}

@media only screen and (max-width: 767px) {
  .top-bar-filter-right {
    flex-wrap: wrap;
  }
}

.top-bar-filter-right > * {
  margin: 0 10px;
}

.top-bar-filter-right .filter-btn {
  padding-top: 20px;
}

.top-bar-filter-right .filter-btn .btn {
  font-size: 15px;
  padding: 0 20px;
  height: 50px;
  line-height: 48px;
}

.newest-select {
  padding-top: 20px;
}

.newest-select .nice-select {
  float: none;
  display: inline-block;
  padding: 0 40px 0 20px;
  height: 50px;
  line-height: 48px;
  border-radius: 10px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  font-weight: 500;
  font-size: 15px;
  color: #212832;
}

@media only screen and (max-width: 767px) {
  .newest-select .nice-select {
    display: block;
  }
}

.newest-select .nice-select::after {
  width: 7px;
  height: 7px;
  border-color: #212832;
  right: 20px;
}

@media only screen and (max-width: 767px) {
  .newest-select .nice-select .list {
    width: 100%;
  }
}

.newest-select .nice-select .list li {
  font-size: 15px;
}

.newest-select .title {
  font-size: 22px;
  font-weight: 500;
  font-family: "Gordita";
  color: #212832;
  margin-top: 25px;
}

.courses-rating-wrapper {
  padding-top: 15px;
}

.single-courses-rating {
  border: 1px solid rgba(48, 146, 85, 0.2);
  padding: 25px;
  border-radius: 10px;
  margin-top: 25px;
}

.single-courses-rating .courses {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .single-courses-rating .courses {
    display: block;
    text-align: center;
  }
}

.single-courses-rating .courses .courses-thumb a {
  display: block;
}

.single-courses-rating .courses .courses-thumb a img {
  border-radius: 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .single-courses-rating .courses .courses-thumb a img {
    width: 130px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-courses-rating .courses .courses-thumb a img {
    width: 100px;
  }
}

.single-courses-rating .courses .courses-content {
  flex: 1;
  max-width: 550px;
  padding-left: 50px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .single-courses-rating .courses .courses-content {
    padding-left: 30px;
    max-width: 370px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-courses-rating .courses .courses-content {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .single-courses-rating .courses .courses-content {
    padding-left: 0;
    padding-top: 25px;
    margin: 0 auto;
  }
}

.single-courses-rating .courses .courses-content .title {
  margin-bottom: 0;
}

.single-courses-rating .courses .courses-content .title a {
  font-size: 20px;
  font-weight: 500;
  color: #212832;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .single-courses-rating .courses .courses-content .title a {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-courses-rating .courses .courses-content .title a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .single-courses-rating .courses .courses-content .title a {
    font-size: 16px;
  }
}

.single-courses-rating .courses .courses-content .title a:hover {
  color: #309255;
}

.single-courses-rating .courses .courses-content .average-rating {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

@media only screen and (max-width: 767px) {
  .single-courses-rating .courses .courses-content .average-rating {
    justify-content: center;
  }
}

.single-courses-rating .courses .courses-content .average-rating .label {
  font-size: 15px;
  font-weight: 400;
  display: block;
  color: #212832;
  white-space: nowrap;
  padding-right: 10px;
}

.single-courses-rating .courses .courses-content .average-rating .count {
  font-size: 22px;
  font-weight: 700;
  color: #309255;
  display: block;
}

@media only screen and (max-width: 575px) {
  .single-courses-rating .courses .courses-content .average-rating .count {
    font-size: 18px;
  }
}

.single-courses-rating .courses .courses-content .average-rating .count .rating-star {
  position: relative;
}

.single-courses-rating .courses .courses-content .average-rating .count .rating-star::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #d0d0d0;
  font-size: 13px;
  letter-spacing: 1px;
}

.single-courses-rating .courses .courses-content .average-rating .count .rating-star .rating-bar {
  position: absolute;
  top: -2px;
  left: 0;
  height: 100%;
  overflow: hidden;
}

.single-courses-rating .courses .courses-content .average-rating .count .rating-star .rating-bar::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #ffba00;
  font-size: 13px;
  letter-spacing: 1px;
}

.single-courses-rating .courses .courses-btn {
  margin-left: auto;
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .single-courses-rating .courses .courses-btn {
    margin: 20px auto 0;
  }
}

.single-courses-rating .courses .courses-btn .btn {
  font-size: 15px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  padding: 0 20px;
  height: 50px;
  line-height: 48px;
}

.single-courses-rating .courses .courses-btn .btn:hover {
  background-color: #309255;
  border-color: #309255;
  color: #fff;
}

.single-courses-rating .rating {
  display: flex;
  position: relative;
  padding: 30px;
  border-radius: 10px;
  background-color: #eefbf3;
  margin-top: 20px;
}

@media only screen and (max-width: 575px) {
  .single-courses-rating .rating {
    display: block;
    padding: 20px;
  }
}

.single-courses-rating .rating .rating-author img {
  width: 70px;
  border-radius: 50%;
}

.single-courses-rating .rating .rating-content {
  padding-left: 30px;
  flex: 1;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .single-courses-rating .rating .rating-content {
    padding-left: 0;
    padding-top: 25px;
  }
}

.single-courses-rating .rating .rating-content .name {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .single-courses-rating .rating .rating-content .name {
    font-size: 20px;
  }
}

.single-courses-rating .rating .rating-content .date {
  color: #8e9298;
  font-size: 14px;
  font-weight: 400;
  display: block;
  margin-top: 10px;
}

.single-courses-rating .rating .rating-content .average-rating {
  margin-top: 10px;
}

.single-courses-rating .rating .rating-content .average-rating .rating-star {
  position: relative;
}

.single-courses-rating .rating .rating-content .average-rating .rating-star::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #d0d0d0;
  font-size: 15px;
  letter-spacing: 2px;
}

.single-courses-rating .rating .rating-content .average-rating .rating-star .rating-bar {
  position: absolute;
  top: -2px;
  left: 0;
  height: 100%;
  overflow: hidden;
}

.single-courses-rating .rating .rating-content .average-rating .rating-star .rating-bar::before {
  content: '\f000 \f000 \f000 \f000 \f000';
  font-family: IcoFont;
  color: #ffba00;
  font-size: 15px;
  letter-spacing: 2px;
}

.single-courses-rating .rating .rating-content .btn {
  font-size: 15px;
  border: 1px solid #c8e6d3;
  padding: 0 20px;
  background-color: #fff;
  color: #309255;
  height: 45px;
  line-height: 41px;
  margin-top: 20px;
}

.single-courses-rating .rating .rating-content .btn:hover {
  background-color: #309255;
  border-color: #309255;
  color: #fff;
}

.single-courses-rating .rating .waving {
  position: absolute;
  top: 27px;
  right: 25px;
  color: #212832;
  font-size: 20px;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.single-courses-rating .rating .waving:hover {
  color: #309255;
}

.question-answer {
  border-top: 1px solid rgba(48, 146, 85, 0.2);
  margin-top: 20px;
}

.answer-user-list {
  padding: 20px 0;
}

.single-user {
  padding: 20px;
  display: flex;
  border-radius: 10px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  position: relative;
  align-items: center;
  margin-top: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .single-user {
    padding: 20px 10px;
  }
}

@media only screen and (max-width: 575px) {
  .single-user {
    padding: 10px;
  }
}

.single-user .user-author img {
  width: 70px;
  border-radius: 50px;
}

@media only screen and (max-width: 575px) {
  .single-user .user-author img {
    width: 35px;
  }
}

.single-user .user-content {
  flex: 1;
  padding-left: 20px;
  position: relative;
  max-width: 210px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .single-user .user-content {
    max-width: 160px;
    padding-left: 10px;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .single-user .user-content {
    max-width: 140px;
    padding-left: 10px;
  }
}

.single-user .user-content .name {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 8px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .single-user .user-content .name {
    font-size: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .single-user .user-content .name {
    font-size: 13px;
  }
}

.single-user .user-content p {
  color: #52565b;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .single-user .user-content p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .single-user .user-content p {
    font-size: 12px;
  }
}

.single-user .time {
  color: #8e9298;
  font-size: 13px;
  font-weight: 400;
  position: absolute;
  bottom: 30px;
  right: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .single-user .time {
    right: auto;
    left: 80px;
    bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .single-user .time {
    font-size: 8px;
    right: 8px;
    bottom: auto;
    top: 10px;
  }
}

.single-user.active {
  background-color: #309255;
  border-color: #309255;
}

.single-user.active .user-content .name {
  color: #fff;
}

.single-user.active .user-content p {
  color: #d4efde;
}

.single-user.active .time {
  color: #d4efde;
}

.answer-message-wrapper {
  border-top: 1px solid rgba(48, 146, 85, 0.2);
  padding-top: 10px;
  padding-bottom: 80px;
}

@media only screen and (min-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1599px) {
  .answer-message-wrapper {
    border-left: 1px solid rgba(48, 146, 85, 0.2);
    padding-left: 30px;
    border-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .answer-message-wrapper {
    padding-bottom: 60px;
  }
}

.answer-message-wrapper ul {
  border-bottom: 1px solid rgba(48, 146, 85, 0.2);
}

.answer-message-wrapper ul li + li .single-message {
  border-top: 1px solid rgba(48, 146, 85, 0.2);
}

.answer-message-wrapper ul li ul {
  border-bottom: 0;
}

.answer-message-wrapper ul li ul li .single-message {
  border-top: 1px solid rgba(48, 146, 85, 0.2);
}

.answer-message-wrapper ul li .message-replay {
  padding-left: 110px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .answer-message-wrapper ul li .message-replay {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .answer-message-wrapper ul li .message-replay {
    padding-left: 70px;
  }
}

@media only screen and (max-width: 575px) {
  .answer-message-wrapper ul li .message-replay {
    padding-left: 0;
  }
}

.answer-message-wrapper .loadmore {
  border: 1px solid #d6e9dd;
  background-color: #edfaf2;
  height: 55px;
  line-height: 53px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  color: #309255;
  display: block;
  padding: 0 20px;
  text-align: center;
  margin-top: 40px;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 575px) {
  .answer-message-wrapper .loadmore {
    height: 45px;
    line-height: 43px;
    font-size: 14px;
  }
}

.answer-message-wrapper .loadmore:hover {
  background-color: #309255;
  border-color: #309255;
  color: #fff;
}

.single-message {
  padding: 30px 0;
}

.single-message .message-author {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 218px;
}

@media only screen and (max-width: 575px) {
  .single-message .message-author {
    display: block;
    padding-right: 0;
  }
}

.single-message .message-author .author-images img {
  width: 70px;
  border-radius: 50%;
}

.single-message .message-author .author-content {
  padding-left: 20px;
  flex: 1;
}

@media only screen and (max-width: 575px) {
  .single-message .message-author .author-content {
    padding-left: 0;
    padding-top: 15px;
  }
}

.single-message .message-author .author-content .name {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 0;
}

.single-message .message-author .author-content .name strong {
  margin-right: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .single-message .message-author .author-content .name strong {
    margin-right: 15px;
  }
}

.single-message .message-author .author-content .name span {
  display: inline-block;
}

.single-message .message-author .author-content .title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-message .message-author .author-content .title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .single-message .message-author .author-content .title {
    font-size: 16px;
  }
}

.single-message .message-author .author-content .time {
  font-size: 12px;
  font-weight: 400;
  font-family: "Gordita";
  color: #8e9298;
}

.single-message .message-author .author-content .instructor {
  background-color: #e7f3eb;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #309255;
  padding: 3px 12px;
}

.single-message .message-author .author-content * + * {
  margin-top: 10px;
  display: block;
}

.single-message .message-author .meta {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 575px) {
  .single-message .message-author .meta {
    display: inline-block;
    text-align: center;
  }
}

.single-message .message-author .meta .view {
  color: #309255;
  font-size: 12px;
  font-weight: 400;
  margin-right: 20px;
}

@media only screen and (max-width: 575px) {
  .single-message .message-author .meta .view {
    display: block;
    margin-right: 0;
  }
}

.single-message .message-author .meta .view i {
  margin-right: 8px;
}

.single-message .message-author .meta .answer {
  font-size: 12px;
  font-weight: 400;
  border: 1px solid #d6e9dd;
  background-color: #edfaf2;
  height: 40px;
  line-height: 38px;
  padding: 0 20px;
  border-radius: 5px;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 575px) {
  .single-message .message-author .meta .answer {
    margin-top: 10px;
    height: 35px;
    line-height: 33px;
    padding: 0 15px;
  }
}

.single-message .message-author .meta .answer i {
  margin-right: 8px;
}

.single-message .message-author .meta .answer:hover {
  background-color: #309255;
  border-color: #309255;
  color: #fff;
}

.single-message p {
  margin-top: 20px;
  margin-bottom: 0;
}

.message-form {
  display: flex;
  margin-top: 40px;
}

@media only screen and (max-width: 575px) {
  .message-form {
    display: block;
  }
}

.message-form .auhtor img {
  width: 70px;
  border-radius: 50%;
}

.message-form .message-input {
  padding-left: 20px;
  flex: 1;
}

@media only screen and (max-width: 575px) {
  .message-form .message-input {
    padding-left: 0;
    padding-top: 20px;
  }
}

.message-form .message-input textarea {
  width: 100%;
  padding: 20px 30px;
  resize: none;
  height: 200px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 5px;
  font-size: 15px;
  font-family: serif;
  font-weight: 400;
  color: #52565b;
  outline: none;
}

.message-form .message-input textarea::-webkit-input-placeholder {
  opacity: 1;
}

.message-form .message-input textarea:-moz-placeholder {
  opacity: 1;
}

.message-form .message-input textarea::-moz-placeholder {
  opacity: 1;
}

.message-form .message-input textarea:-ms-input-placeholder {
  opacity: 1;
}

.message-form .message-input textarea:focus {
  border-color: #309255;
}

@media only screen and (max-width: 575px) {
  .message-form .message-input textarea {
    padding: 15px 20px;
  }
}

.message-form .message-input .message-btn {
  text-align: center;
  margin-top: 20px;
}

.message-form .message-input .message-btn .btn {
  width: 120px;
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  margin: 0 8px;
}

@media only screen and (max-width: 575px) {
  .message-form .message-input .message-btn .btn {
    width: 90px;
    height: 45px;
    line-height: 45px;
    font-size: 13px;
    padding: 0 20px;
  }
}

.message-form .message-input .message-btn .btn.btn-secondary {
  background-color: #ffe4e0;
}

/*----------------------------------------*/
/*  05. Widget CSS
/*----------------------------------------*/
/*--
/*  5.1 - Footer Widget CSS
/*----------------------------------------*/
.footer-widget-section {
  padding-top: 30px;
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .footer-widget-section {
    padding-top: 10px;
    padding-bottom: 60px;
  }
}

.footer-widget-section .shape-1 {
  position: absolute;
  top: 80px;
  left: 5%;
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .footer-widget-section .shape-1 {
    display: none;
  }
}

.footer-widget-section .shape-2 {
  position: absolute;
  right: 3%;
  bottom: 95px;
  z-index: -1;
}

.footer-widget-title {
  color: #212832;
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 0px;
}

.footer-widget {
  margin-top: 50px;
}

.footer-widget .widget-address {
  margin-top: 27px;
}

.footer-widget .widget-address p {
  font-size: 15px;
  font-weight: 400;
  color: #309255;
  margin-top: 6px;
}

.footer-widget .widget-info {
  padding-top: 25px;
}

.footer-widget .widget-info li p {
  font-size: 15px;
  color: #212832;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
}

.footer-widget .widget-info li p i {
  font-size: 20px;
  color: #309255;
}

.footer-widget .widget-info li p a {
  margin-top: 3px;
  margin-left: 15px;
}

.footer-widget .widget-social {
  display: flex;
  padding-top: 20px;
}

.footer-widget .widget-social li {
  margin-right: 20px;
}

.footer-widget .widget-social li:last-child {
  margin-right: 0;
}

.footer-widget .widget-social li a {
  font-size: 20px;
  color: #212832;
}

.footer-widget .widget-social li a:hover {
  color: #309255;
}

.footer-widget .widget-link {
  padding-top: 26px;
}

.footer-widget .widget-link li {
  margin-top: 12px;
}

.footer-widget .widget-link li a {
  color: #212832;
  transition: all 0.3s ease 0s;
  font-size: 15px;
  font-weight: 400;
}

.footer-widget .widget-link li a:hover {
  color: #309255;
}

.footer-widget .widget-subscribe {
  padding-top: 36px;
}

.footer-widget .widget-subscribe p {
  color: #212832;
  font-size: 15px;
}

.footer-widget .widget-subscribe .widget-form input {
  width: 100%;
  height: 55px;
  padding: 0 30px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  background-color: #fff;
  color: #212832;
  font-size: 15px;
  font-weight: 500;
  outline: none;
}

.footer-widget .widget-subscribe .widget-form input::-webkit-input-placeholder {
  opacity: 1;
  color: #212832;
}

.footer-widget .widget-subscribe .widget-form input:-moz-placeholder {
  opacity: 1;
  color: #212832;
}

.footer-widget .widget-subscribe .widget-form input::-moz-placeholder {
  opacity: 1;
  color: #212832;
}

.footer-widget .widget-subscribe .widget-form input:-ms-input-placeholder {
  opacity: 1;
  color: #212832;
}

.footer-widget .widget-subscribe .widget-form .btn {
  margin-top: 20px;
  height: 55px;
  line-height: 55px;
  padding: 0 30px;
  font-size: 15px;
}

.footer-widget-link {
  display: flex;
  flex-wrap: wrap;
}

.footer-widget-link .footer-widget {
  width: 50%;
}

@media only screen and (max-width: 575px) {
  .footer-widget-link .footer-widget {
    width: 100%;
  }
}

/*--
/*  5.2 - Sidebar Widget CSS
/*----------------------------------------*/
.sidebar-widget {
  margin-top: 50px;
}

.sidebar-widget .social {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .sidebar-widget .social {
    justify-content: flex-start;
  }
}

.sidebar-widget .social li {
  padding-top: 10px;
  margin-right: 10px;
}

.sidebar-widget .social li:last-child {
  margin-right: 0;
}

.sidebar-widget .social li a {
  display: block;
  width: 50px;
  height: 50px;
  line-height: 52px;
  border: 1px solid rgba(48, 146, 85, 0.25);
  border-radius: 10px;
  text-align: center;
  font-size: 18px;
  color: #212832;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .social li a {
    width: 45px;
    height: 45px;
    line-height: 47px;
  }
}

.sidebar-widget .social li a:hover {
  background-color: #309255;
  border-color: #309255;
  color: #fff;
}

.widget-title {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: -7px;
}

.widget-information {
  background-color: #e7f8ee;
  padding: 30px 30px 40px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
}

.widget-information .info-price {
  text-align: center;
}

.widget-information .info-price .price {
  font-size: 30px;
  font-weight: 700;
  color: #309255;
  display: block;
}

.widget-information .info-list ul {
  border-top: 1px solid #d1e6d9;
  margin-top: 35px;
}

.widget-information .info-list ul li {
  border-bottom: 1px solid #d1e6d9;
  padding: 15px 0;
}

.widget-information .info-list ul li i {
  color: #309255;
  margin-right: 3px;
  font-size: 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget-information .info-list ul li i {
    font-size: 15px;
  }
}

.widget-information .info-list ul li strong {
  color: #212832;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget-information .info-list ul li strong {
    font-size: 15px;
  }
}

.widget-information .info-list ul li span {
  color: #52565b;
  display: block;
  float: right;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget-information .info-list ul li span {
    font-size: 15px;
  }
}

.widget-information .info-btn {
  text-align: center;
  margin-top: 40px;
}

.widget-search {
  position: relative;
}

.widget-search input {
  width: 100%;
  height: 60px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  padding: 0 20px;
  padding-right: 60px;
  outline: none;
  transition: all 0.3s ease 0s;
  font-size: 15px;
  font-weight: 400;
  color: #52565b;
}

.widget-search input::-webkit-input-placeholder {
  opacity: 1;
}

.widget-search input:-moz-placeholder {
  opacity: 1;
}

.widget-search input::-moz-placeholder {
  opacity: 1;
}

.widget-search input:-ms-input-placeholder {
  opacity: 1;
}

.widget-search input:focus {
  border-color: #309255;
}

.widget-search button {
  position: absolute;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 10px;
  background-color: #309255;
  border: 0;
  top: 6px;
  right: 6px;
  font-size: 16px;
  color: #fff;
}

.widget-category {
  padding: 35px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  margin-top: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .widget-category {
    padding: 25px;
  }
}

.widget-category .category-list li + li {
  margin-top: 10px;
}

.widget-category .category-list li a {
  padding: 0 15px;
  height: 50px;
  line-height: 50px;
  display: block;
  color: #52565b;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget-category .category-list li a {
    font-size: 14px;
  }
}

.widget-category .category-list li a span {
  display: block;
  float: right;
}

.widget-category .category-list li a:hover {
  color: #309255;
  background-color: #e7f8ee;
  border-color: #e7f8ee;
}

.widget-post {
  padding: 35px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  margin-top: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .widget-post {
    padding: 25px;
  }
}

.widget-post .post-items li {
  border-bottom: 1px solid rgba(48, 146, 85, 0.2);
  padding: 20px 0;
}

.widget-post .post-items li:first-child {
  padding-top: 0;
}

.widget-post .post-items li:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.widget-post .single-post {
  display: flex;
  align-items: center;
}

.widget-post .single-post .post-thumb a {
  display: block;
}

.widget-post .single-post .post-thumb a img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-position: center;
  object-fit: cover;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .widget-post .single-post .post-thumb a img {
    width: 60px;
    height: 60px;
  }
}

.widget-post .single-post .post-content {
  flex: 1;
  padding-left: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .widget-post .single-post .post-content {
    padding-left: 20px;
  }
}

.widget-post .single-post .post-content .title {
  margin-bottom: 0;
}

.widget-post .single-post .post-content .title a {
  font-size: 15px;
  font-weight: 500;
  font-family: "Gordita";
  color: #212832;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .widget-post .single-post .post-content .title a {
    font-size: 14px;
  }
}

.widget-post .single-post .post-content .title a:hover {
  color: #309255;
}

.widget-post .single-post .post-content .date {
  font-size: 13px;
  color: #52565b;
  margin-top: 5px;
  display: block;
}

.widget-post .single-post .post-content .date i {
  color: #309255;
}

.widget-tags {
  padding: 35px;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  margin-top: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 575px) {
  .widget-tags {
    padding: 25px;
  }
}

.widget-tags .tags-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -7px;
  margin-right: -7px;
  margin-top: -10px;
}

.widget-tags .tags-list li {
  padding: 10px 5px 0;
}

.widget-tags .tags-list li a {
  height: 45px;
  line-height: 43px;
  padding: 0 35px;
  font-size: 15px;
  background-color: #fff;
  display: block;
  color: #52565b;
  border: 1px solid rgba(48, 146, 85, 0.2);
  border-radius: 10px;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .widget-tags .tags-list li a {
    font-size: 14px;
    padding: 0 22px;
  }
}

@media only screen and (max-width: 767px) {
  .widget-tags .tags-list li a {
    padding: 0 20px;
  }
}

.widget-tags .tags-list li a:hover {
  background-color: #e7f8ee;
  border-color: #e7f8ee;
  color: #309255;
}

/*----------------------------------------*/
/*  06. Footer CSS
/*----------------------------------------*/
.footer-section {
  background-color: #e7f8ee;
}

.footer-copyright {
  background-color: #212832;
}

.copyright-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  padding-top: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .copyright-wrapper {
    display: block;
    text-align: center;
  }
}

.copyright-link {
  padding-top: 15px;
}

.copyright-link a {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  position: relative;
}

.copyright-link a + a::before {
  content: '*';
  font-size: 15px;
  color: #a6afba;
  margin-left: 5px;
  margin-right: 10px;
}

.copyright-link a:hover {
  color: #309255;
}

.copyright-text {
  padding-top: 15px;
}

.copyright-text p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.copyright-text p span {
  color: #309255;
  text-transform: uppercase;
}

.copyright-text p i {
  color: #ff0000;
}

.copyright-text p a {
  color: #309255;
  transition: all 0.3s ease 0s;
  font-weight: 500;
}

.copyright-text p a:hover {
  color: #309255;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 20px;
  width: 40px;
  height: 40px;
  line-height: 36px;
  border-radius: 50%;
  z-index: 99;
  text-align: center;
  display: none;
  box-shadow: 2px 4px 8px rgba(33, 40, 50, 0.15);
  transition: all 0.3s linear;
  color: #fff;
  background-color: #309255;
}

.back-to-top:hover {
  color: #fff;
  background-color: #212832;
}
